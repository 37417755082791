import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetDirective } from './widget/widget.directive';
import { ScrollDirective } from './scroll/scroll-spy.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    WidgetDirective,
    ScrollDirective
  ],
  exports: [ 
      WidgetDirective,
      ScrollDirective
  ]
})
export class DirectivesModule { }
