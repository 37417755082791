import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ContractSearchPipe', pure: false })
export class ContractSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(contract => {
        // alert(JSON.stringify(contract))
        if (contract?.projectName) {
          return contract.projectName.search(searchText) !== -1;
        }
      });
    }
  }
}