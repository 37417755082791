<div class="p-2">
    <div class="row  mb-4 mt-2">
        <div class="col-12 d-flex" [class]="userType?.isSuperAdmin ? 'justify-content-between' : 'justify-content-end'">
            <div *ngIf="userType?.isSuperAdmin">
                <mat-form-field appearance="outline" class="me-2">
                    <mat-label>Role</mat-label>
                    <mat-select [(ngModel)]="selectRoleSno" (selectionChange)="changeRole($event.value)">
                        <mat-option *ngFor="let role of roleList;let i = index;" [value]="role.codesDtlSno">
                            {{role?.cdValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="me-2">
                    <mat-label>Country</mat-label>
                    <mat-select [(ngModel)]="selectCountrySno" (selectionChange)="getUserMenu()">
                        <mat-option *ngFor="let country of countryList;let i = index;" [value]="country.countrySno">
                            {{country?.countryShortCode}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline" *ngIf="(selectedRoleValue == 'Employee') || !userType?.isSuperAdmin">
                <mat-label>Select Menu</mat-label>
                <mat-select (selectionChange)="getUserMenu()" [(ngModel)]="selectAppMenuSno">
                    <mat-option *ngFor="let menu of menuList;let i = index;" [value]="menu.appMenuSno">
                        {{menu?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- <div class="w-25">
            <div class="input-group">
              <div class="input-group-prepend mx-auto" style="width:50px">
                <span class="input-group-text  text-center" style="width:50px"><i
                    class="fa fa-search mx-auto"></i></span>
              </div>
              <input (keyup)="selectAppMenuSno && this.searchUser.next($event)"  type="text" style="height:42px" class="w-25"
                placeholder="Search user Name..." class="form-control border-1 ">
            </div>
        </div> -->

            <!-- <mat-form-field appearance="outline" class="ml-3">
            <mat-label>Select Menu</mat-label>
            <mat-select [formControl]="menuCtrl"  placeholder="Menu"
                [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="menuFilterCtrl" placeholderLabel="Find Menu.."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All"
                        [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                        (toggleAll)="toggleMenuSelectAll($event)">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>
      
                <mat-option *ngFor="let menu of filteredMenu | async "
                    style="border-bottom:1px solid #ced4da !important;" [value]="menu.menuSno">
                    {{ menu.title }}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

        </div>
    </div>

    <!-- <div class="crm-container scrollable-content" cdkScrollable>
    <div class="row mb-3 flex-nowrap">
        <div class="col-lg-4 col-xl-3 mx-1" *ngFor="let crm of crmList;let k =index">
            <div class="card px-2 p-0 bg-card">
                <h4 class="fw-400 pl-2 m-0 mt-2 mb-2 ">{{crm.menuStatus}} 
                    </h4>
            </div>
        </div>
    </div>

    <div cdkDropListGroup class="row flex-nowrap position-center">
        <div *ngFor="let crm of crmList;let i = index;" class="col-lg-4 col-xl-3 mx-1 item-container overflow-auto"
            cdkDropList [cdkDropListData]="crm.users" (cdkDropListDropped)="drop($event,i)">
            <div class="card mb-2"  cdkDrag  *ngFor="let user of crm.users;let j = index">
                <div class="card-body">
                    <div class="row">
                        <div [ngClass]="crmList[i].menuStatus == 'Assigned User'?'col-9':'col-12'"> 
                            <h3 class="card-title m-0 blue text-capitalize text-wrap pointer"
                                style="font-weight:500;width: 16rem;" title="User Name" >
                      
                               <span class="text-muted">Name:</span> {{user?.surname != null? user?.surname + ' ' + user?.name:user?.name}} </h3>
                            <h3 class="card-subtitle m-0 text-wrap text-dark blue" title="Position"> <span class="text-muted">Position:
                            </span> {{ api.isEmptyString(user?.jobTitle) ? user?.jobTitle : '-----' }} </h3>
                            <h3 class="card-subtitle m-0 text-truncate text-dark blue" title="email"><span class="text-muted">Email:</span> {{user?.email}}</h3>
                            <h3 class="card-subtitle m-0 blue text-capitalize text-truncate text-dark" title="Account Name"><span class="text-muted">Phone:</span>
                                {{ api.isEmptyString(user?.mobilePhone) ? user?.mobilePhone : '-----' }}</h3>
                        </div>
                        <div class="col-3" *ngIf="crmList[i].menuStatus == 'Assigned User'">
                            <label class="text-dark">Make as Admin</label>
                            <div class="form-check text-end">
                                <input class="form-check-input pointer" type="checkbox" [checked]="user?.isAdmin"
                                    (click)="changeAdmin(i,j)">
                            </div>  
                        </div>
                    </div> 
                </div>
            </div>
            <div *ngIf="crm.users?.length ==0" class="no_data">
                <h3 class="centered"> No Data Found </h3>
            </div>
        </div>
    </div>
</div> -->

    <div class="card text-center">
        <div class="row border-bottom px-3">
            <div class="col-6 p-3 border-right bg-primary text-white">
                <b>UnAssigned User</b>
            </div>
            <div class="col-6 p-3  bg-primary text-white">
                <b>Assigned User</b>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-12 text-center align-self-center">
                        <mat-form-field class="m-2 search-form-field">
                            <button matPrefix mat-icon-button aria-label="Clear">
                                <mat-icon>search</mat-icon>
                            </button>
                            <input matInput type="text" placeholder="search" [(ngModel)]="searchUnAssignVal"
                                (input)="this.searchUnAssingedUser.next($event)">
                            <button *ngIf="api.isEmptyString(searchUnAssignVal)" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearUnAssignedUser()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-start height-700">
                        <div class="card m-2" *ngFor="let user of filteredUnAssignUsers | async;let j = index">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-9">
                                        <h5 class="card-title m-0 blue text-capitalize text-wrap" title="User Name">
                                            <span class="text-muted">Name:</span> {{api.isEmptyString(user?.surname) ?
                                            user?.surname + '
                                            ' + user?.name:user?.name}}
                                        </h5>
                                        <h5 class="card-subtitle m-0 text-wrap text-dark blue" title="Position"> <span
                                                class="text-muted">Position:
                                            </span> {{ api.isEmptyString(user?.jobTitle) ? user?.jobTitle : '-----' }}
                                        </h5>
                                        <h5 class="card-subtitle m-0 text-truncate text-dark blue" title="email"><span
                                                class="text-muted">Email:</span> {{user?.email}}</h5>
                                    </div>
                                    <div class="col-3">
                                        <button mat-button
                                            (click)="selectedRoleValue == 'Employee' ? changeAssignStatus(user) : updateRole(user,selectedRoleValue == 'Admin' ? 'Admin' : 'Super Admin')">
                                            <mat-icon>add</mat-icon>Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-12 text-center align-self-center">
                        <mat-form-field class="m-2 search-form-field">
                            <button matPrefix mat-icon-button aria-label="Clear">
                                <mat-icon>search</mat-icon>
                            </button>
                            <input matInput type="text" placeholder="search" [(ngModel)]="searchAssignVal"
                                (input)="this.searchAssingedUser.next($event)">
                            <button *ngIf="api.isEmptyString(searchAssignVal)" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearAssignedUser()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-start height-700">
                        <div class="card m-2" *ngFor="let user of filteredAssignUsers | async;let j = index">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-9">
                                        <h5 class="card-title m-0 blue text-capitalize text-wrap" title="User Name">
                                            <span class="text-muted">Name:</span> {{api.isEmptyString(user?.surname) ?
                                            user?.surname + '
                                            ' + user?.name:user?.name}}
                                        </h5>
                                        <h5 class="card-subtitle m-0 text-wrap text-dark blue" title="Position"> <span
                                                class="text-muted">Position:
                                            </span> {{ api.isEmptyString(user?.jobTitle) ? user?.jobTitle : '-----' }}
                                        </h5>
                                        <h5 class="card-subtitle m-0 text-truncate text-dark blue" title="email"><span
                                                class="text-muted">Email:</span> {{user?.email}}</h5>
                                    </div>
                                    <div class="col-3 text-center">
                                        <button mat-button
                                            (click)="selectedRoleValue == 'Employee' ? changeUnAssignStatus(user) : updateRole(user,'Employee')">
                                            <mat-icon>remove</mat-icon>Remove
                                        </button><br>
                                        <div class="py-3" *ngIf="selectedRoleValue == 'Employee'">
                                            <label>Admin</label>
                                            <mat-slide-toggle color="primary" [checked]="user?.isAdmin"
                                                (change)="changeAdmin(user)"></mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>