<div class="p-2">
    <div class="row d-flex justify-content-end">
        <div class="col-md-2 col-sm-12">
            <label class="text-gray">Search</label><br>
            <mat-form-field appearance="outline" class="search-form-field">
                <mat-icon matPrefix class="pe-0">search</mat-icon>
                <input matInput [(ngModel)]="searchKey" [disabled]="isShowLoading" (ngModelChange)="searchEle()"
                    placeholder="Search dealName">
                <button class="btn clear_icon" *ngIf="api.isEmptyString(searchKey)" matSuffix (click)="clearSearch()">
                    <i class="bi bi-x-circle"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="col-md-3 col-sm-12">
            <label class="text-gray">Country <span class="text-danger">*</span></label><br>
            <mat-form-field appearance="outline" class="search-form-field">
                <mat-select [(ngModel)]="selectCountrySno" [disabled]="isShowLoading" (ngModelChange)="changeCountry()">
                    <mat-option *ngFor="let country of countryList;let i = index;" [value]="country.countrySno">
                        {{country?.countryShortCode}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-3 col-sm-12"
            *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin">
            <label class="text-gray">Deal Owner <span class="text-danger">*</span></label><br>
            <mat-form-field appearance="outline" class="search-form-field">
                <mat-select [formControl]="userCtrl" [disabled]="isShowLoading" (ngModelChange)="getDeal()"
                    placeholder="Select user" [multiple]="true" #singleSelect>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="userFilterCtrl" placeholderLabel="Find user"
                            noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                            toggleAllCheckboxTooltipMessage="Select / Unselect All"
                            [toggleAllCheckboxIndeterminate]="false" [toggleAllCheckboxChecked]="false"
                            (toggleAll)="toggleSelectAllUsers($event)">
                            <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let user of filteredUsers | async "
                        style="border-bottom:1px solid #ced4da !important;" [value]="user?.appUserSno">
                        {{ user?.name }} {{ user?.surname}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3 col-sm-12">
            <label class="text-gray">Account <span class="text-danger">*</span></label><br>
            <mat-form-field appearance="outline" class="search-form-field">
                <mat-select [formControl]="clientCtrl" [disabled]="isShowLoading" (ngModelChange)="getDeal()"
                    placeholder="Select account" [multiple]="true" #singleSelect1>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="clientFilterCtrl" placeholderLabel="Find client"
                            noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                            toggleAllCheckboxTooltipMessage="Select / Unselect All"
                            [toggleAllCheckboxIndeterminate]="false" [toggleAllCheckboxChecked]="false"
                            (toggleAll)="toggleSelectAllClient($event)">
                            <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let client of filteredClients | async "
                        style="border-bottom:1px solid #ced4da !important;" [value]="client?.clientSno">
                        {{client?.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-1 col-sm-12">
            <label class="text-gray">Export Xl</label><br>
            <button type="button" class="btn btn-primary " style="height: 2.8rem;" (click)="extractXl()"><i
                class="fa fa-download "></i></button>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-12 text-end">
            <button mat-raised-button color="primary" class="p-4 ml-3" (click)="selectDocument()">
                <mat-icon>cloud_upload</mat-icon>&nbsp;Import Deal
            </button>
            <input type="file" id="document_upload" (change)="fileChange($event)" #input class="d-none"
                accept=".xlsx" />
        </div> -->
    </div>

    <div class="crm-container scrollable-content" cdkScrollable *ngIf="!isShowLoading && crmList?.length">
        <div class="row flex-nowrap">
            <div class="col-lg-4 col-xl-3 mx-1" *ngFor="let crm of crmList">
                <div class="card px-2 p-0 bg-card">
                    <h5 class="fw-400 text-dark pl-2 m-0 mt-2">{{crm.statusName}} <span class="text-secondary">&#x2022;
                            {{crm?.percentage}}%</span></h5>
                    <h6 class="pl-2 m-0 " style="font-weight:500;"><i class="bi bi-cash"></i> {{crm.totalDealAmount |
                        number : '1.2-2'}} <span class="text-secondary font-weight-normal">&#x2022; {{crm?.deals?.length
                            ?? 0}} Deals </span></h6>
                </div>
            </div>
        </div>

        <div cdkDropListGroup class="row flex-nowrap">
            <div *ngFor="let crm of crmList;let i = index;" class="col-lg-4 col-xl-3 mx-1 item-container overflow-auto"
                cdkDropList [cdkDropListData]="crm.deals" (cdkDropListDropped)="drop($event,i)">
                <div class="card mb-2 position-relative container p-0" cdkDrag
                    *ngFor="let client of crm.deals;let j = index">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <h5 class="card-title m-0 blue text-capitalize text-wrap pointer"
                                    style="font-weight:500;width: 16rem;display: contents;" title="Deal Name"
                                    (click)="viewDeal(client)">
                                    {{client.dealName}} </h5>
                                <h5 class="card-subtitle m-0 text-wrap" title="Deal Owner">{{client.dealOwnerName}}</h5>
                                <h5 class="card-subtitle m-0 text-wrap" title="Deal Id">{{client.dealId}}</h5>
                                <!-- <h5 class="card-subtitle m-0 blue text-capitalize text-truncate" title="Account Name">
                                    {{client.accountName}}</h5> -->
                                <h5 class="card-subtitle m-0 text-capitalize" title="Amount">
                                    {{api.getCurrencyCode(client?.currencyCode)}} {{client?.withoutTaxTotalAmount
                                    |number : '1.2-2'}}</h5>
                                <h5 class="card-subtitle m-0 text-capitalize text-danger" title="Closing Date">
                                    {{client.createdDate | date: 'dd/MM/yyyy, h:mm:ss a'}}</h5>
                                <h5 class="card-subtitle m-0 text-capitalize text-end"
                                    *ngIf="client?.dealModifyDetails">
                                    Last Modify On
                                </h5>
                                <h5 class="card-subtitle m-0 text-capitalize text-end"
                                    *ngIf="client?.dealModifyDetails">
                                    {{client.dealModifyDetails?.updateBy}} ({{client.dealModifyDetails?.updateTime |
                                    date: 'dd/MM/yyyy, h:mm:ss a'}})
                                </h5>
                            </div>
                        </div>

                        <div class="row text-center text-white mt-2 deal-edit pointer"
                            lass="col-12 text-end edit_option"
                            *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin">
                            <div class="col-6 bg-secondary p-1 " (click)="edit(i,j)">
                                <i class="bi bi-pencil"></i> Edit
                            </div>
                            <div class="col-6 bg-danger p-1" (click)="deleteAlert(i,j)">
                                <i class="bi bi-trash"></i> Delete
                            </div>
                        </div>

                        <!-- <div class="col-12 text-end edit_option"
                        *ngIf="isAdmin || (authUser?.roleCdValue == 'Admin')">
                        <button type="button" class="btn btn-light" (click)="edit(i,j)">
                            <span class="menu-icon tf-icons iconify text-primary">
                                <i class="fa fa-edit"></i>
                            </span>
                        </button>
                        <button type="button" class="btn btn-light" (click)="deleteAlert(i,j)">
                            <span class="menu-icon tf-icons iconify text-danger">
                                <i class="fa fa-trash"></i>
                            </span>
                        </button>
                    </div> -->
                    </div>
                </div>
                <div *ngIf="crm.deals?.length ==0" class="no_deal">
                    <h3 class="centered"> No Deals Found </h3>
                </div>
            </div>
        </div>

        <div class="example-button-row text-end position label">
            <button mat-fab extended color="primary" (click)="gotoAddDealPage()">
                <mat-icon>add</mat-icon>
                Add Deal
            </button>
        </div>
    </div>

    <div *ngIf="isShowLoading" class="text-center">
        <img src="assets/gif/loading.gif" class="position-absolute top-50 start-50" width="110px" />
    </div>
</div>