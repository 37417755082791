import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { EmailValidators } from 'ngx-validators'
import { TokenStorageService } from './token-storage.service';
import { UserIdleService } from 'angular-user-idle';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from 'src/app/providers/fire/messaging.service';
import { azureService } from 'src/app/services/azure.sevices';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/providers/api/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
  public router: Router;
  public form: FormGroup;
  // public email: AbstractControl;
  // public password: AbstractControl;

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  menus: any[] = [];
  userStoredName: string = "";


  constructor(
    router: Router, fb: FormBuilder,
    private toastrService: ToastrService,
    private tokenStorage: TokenStorageService,
    private userIdle: UserIdleService,
    private messageService: MessagingService,
    public azureConnectionService: azureService,
    public httpClient: HttpClient,
    private api: ApiService
  ) {
    this.router = router;
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required, EmailValidators.simple])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });

    // this.email = this.form.controls['email'];
    // this.password = this.form.controls['password'];
  }

  ngOnInit() {
  }

  // public onSubmit(): void {
  //   if (this.form.valid) {
  //     let body: any = this.form.value;
  //     body.deviceId = "12345";
  //     body.pushToken = this.messageService.token;
  //     body.deviceTypeName = "web";
  //     this.authService.login(body).subscribe(
  //       data => {
  //         if (data.isLogin) {
  //           this.tokenStorage.saveToken(data.accessToken);
  //           this.tokenStorage.saveUser(data);

  //           this.isLoginFailed = false;
  //           this.isLoggedIn = true;
  //           this.roles = this.tokenStorage.getUser().roles;
  //           // this.menus = this.tokenStorage.getUserMenus();
  //           this.userStoredName = this.tokenStorage.getUser().username;
  //           //this.reloadPage();
  //           this.router.navigate(['/']);
  //           this.toastrService.success("Logged in successfully");

  //           //Start watching for user inactivity.
  //           this.userIdle.startWatching();

  //           // Start watching when user idle is starting.
  //           this.userIdle.onTimerStart().subscribe(count => // console.log(count));

  //           // Start watch when time is up.
  //           this.userIdle.onTimeout().subscribe(() => {
  //             // console.log('Time is up!');
  //             this.stopWatching();
  //             this.stop();
  //             this.tokenStorage.signOut();
  //             this.router.navigate(['/login']);
  //             this.isLoginFailed = true;
  //             this.errorMessage = "User Session is Timed Out- Kindly Re-login...";
  //           });
  //         } else {
  //           this.isLoginFailed = true;
  //           this.toastrService.error(data.msg);
  //         }
  //       },
  //       err => {
  //         this.errorMessage = err.error.message;
  //         this.isLoginFailed = true;
  //         this.toastrService.error(err.error.message);
  //       }
  //     );
  //   }
  // }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  connectAzure() {
    this.azureConnectionService.connectAzure((token) => {
      // console.log(token)
      const headers: HttpHeaders = new HttpHeaders()
        .append('Authorization', token.accessToken)
        .append('Accept', 'application/json');
      let reqOpts: any = {
        headers: headers
      };
      this.httpClient.get('https://graph.microsoft.com/v1.0/me', reqOpts).subscribe((res: any) => {
        localStorage.clear();
        sessionStorage.clear();
        if (res) {
          this.login(res);
        }
      })
    })
  }

  login(user) {
    let body: any = {};
    body.mail = user.mail;
    body.userPrincipalName = user.userPrincipalName;
    body.deviceId = "12345";
    body.pushToken = this.messageService.token;
    body.deviceTypeName = "web";
    console.log(body)
    this.api.post('8082/api/signin', body).subscribe((result) => {
      if (result.isLogin) {
        this.tokenStorage.saveUser(result);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().roles;
        // this.menus = this.tokenStorage.getUserMenus();
        this.userStoredName = this.tokenStorage.getUser().username;
        this.toastrService.success("Logged in successfully");

        window.location.reload();

        //Start watching for user inactivity.
        // this.userIdle.startWatching();

        // // Start watching when user idle is starting.
        // this.userIdle.onTimerStart().subscribe(count => // console.log(count));

        // // Start watch when time is up.
        // this.userIdle.onTimeout().subscribe(() => {
        //   // console.log('Time is up!');
        //   this.stopWatching();
        //   this.stop();
        //   this.tokenStorage.signOut();
        //   this.router.navigate(['/login']);
        //   this.isLoginFailed = true;
        //   this.errorMessage = "User Session is Timed Out- Kindly Re-login...";
        // });
      } else {
        this.isLoginFailed = true;
        this.toastrService.error(result.msg);
      }
    });
  }
}
