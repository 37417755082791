<section>
    <!-- <div class="bg-white">
        <div class="d-flex p-2">
            <mat-icon class="m-2">receipt</mat-icon>&nbsp;&nbsp;
            <h3 class="m-1 fw-bold">New Bill</h3>
            <mat-icon class="ms-auto mt-2">close</mat-icon>
        </div>
    </div> -->
    <form [formGroup]="billsForm">
        <div>
            <div class="form-group py-2">
                <div class="d-flex">
                    <label class="text-danger col-md-2 col-sm-4 my-auto">Vendor Name *</label>
                    <select class="form-control col-md-3 col-sm-8" formControlName="vendorSno">
                        <option [value]="vendor.vendorSno" *ngFor="let vendor of vendorList;let i=index">
                            {{vendor.vendorName}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group py-2">
                <div class="d-flex">
                    <label class="text-danger col-2 my-auto">Source Of Supply *</label>
                    <select class="form-control col-3" formControlName="sourceOfSupplyCd">
                        <option *ngFor="let source of stateList;" [value]="source?.stateSno">
                            {{source.stateName}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group py-2">
                <div class="d-flex">
                    <label class="text-danger col-2 my-auto">Destination Of Supply *</label>
                    <select class="form-control col-3" formControlName="destinationOfSupplyCd">
                        <option *ngFor="let destination of stateList;" [value]="destination?.stateSno">
                            {{destination.stateName}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group mt-2">
                <div class="d-flex">
                    <label class="text-danger col-2 my-auto">Bill# *</label>
                    <input class="form-control w-25" type="text" formControlName="billNo">
                </div>
            </div>
            <div class="form-group">
                <div class="d-flex">
                    <label class=" col-2 my-auto">Order Number</label>
                    <input class="form-control w-25" type="text" formControlName="orderNo">
                </div>
            </div>
            <div class="form-group">
                <div class="d-flex">
                    <label class="text-danger col-2">Bill date *</label>
                    <div class="w-25">
                        <input class="form-control" type="date" formControlName="billDate">
                        <!-- <h5>To create transaction dated before 01/07/2017,<a href="">click here</a></h5> -->
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="d-flex">
                    <label class=" col-2">Due date</label>
                    <div class="w-25">
                        <input class="form-control" type="date" formControlName="dueDate">
                        <div class="d-flex form-check">
                            <input class="form-check-input" formControlName="isReverseCharge" type="checkbox" value=""
                                id="flexCheckDefault">
                            <label class="form-check-label pt-1" for="flexCheckDefault">This transaction is applicable
                                for reverse charge</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <label class="mt-2 mx-5">Payment Terms</label>
                        <div class="mx-4">
                            <select class="form-control" formControlName="paymentTermsCd">
                                <option [value]="payment.codesDtlSno" *ngFor="let payment of paymentList">
                                    {{payment.cdValue}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="w-75">
        <div class="mx-3">
            <!-- <div class="d-flex mb-4">
                <h5 class="mt-2">Discount Type</h5>
                <div class="mx-2">
                    <select class="form-control border-0" style="border-bottom: 4px dotted gray !important;"
                        formControlName="discountTypeCd">
                        <option [value]="discount.codesDtlSno" *ngFor="let discount of discountList">
                            {{discount.cdValue}}</option>
                    </select>
                </div>
            </div> -->
            <div class="mb-3">
                <div>
                    <span class="table-responsive bg-white box-shadow">
                        <table class="table mb-0">
                            <thead>
                                <tr class="bg-primary text-white">
                                    <th>Item Details</th>
                                    <th>Account</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>Tax</th>
                                    <th>Customer Details</th>
                                    <th>Amount</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="bills"  class="table-hover">
                                <tr [formGroup]="detail" class="pointer"
                                    *ngFor="let detail of billsForm?.controls?.bills?.controls;let i = index;">
                                    <td>
                                        <textarea type="text" class="form-control "
                                            placeholder="Type or click to select an item"
                                            formControlName="itemDetail"></textarea>
                                    </td>
                                    <td>
                                        <select class="form-control " formControlName="accountSno">
                                            <option [value]="account.accountSno"
                                                *ngFor="let account of accountList;let i=index">{{account.accountName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control" value="1.00" type="number"
                                            formControlName="quantity">
                                    </td>
                                    <td>
                                        <input class="form-control bg-ligh" value="0.00" type="number"
                                            formControlName="rate">

                                    </td>
                                    <td>
                                        <select class="form-control " formControlName="taxCd">
                                            <option [value]="tax.codesDtlSno" *ngFor="let tax of taxList">
                                                {{tax.cdValue}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control " formControlName="clientSno">
                                            <option [value]="client.clientSno" *ngFor="let client of clients">
                                                {{client.companyName}}</option>
                                        </select>
                                    </td>
                                    <td class="fw-bold">
                                        <input formControlName="amount" class="form-control bg-white" readonly
                                            type="number" [value]="detail.value.quantity * detail.value.rate" />
                                    </td>
                                    <td>
                                        <div (click)="removeVisit(i)" *ngIf="billsForm?.controls?.bills?.length > 1">
                                            <i class="fa fa-close"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </span>
                </div>
                <div class="text-left mb-4">
                    <button type="submit" class="btn btn-gray mt-2" (click)="addDetail()"><i class="fa fa-plus"></i> Add
                        another line</button>
                </div>
            </div>

            <hr class="my-4">
            <div class="row my-2">
                <div class="col-lg-6 col-md-6 col-sm-12 w-50 border-right">
                    <p>Notes</p>
                    <textarea class="form-control" formControlName="notes" cols="30" rows="2"></textarea>
                    <h5 class="text-secondary">It will not be shown in PDF</h5>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 my-auto">
                    <h5 class="my-0 py-0 text-dark mx-1">Attach File(s) to Bill</h5>
                    <div class="file-upload d-flex" *ngIf="!billsForm.value.media?.length">
                        <input type="file" id="expense_media" (change)="fileChange(input)" #input
                            class="file-upload-btn" />
                        <input type="text" class="form-control" placeholder="Choose a file...">
                        <span class="input-group-append">
                            <button class="btn btn-secondary" type="button">
                                <i class="fa fa-upload"></i></button>
                        </span>
                    </div>
                    <div *ngIf="billsForm.value.media?.length" class="text-center py-2" (click)="removeMedia()">
                        {{billsForm.value.media[0]?.fileName}}
                        <i class="fa fa-close"></i>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </form>
    <div class="row mt-5 mx-0">
        <div class="col-12">
            <button *ngIf="!billsForm.value?.billSno " type="button" class="btn btn-success  mb-1 mr-3"
                [disabled]="billsForm.invalid" (click)="save()">Save</button>
            <button *ngIf="billsForm.value?.billSno " type="button" class="btn btn-success  mb-1 mr-3"
                [disabled]="billsForm.invalid" (click)="update()">Update</button>

            <button type="button" class="btn btn-outline-secondary  mb-1 mr-3">Cancel</button>
        </div>
    </div>
</section>