import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ApiService } from '../providers/api/api.service';
declare var swal: any;

@Component({
  selector: 'app-subtask',
  templateUrl: './subtask.component.html',
  styleUrls: ['./subtask.component.scss']
})
export class SubtaskComponent implements OnInit {

  @Input() element: any;
  @Input() taskStatusList: any;
  @Input() userList: any;
  initColumns: any[] = [
    { name: "", key: "subTask" },
    { name: "Tasks", key: "tasks" },
    { name: "Assigned To", key: "assignedTo" },
    { name: "Status", key: "status" },
    { name: "Start Date", key: "startDate" },
    { name: "Due Date", key: "dueDate" },
    { name: "Duration", key: "duration" },
    { name: "Action", key: "action" }
  ];
  subTashColumnsToDisplay = this.initColumns.map(col => col.key);
  taskNameChange = new Subject<string>();
  deleteIndex: number = 0;

  constructor(
    private api: ApiService
  ) {
    this.taskNameChange.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe((index: any) => {
        // console.log(index);
        this.updateSubTask('taskName', this.element.projectSubTask[index].subTaskName, index)
      });
  }

  ngOnInit(): void {
    // console.log(this.element)
  }


  updateSubTask(type: any, input: number, index: number) {
    let body: any = {};
    if (this.element.projectSubTask[index].subTaskSno) {
      if (type == 'assign') {
        body.updateArgumentObj = {
          assigned_app_user_sno: input
        };
      } else if (type == 'status') {
        body.updateArgumentObj = {
          task_status_cd: input
        };
      } else if (type == 'startDate') {
        body.updateArgumentObj = {
          start_date: input
        };
      } else if (type == 'endDate') {
        body.updateArgumentObj = {
          due_date: input
        };
      } else if (type == 'taskName') {
        body.updateArgumentObj = {
          sub_task_name: input
        };
      }
      body.subTaskSno = this.element.projectSubTask[index].subTaskSno;
    } else {
      body = this.element.projectSubTask[index];
    }
    this.api.put('8085/api/update_project_sub_task', body).subscribe((result: any) => {
      // console.log(result);
      if (result && result?.data?.subTaskSno) {
        this.element.projectSubTask[index].subTaskSno = result.data.subTaskSno;
      }
    });
  }

  calculateDiff(startDate, dueDate) {
    startDate = new Date(startDate);
    dueDate = new Date(dueDate);
    if (dueDate >= startDate) {
      let duration: any = Math.floor((Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
      if (duration) {
        return duration;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  }

  deleteSubTask(index: number) {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      dangerMode: true,
      button: {
        text: "Delete",
        closeModal: true,
      }
    }).then(willDelete => {
      if (willDelete) {
        if (this.element.projectSubTask[index]?.subTaskSno) {
          let param: any = {};
          param.subTaskSno = this.element.projectSubTask[index].subTaskSno;
          this.api.delete('8085/api/delete_project_sub_task', param).subscribe((result: any) => {
            // console.log(result);
            if (result && result?.data) {
              this.element.projectSubTask.splice(index, 1);
            }
          });
        } else {
          this.element.projectSubTask.splice(index, 1);
        }
      }
    });
  }

  emptyStringValidation(key: any) {
    if (!key || key?.trim() == "") {
      return true;
    } else {
      return false
    }
  }
}
