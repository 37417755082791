import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/providers/api/api.service';
import { TokenStorageService } from '../login/token-storage.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-adminmenurolemap',
  templateUrl: './adminmenurolemap.component.html',
  styleUrls: ['./adminmenurolemap.component.scss']
})
export class AdminmenurolemapComponent implements OnInit {

  adUser: any;
  roleList: any;
  selectRoleCd: any;
  userType: any;
  authUser: any = this.tokenStorage.getUser();
  selectRoleCdValue: any;
  countryList: any = [];
  selectCountrySno: any;

  constructor(
    public dialogRef: MatDialogRef<AdminmenurolemapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public api: ApiService,
    public toastrService: ToastrService,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit(): void {
    // // console.log(this.data)
    this.adUser = JSON.parse(JSON.stringify(this.data))
    console.log(this.adUser)
    let index = this.authUser?.menus?.findIndex((menu) => menu?.routerLink == '/employee');
    if (index != -1) {
      this.userType = this.authUser?.menus[index]?.userType;
    }

    this.getRoleEnums();

  }

  getRoleEnums() {
    let param: any = { codeType: "role_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      if (result != null && result.data.length > 0) {
        this.roleList = result.data;

        for (let i in this.roleList) {
          this.roleList[i].isDisabled = false;
          if (this.roleList[i].cdValue == this.adUser.role) {
            this.selectRoleCd = this.roleList[i].codesDtlSno;
            this.selectRoleCdValue = this.roleList[i].cdValue;
            break;
          }
        }
        // this.authUser.roleCdValue = 'Employee';
        if (this.authUser.roleCdValue != 'Super Admin') {
          for (let i in this.roleList) {
            if (this.authUser.roleCdValue == 'Global Admin' && this.roleList[i].cdValue == 'Super Admin') {
              this.roleList[i].isDisabled = true;
              break;
            } else if (this.authUser.roleCdValue == 'Admin') {
              if (this.roleList[i].cdValue == 'Super Admin') {
                this.roleList[i].isDisabled = true;
              } else if (this.roleList[i].cdValue == 'Global Admin') {
                this.roleList[i].isDisabled = true;
              }
            } else if (this.authUser.roleCdValue == 'Employee') {
              if (this.roleList[i].cdValue == 'Super Admin') {
                this.roleList[i].isDisabled = true;
              } else if (this.roleList[i].cdValue == 'Global Admin') {
                this.roleList[i].isDisabled = true;
              } else if (this.roleList[i].cdValue == 'Admin') {
                this.roleList[i].isDisabled = true;
              }
            }
          }
        }
        this.getRegion();

      }
    });
  }



  changeRole() {
    for (let i in this.roleList) {
      if (this.roleList[i].codesDtlSno == this.selectRoleCd) {
        this.selectRoleCdValue = this.roleList[i].cdValue;
        break;
      }
    }
    this.getRegion();
  }

  getRegion() {
    this.getCountry();
    if (this.selectRoleCdValue != 'Super Admin') {
      this.setMenus();
    }
  }

  setMenus() {
    for (let i in this.adUser?.menuIds) {
      for (let j in this.adUser?.menuSelectOptions) {
        if (this.adUser?.menuSelectOptions[j].appMenuSno == this.adUser?.menuIds[i].appMenuSno ) {
          this.adUser.menuSelectOptions[j].isAdmin = this.adUser?.menuIds[i].isAdmin ? true : false;
          break;
        }
      }
    }
    $(document).ready(() => {
      $('input[name="usermenu"]').each((i) => {
        for (let j in this.adUser?.menuIds) {
          if (parseInt($('#usermenu' + i).val()) == parseInt(this.adUser?.menuIds[j].appMenuSno)) {
            $('#usermenu' + i).prop("checked", true);
            $('#menuswitch' + i).prop("checked", this.adUser?.menuIds[j].isAdmin);
            break;
          }
        }
      });
    });
  }

  getCountry() {
    let params: any = {};
    if(this.authUser.countries && this.authUser.countries.length > 0 ){
      params.countries = JSON.stringify(this.authUser.countries)
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        this.setCountry();
      } else {
      }
    });
  }

  setCountry() {
    if ((this.selectRoleCdValue != 'Super Admin') && (this.selectRoleCdValue != 'Global Admin')) {
      $(document).ready(() => {
        $('input[name="region"]').each((i) => {
          for (let j in this.adUser?.countryList) {
            if ((parseInt($('#country' + i).val()) == parseInt(this.adUser?.countryList[j]))) {
              $('#country' + i).prop("checked", true);
              break;
            }
          }
        });
      });
    } else {
      $(document).ready(() => {
        $('input[name="region"]').each((i) => {
          $('#country' + i).prop("checked", true);
        });
      });
    }
  }

  updateMenuRole() {


    let body: any = {};
    body.roleCd = this.selectRoleCd;
    body.appUserSno = this.adUser.appUserSno;
    var countryList = [];
    $('input[name="region"]:checked').each(function (i) {
      countryList[i] = parseInt($(this).val());
    });
    body.countryList = countryList;
    if (this.selectRoleCdValue != 'Super Admin') {
      var menuList = [];
      $('input[name="usermenu"]:checked').each(function (i) {
        menuList[i] = $(this).val();
      });
      body.menuList = [];
      for (let i in menuList) {
        for (let j in this.adUser.menuSelectOptions) {
          if (parseInt(menuList[i]) == parseInt(this.adUser.menuSelectOptions[j].appMenuSno)) {
            this.adUser.menuSelectOptions[j].isAdmin =  this.adUser.menuSelectOptions[j].isAdmin? this.adUser.menuSelectOptions[j].isAdmin:false;
              body.menuList.push(this.adUser.menuSelectOptions[j]);
            break;
          }
        }
      }
    }
    console.log(body.menuList)
    if(( this.selectRoleCdValue == 'Admin' || this.selectRoleCdValue == 'Employee') && body.countryList.length ==0){
      this.toastrService.error('Kindly select any one region')
      return;
    }
    if((this.selectRoleCdValue == 'Global Admin' || this.selectRoleCdValue == 'Admin' || this.selectRoleCdValue == 'Employee') && body.menuList.length ==0){
      this.toastrService.error('Kindly select any one module')
      return;
    }
    this.api.put('8082/api/update_app_menu_user', body).subscribe((result: any) => {
      if (result != null && result.data) {
        this.dialogRef.close({ isUpdated: true });
        this.toastrService.success("Assign Successfully");
        if(this.adUser?.appUserSno == this.authUser?.appUserSno){
          window.location.reload();
        }
      }
    }, error => {
      this.toastrService.error(error)
    });
  }

  selectMenu(index: number) {
    if (this.selectRoleCdValue == 'Global Admin' || this.selectRoleCdValue == 'Admin') {
      this.adUser.menuSelectOptions[index].isAdmin = true;
    }
    if(!$('#usermenu' + index).is(':checked')){
      this.adUser.menuSelectOptions[index].isAdmin = false
    }
  }

  selectAdminToogle(index:number){
    if(this.adUser.menuSelectOptions[index].isAdmin)
      $('#usermenu' + index).prop("checked", true);
  }

  // selectRole(index:number){
  //   this.selectRoleCdValue = this.roleList[index].cdValue;
  //   this.getRegion();
  // }

  // updateMenuRole() {
  //   // console.log(this.adUser)
  //   let body: any = {};
  //   body.menuIds = this.adUser.menuSelectOptions;
  //   body.appUserSno = this.adUser.appUserSno;
  //   this.api.put('8082/api/update_app_menu_user', body).subscribe((result: any) => {
  //     // console.log(result);
  //     if (result != null && result.data) {
  //       this.dialogRef.close({ isUpdated: true });
  //     }
  //   }, error => {
  //     this.toastrService.error(error)
  //   });
  // }

  // changeAdmin(i) {
  //   if (this.adUser?.menuSelectOptions[i].isAdmin)
  //     this.adUser.menuSelectOptions[i].isMenuAssign = this.adUser?.menuSelectOptions[i].isAdmin;
  // }
}
