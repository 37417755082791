<div class="p-2">
    <div class="row mb-3">
        <div class="col-12 text-end">
            <button class="btn btn-primary" (click)="getDocx()">
                <i class="bi bi-file-earmark-arrow-down"></i>
            </button>
        </div>
    </div>
    <div class="container p-5  border border-secondary" style="height:100vh">
        <img src="assets/img/logo/logo.png" class="float-right w-25 ">
        <div class="card  bg-primary p-5">
            <div class="row">
                <div class="col-6">
                    <div class="card-body  text-light">
                        <h5 class="card-title text-uppercase fs-1">Project Proposal</h5>
                        <div class="row mt-5">
                            <div class="col-6">
                                <p class="card-text m-0"><span class="text-danger">*</span> Service Proposal Ref:</p>
                                <p class="card-text deal">
                                    <mat-select placeholder="Select Deal No" [formControl]="dealCtrl" #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Find deal..."
                                                [formControl]="dealFilterCtrl" noEntriesFoundLabel="'no matching found'"
                                                [showToggleAllCheckbox]="true"
                                                toggleAllCheckboxTooltipMessage="Select / Unselect All"
                                                [toggleAllCheckboxIndeterminate]="false"
                                                [toggleAllCheckboxChecked]="false">
                                                <mat-icon ngxMatSelectSearchClear
                                                    style="font-size: 14px;">close</mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let deal of filteredDeal | async;"
                                            style="border-bottom:1px solid #ced4da !important;" [value]="deal?.dealSno">
                                            {{ deal?.dealId }}
                                        </mat-option>
                                    </mat-select>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="card-text m-0"><span class="text-danger">*</span> Prepared by:</p>
                                <p class="card-text deal">
                                    <mat-select placeholder="Select Deal Owner" [formControl]="dealOwnerCtrl"
                                        #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Find deal owner..."
                                                [formControl]="dealOwnerFilterCtrl"
                                                noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                                                toggleAllCheckboxTooltipMessage="Select / Unselect All"
                                                [toggleAllCheckboxIndeterminate]="false"
                                                [toggleAllCheckboxChecked]="false">
                                                <mat-icon ngxMatSelectSearchClear
                                                    style="font-size: 14px;">close</mat-icon>
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let deal of filteredDealOwner | async;"
                                            style="border-bottom:1px solid #ced4da !important;"
                                            [value]="deal?.dealOwnerSno">
                                            {{ deal?.dealOwnerName }}
                                        </mat-option>
                                    </mat-select>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 text-end mt-5">
                    <div class="card-body  text-light mt-3">

                        <p class="card-text m-0">Proposal Issued:</p>
                        <p class="card-text">
                            <input type="date" class="form-control ml-auto m-2 w-50" [(ngModel)]="proposalIssuedDate" />
                        </p>
                        <p class="card-text m-0">Proposal Valid:</p>
                        <p class="card-text">
                            <b *ngIf="!proposalIssuedDate">- - - - -</b>
                            <span *ngIf="proposalIssuedDate">{{calCulateIssuedDate()}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container p-5  content border border-secondary">
        <img src="assets/img/logo/logo.png" class="float-right w-25 ">
        <div class="row mt-5 text-secondary">
            <div class="col-5 mt-5">
                STATEMENT OF WORK
            </div>
            <div class="col-auto mt-5">
                CONFIDENTIAL
            </div>
        </div>
        <hr>
        <h3>Contents</h3>
        <li class="sgLi w-100">
            <ol class="w-100">
                <li>1.<span>Overview</span><span class="float-right">3</span></li>
                <li>2.<span>Project Activities &amp; Scope</span><span class="float-right">3</span>
                    <div class="sub">
                <li>2.1<span>In scope</span><span class="float-right">3</span></li>
                <li>2.2<span>Prerequisites</span><span class="float-right">4</span></li>
    </div>
    </li>
    <li>3.<span>Out of scope</span><span class="float-right">4</span></li>
    <li>4.<span>General Assumptions</span><span class="float-right">4</span></li>
    <li>5.<span>Customer Responsibilities</span><span class="float-right">5</span></li>
    <li>6.<span>Project timeline</span><span class="float-right">5</span></li>
    <li>7.<span>Governance</span><span class="float-right">5</span>
        <div class="sub">
    <li>7.1<span>Scope Change</span><span class="float-right">5</span></li>
    <li>7.2<span>Change Management Process</span><span class="float-right">5</span></li>
    <li>7.3<span>Change Approval Process</span><span class="float-right">6</span></li>
    <li>7.4<span>Communication & Reporting</span><span class="float-right">6</span></li>
</div>
</li>
<li>8.<span>Acceptanc</span><span class="float-right">6</span></li>
<li>9.<span>ANNEX 1</span><span class="float-right">8</span></li>
<div class="sub">
    <li>QUOTATION<span class="float-right">8</span></li>
</div>
<li>10.<span>PAYMENT TERMS</span><span class="float-right">8</span></li>
<li>11.<span>APPENDIX 1 – TERMS & CONDITION</span><span class="float-right">9</span></li>
</ol>
</li>
</div>


<div class="container p-5  border border-secondary">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <div class="w-100 mb-3">
        <h3 class="text-primary">1. Overview</h3>
        <textarea class="w-100 form-control" autosize [(ngModel)]="overView"></textarea>
    </div>
    <div class="w-100 mb-3">
        <h3 class="text-primary">2. Project Activities & Scope</h3>
        <textarea class="w-100 form-control" autosize [(ngModel)]="projectActivities"></textarea>
        <div class="w-80 mb-3 mx-5 mt-3">
            <h3 class="text-primary">2.1. In scope:</h3>
            <textarea autosize class="w-100 form-control" [(ngModel)]="projectScope"></textarea>
        </div>
        <div class="w-80 mb-3 mx-5 mt-3">
            <h3 class="text-primary">2.2. Prerequisites:</h3>
            <textarea autosize class="w-100 form-control" [(ngModel)]="prerequisites"></textarea>
        </div>
    </div>
    <div class="w-100 mb-3">
        <h3 class="text-primary">3. Out of scope</h3>
        <ul>
            <li>License, Software, certificate, and Hardware compatibility</li>
            <li> Upgrade or Patching of OS or any applications</li>
            <li>Any issues due to product limitations or tool limitations</li>
            <li>Anything not mentioned in the in-scope.</li>
        </ul>
    </div>
    <div class="w-100 mb-3">
        <h3 class="text-primary">4. General Assumptions</h3>
        <p>This statement of work is made based on understanding the client’s requirements. Any deviation to the scope
            of
            services or if any of this information is not accurate, project timelines and costs will increase.</p>
        <ul>
            <li>Need Both Primary & DR Vcenter Administrator access to enable SRM deployment.</li>
            <li>Professional Services delivered under this SOW do not provide any application, system performance,
                availability, or any other quality of service guarantees, implied or otherwise. The actual quality of
                service
                and performance may be influenced by uncontrollable variables in the environment.</li>
            <li>ToCumulus may engage subcontractors to provide or assist in providing Professional Services, in which
                case
                ToCumulus remains responsible for the fulfillment of its obligations and the performance of this
                Statement of
                Work</li>
            <li>ToCumulus will invoke a change order request upon discovery and/or during project execution; if the
                scope of
                services needs modification for the success of the project and/or is changed by the end user. </li>
            <li>Change order request is not limited to hours; hourly rate; software licensing; or hardware material cost
                and
                is up to ToCumulus’s discretion.
            </li>
            <li>All work is to be performed remotely unless specified explicitly.
            </li>
            <li>A minimum one-week notice is required to assign resources and kick off the project.
            </li>
            <li>Any software or hardware required to execute this statement of work is not included and will be billed
                to
                actual to the Customer.</li>
        </ul>
    </div>
</div>


<div class="container p-5  border border-secondary">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <h3 class="text-primary">5. Customer Responsibilities</h3>
    <ul>
        <li>The Customer shall designate a primary project contact. This person shall: handle and monitor all
            communications
            related to this project and have the authority to act on the Customer’s behalf in matters regarding this
            project.
        </li>
        <li> The Customer shall grant Tocumulus adequate and reasonable access to its network, services, and end-user
            PCs
            where necessary. If such access requires authorization and provisioning, the Customer shall inform Tocumulus
            in
            advance.
        </li>
        <li>The Customer performs all appropriate back-up before the kick-off. Tocumulus will not be responsible for any
            data loss.
        </li>
        <li>The Customer's employees shall answer Tocumulus questions and provide crucial information on the project
            timely
            (e.g., network design, existing processes, IP addresses, usernames, passwords, etc.). Delays may increase
            the time
            and cost of the project.
        </li>
        <li>The Customer shall ensure that Tocumulus is notified of any issues that may affect this SOW.</li>
    </ul>

    <h3 class="text-primary">6. Project timeline</h3>
    <p>Tocumulus Project Manager will kick off the project upon receiving a signed SOW. The standard lead time to kick
        off
        the project is 5 business days.</p>

</div>

<div class="container p-5  border border-secondary">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <h3 class="text-primary">7. Governance</h3>

    <h3 class="text-primary">7.1. Scope Change</h3>
    <p>Suppose Crayon wishes Tocumulus to undertake work not included in this Scope Definition. In that case, the Scope
        Change Management Process can be invoked, as per the Tocumulus Terms and Conditions, and only after written
        consent
        from – Crayon’s Key Project Stakeholders.

        It may become necessary to amend this SoW for reasons including, but not limited to, the following:</p>
    <br>
    <ul>
        <li>Changes to the scope of work and specifications for the Services or deliverables
        </li>
        <li> Changes to the project schedule
        </li>
        <li>The inability of external vendors to provide a functionally suitable product.
        </li>
        <li>Information that was not known at negotiation and creation of this statement of work comes to light which,
            if
            known, would have affected the quotation.
        </li>
        <li>Environmental or architectural impediments have not previously been identified.
        </li>
        <li>Crayon obligations not being met.</li>
        <li>Delays to the project schedule beyond Tocumulus control.</li>
    </ul>
    <br>
    <p>Either party may initiate a Change Request for any material changes to the Sow</p>
    <h3 class="text-primary">7.2. Change Management Process</h3>
    <ul>
        <li>Either party can initiate a Change Request for any material changes to the Sow</li>
        <li>The Tocumulus delivery consultant will prepare a change request document describing the nature of the
            change,
            the reason for the change, and the effect of the change on the SoW, which may include changes to the project
            schedule, price, and deliverables. Project Managers will review the proposed change.
        </li>
        <li>The parties will evaluate the Change Request and negotiate in good faith the changes to the Services and the
            additional charges, if any, are required to implement the Change Request. Suppose both parties agree to
            implement
            the Change Request. In that case, the appropriate authorized representatives of the parties will sign the
            Change
            Request, indicating the acceptance of the changes by the parties before the change request is executed.
        </li>
        <li>Crayon acknowledges that change requests will impact the project price and schedule. This includes both the
            impact of the change request evaluation and the effect of implementation.
        </li>
    </ul>
</div>

<div class="container p-5  border border-secondary">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <h3 class="text-primary">7.3. Change Approval Process</h3>
    <ul>
        <li>Crayon Key Project Stakeholder (TBA) will be the authorized representative for approving changes to this
            Sow.
        </li>
        <li>The Tocumulus practice lead will be designated as the authorized Tocumulus representative for approving
            changes
            to this Sow.</li>
    </ul>
    <h3 class="text-primary">7.4. Communication & Reporting</h3>
    <p>Communication and Reporting related to this project will follow the communications plan below.
        Audience Groups:</p>
    <ol>
        <li>All – everyone associated with the project.</li>
        <li>Client Stakeholders – Crayon and Tocumulus representative</li>
        <li>Crayon’s Project Manager (User / Technical)</li>
        <li>Core Project Team – those involved in the day-to-day project activities.</li>
        <li>Super Users – those involved with Staging and initial testing.</li>
    </ol>

    <table>
        <tr class="bg-primary text-light">
            <th>Activity</th>
            <th>Format</th>
            <th>Responsibility</th>
            <th>Audience</th>
            <th>Frequency</th>
        </tr>
        <tr>
            <td>Kick-off meeting</td>
            <td>Teams</td>
            <td>ToCumulus</td>
            <td>Project Team</td>
            <td>Once</td>
        </tr>
        <tr>
            <td>Kick-off meeting minutes</td>
            <td>Word</td>
            <td>ToCumulus</td>
            <td>Project Team</td>
            <td>Once</td>
        </tr>
        <tr>
            <td>Status Update Meetings</td>
            <td>Teams/ Email</td>
            <td>ToCumulus/Crayon</td>
            <td>Project Team</td>
            <td>As needed</td>
        </tr>
        <tr>
            <td>SOW</td>
            <td>PDF</td>
            <td>Crayon</td>
            <td>Project Team</td>
            <td>Once</td>
        </tr>
        <tr>
            <td>Weekly Cadence</td>
            <td>Teams</td>
            <td>ToCumulus/Crayon</td>
            <td>Project Team, Crayon/ Customer Stakeholder</td>
            <td>Weekly</td>
        </tr>
        <tr>
            <td>Weekly Cadence</td>
            <td>Teams</td>
            <td>ToCumulus/Crayon</td>
            <td>Project Team, Crayon/ Customer Stakeholder</td>
            <td>Weekly</td>
        </tr>
        <tr>
            <td>Lessons Learned & Next
                Steps Meeting</td>
            <td>Teams</td>
            <td>ToCumulus/Crayon</td>
            <td>Project Team</td>
            <td>Once</td>
        </tr>
        <tr>
            <td>Closure</td>
            <td>Word</td>
            <td>ToCumulus/Crayon</td>
            <td>Project Team, Crayon/ Customer Stakeholder</td>
            <td>Once</td>
        </tr>
    </table>
    <h3 class="text-primary">8. Acceptance</h3>
    <p>By signing this form, I now agree to all the terms and conditions of this scope of work, prices, and other
        matters
        duly stated.

        This SOW and the Annexures constitute the agreement between Tocumulus and Crayon as to the subject matter hereof
        and
        supersedes all prior discussions or understandings, whether written or oral, between the Parties hereto.

        I also confirm the purchase order of the product and services stated in the scope and pricing schedule.</p>
</div>

<div class="container p-5  border border-secondary h-100">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <table>
        <tr class="bg-primary text-light">
            <th col="2">Signatures:</th>
            <th></th>
        </tr>
        <tr>
            <td><span class="text-danger">*</span> For and on behalf of
                <b>
                    <mat-select [formControl]="companyCtrl" placeholder="Select company">
                        <mat-option *ngFor="let company of companyList"
                            style="border-bottom:1px solid #ced4da !important;" [value]="company?.codesDtlSno">
                            {{company?.cdValue}}
                        </mat-option>
                    </mat-select>
                </b>
            </td>
            <td><span class="text-danger">*</span> For and on behalf of
                <mat-select [formControl]="clientCtrl" placeholder="Select account" #singleSelect1
                    (selectionChange)="onChangeClient($event)">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Find Client" [formControl]="clientFilterCtrl"
                            noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                            toggleAllCheckboxTooltipMessage="Select / Unselect All"
                            [toggleAllCheckboxIndeterminate]="false" [toggleAllCheckboxChecked]="false">
                            <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let client of filteredClients | async "
                        style="border-bottom:1px solid #ced4da !important;" [value]="client?.clientSno">
                        {{client?.companyName}}
                    </mat-option>
                </mat-select>
            </td>
        </tr>
        <tr>
            <td><span class="text-danger">*</span> Signatory Name: <br>
                <mat-select [formControl]="signatoryCtrl" placeholder="Select Name">
                    <mat-option *ngFor="let signatory of signatoryList"
                        style="border-bottom:1px solid #ced4da !important;" [value]="signatory?.codesDtlSno">
                        {{signatory?.cdValue}}
                    </mat-option>
                </mat-select>
            </td>
            <td><span class="text-danger">*</span> Signatory Name: <br>
                <mat-select [formControl]="contactCtrl" placeholder="Select contact..." [multiple]="false">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Find Client" [formControl]="contactFilterCtrl"
                            noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                            toggleAllCheckboxTooltipMessage="Select / Unselect All"
                            [toggleAllCheckboxIndeterminate]="false" [toggleAllCheckboxChecked]="false">
                            <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let contact of filteredContact | async "
                        [style.display]="api?.isEmptyString(contact?.first_name) ? 'block' : 'none' "
                        style="border-bottom:1px solid #ced4da !important;" [value]="contact?.contact_person_id">
                        {{contact?.first_name }}
                        <span *ngIf="api?.isEmptyString(contact?.last_name)">
                            {{contact?.last_name}}
                        </span>
                    </mat-option>
                </mat-select>
            </td>
        </tr>
        <tr>
            <td>Signatory Title: Director - Operations</td>
            <td>Signatory Title: National Head – Services Sales</td>
        </tr>
    </table>
</div>

<div class="container p-5  border border-secondary h-100">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <h3 class="text-primary">9. PAYMENT TERMS</h3>
    <ul>
        <li>ToCumulus will bill “Crayon” on a fixed fee basis for this project. A PO for the same is to be provided by
            the customer. (Please refer to Annex 1 for Quota).
        </li>
        <li>Invoicing will occur for the items that are defined in scope in advance. "Crayon" agrees to pay the
            submitted invoices within 30 days of the invoice date.
        </li>
        <li>This quotation is made based on understanding our client’s requirement as a price for the services to be
            rendered. Any change to the services identified above must be provided in writing. Changes to the services
            or their functionality identified above may impact the quota.</li>
    </ul>
    <h3 class="text-primary">10. APPENDIX 1 – TERMS CONDITIONS</h3>
    <p>
        1. <b>Services:</b> It is understood and agreed that ToCumulus Technology Solutions LLP, (“ToCumulus”) services
        may
        include
        advice and recommendations; but all decisions in connection with the implementation of such advice and
        recommendations shall be the responsibility of, and made by, Customer. References herein to Customer shall refer
        to
        the addressee of the SOW, proposal, or engagement letter to which these Standard Terms and Conditions are
        attached
        (the “Engagement Letter”).
    </p>
    <p>
        2. <b>Payment of Invoices:</b> Customer agrees to pay properly submitted invoices within thirty (30) days of
        receipt of
        the
        invoice, or such other due date as may be indicated in the Engagement Letter. ToCumulus shall have the right to
        halt or
        terminate entirely its services under the Engagement Letter until payment is received on past due invoices. All
        fees,
        charges, and other amounts payable to ToCumulus under the Engagement Letter do not include any sales, use,
        excise,
        value-added or other applicable taxes, tariffs, or duties, payment of which shall be Customer’s sole
        responsibility,
        excluding any applicable taxes based on ToCumulus’s net income or taxes arising from the employment or
        independent
        contractor relationship between ToCumulus and its personnel.
    </p>
    <p>
        3. <b>Term:</b> Unless terminated sooner in accordance with its terms, the engagement shall terminate upon the
        completion
        of ToCumulus’s services under the Engagement Letter. In addition, either party may terminate the Engagement
        Letter
        at any time by giving written notice to the other party not less than 30 calendar days before the effective date
        of
        termination.
    </p>
    <div>
        4. Ownership: <br>
        <ul id="ownership">
            <li>
                ToCumulus Property and Customer Intellectual Property. ToCumulus, or its subcontractors and
                licensors,
                have
                created, acquired, owns, or otherwise have rights in, and may, in connection with the performance of
                services
                under the Engagement Letter, employ, provide, modify, create, acquire, or otherwise obtain rights in,
                various
                concepts, ideas, methods, methodologies, procedures, tools, processes, know-how, and techniques, models,
                templates; software, user interfaces, and screen designs; general purpose consulting and software tools,
                utilities and routines; and logic, coherence, and methods of operation of systems (collectively, the
                “ToCumulus
                Property”). As between ToCumulus and Customer, ToCumulus retains all ownership rights in the ToCumulus
                Property created, acquired, owned, developed, or derived solely by ToCumulus either prior to or because
                of
                the services provided under this Engagement Letter. Customer shall acquire no right or interest in such
                property, except for the license expressly granted in the next paragraph. In addition, ToCumulus shall
                be
                free
                to provide services of any kind to any other party as ToCumulus deems appropriate and may use the
                ToCumulus Property to do so. ToCumulus acknowledges that ToCumulus Property shall not include any of
                Customer’s confidential information or Customer’s tangible or intangible property, including without
                limitation, patents, and other intellectual property rights, and ToCumulus shall have no ownership
                rights in
                such property. For clarification, Customer is not obligated to license any of its patents or other
                intellectual
                rights under this Engagement Letter.
            </li>
            <li>
                Ownership of Deliverables: Except for ToCumulus Property, and upon full and final payment to ToCumulus
                under the Engagement Letter, the tangible items specified as in-scope or work product in the Engagement
                Letter including any intellectual property rights appurtenant thereto (the “Deliverables”) will become
                the property of the customer. If any ToCumulus Property is contained in any of the Deliverables,
                ToCumulus
                hereby grants Customer a royalty-free, paid-up, nonexclusive, perpetual license to use such ToCumulus
                Property in connection with Customer’s use of the Deliverables.
            </li>
        </ul>
    </div>
</div>
<div class="container p-5  border border-secondary h-100">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <p>
        5. <b>Limitation on Warranties:</b> THIS IS A SERVICES ENGAGEMENT. TOCUMULUS WARRANTS THAT IT WILL PERFORM
        SERVICES UNDER THE ENGAGEMENT LETTER IN GOOD FAITH, WITH QUALIFIED PERSONNEL IN A COMPETENT AND
        WORKMANLIKE MANNER IN ACCORDANCE WITH APPLICABLE INDUSTRY STANDARDS. TOCUMULUS DOES NOT
        WARRANT THAT THE SERVICES WILL BE ERROR-FREE OR WILL MEET CUSTOMERS’ REQUIREMENTS. TOCUMULUS
        DISCLAIMS ALL OTHER WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES
        OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        ToCumulus is not responsible for any delays, delivery failures, loss or corruption of data, or any other loss or
        damage
        resulting from the transfer of data over communications networks and facilities, including the internet, or
        related to
        any services provided by third parties, and Customer acknowledges that the Services may be subject to
        limitations,
        delays, and other problems inherent in the use of such communications facilities.
    </p>
    <p>
        6. <b>Limitation on Damages:</b> Except for each party’s indemnification obligations as set forth below, neither
        Customer nor
        ToCumulus shall be liable to the other for any actions, damages, claims, liabilities, costs, expenses, or losses
        in any way
        arising out of or relating to the services performed under the Engagement Letter for an aggregate amount in
        excess of
        the fees paid or owing to ToCumulus for services rendered by ToCumulus under the Engagement Letter in the
        twelve-
        month period before the claim arose. In no event shall either party be liable for consequential, special,
        indirect,
        incidental, punitive, or exemplary damages, costs, expenses, or losses (including, without limitation, lost
        profits, and
        opportunity costs). ToCumulus shall have no liability with respect to the results of any audit or payment
        requests of any
        vendor of Customer. Customer shall be solely responsible for Customer’s deployment and use of its software
        assets.
        customer assumes sole responsibility for information and results obtained from the use of the services and for
        conclusions drawn from such use. The provisions of this Paragraph shall apply regardless of the form of action,
        damage,
    </p>
    <div>
        <p>7. <b>Infringement:</b></p>
        <ul id="ownership">
            <li>
                ToCumulus hereby agrees to indemnify, hold harmless and defend Customer from and against all claims,
                liabilities, losses, expenses (including reasonable attorneys’ fees), fines, penalties, taxes, or
                damages
                (collectively &quot;Liabilities&quot;) asserted by any third party against Customer to the extent such
                Liabilities result from
                the infringement by the Deliverables of any third party&#39;s patents issued as of the date of the
                Engagement
                Letter. The preceding indemnification provision shall not apply to any infringement arising out of the
                following:
                <div class="sub">
                    <div class="d-flex">
                        <div class="me-3">
                            (i)
                        </div>
                        <div>
                            use of the Deliverables other than in accordance with applicable documentation or
                            instructions
                            supplied by ToCumulus or other than in accordance with Paragraph 8(b).
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="me-3">
                            (ii)
                        </div>
                        <div>
                            any alteration, modification, or revision of the Deliverables not expressly agreed to in
                            writing by
                            ToCumulus; or
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="me-3">
                            (iii)
                        </div>
                        <div>
                            the combination of the Deliverables with materials not supplied or approved by ToCumulus.
                        </div>
                    </div>
                </div>
            </li>
            <li>
                In case any of the Deliverables or any portion thereof is held, or in ToCumulus’s reasonable opinion is
                likely to
                be held, in any such suit to constitute infringement, ToCumulus may, within a reasonable time, at its
                option
                either:
                <div class="sub">
                    <div class="d-flex">
                        <div class="me-3">
                            (i)
                        </div>
                        <div>
                            secure for Customer the right to continue the use of such infringing item; or
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="me-3">
                            (ii)
                        </div>
                        <div>
                            replace, at ToCumulus’s sole expense, such item with a substantially equivalent
                            non-infringing
                            item or modify such item so that it becomes non-infringing.
                        </div>
                    </div>
                </div>
                In the event, ToCumulus is, in its reasonable discretion, unable to perform either of the options
                described in
                <div class="sub">
                    (i) or (ii) above, Customer shall return the Deliverable to ToCumulus, and ToCumulus’s sole
                    liability shall be
                    to refund to Customer the amount paid to ToCumulus for the items; provided that the foregoing shall
                    not
                    be construed to limit ToCumulus’s indemnification obligation set forth in Paragraph 7(a) above.
                </div>
            </li>
            <li>
                The provisions of this Paragraph 7 state ToCumulus’s entire liability and Customer’s sole and exclusive
                remedy
                with respect to any infringement or claim of infringement.
            </li>
        </ul>
    </div>
</div>
<div class="container p-5  border border-secondary h-100">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <div>
        <p>8. <b>Indemnification:</b></p>
        <ul id="ownership">
            <li>
                Each party agrees to indemnify, hold harmless and defend the other party from and against all
                Liabilities for
                physical injury to, or illness or death of, any person or persons regardless of status, and damage
                to or
                destruction of any tangible property, which the other party may sustain or incur, to the extent such
                Liabilities
                result from the negligence or willful misconduct of the indemnifying party.
            </li>
            <li>
                Except as otherwise required by law, or as permitted by the Engagement Letter, Customer acknowledges
                and
                agrees that any advice, recommendations, information, or work product provided to Customer by
                ToCumulus
                in connection with this engagement is for the confidential use of Customer, may not be relied upon
                by
                any
                third party and Customer will not disclose or permit access to such advice, recommendations,
                information
                or
                work product to any third party or summarize or refer to such advice, recommendations, information
                or
                work
                product or ToCumulus&#39;s engagement under the Engagement Letter without, in each case,
                ToCumulus&#39;s
                prior
                written consent. In furtherance of the foregoing, Customer will indemnify, defend, and hold harmless
                ToCumulus from and against any and all Liabilities suffered by or asserted against ToCumulus in
                connection
                with a third-party claim to the extent resulting from such party’s use or possession of or reliance
                upon
                ToCumulus’s advice, recommendations, information or work product as a result of Customer’s use or
                disclosure of such advice, recommendations, information or work product.
            </li>
            <li>
                The party entitled to indemnification (the “Indemnified Party”) shall promptly notify the party
                obligated to
                provide such indemnification (the “Indemnifying Party”) of any claim for which the Indemnified Party
                seeks
                indemnification. The Indemnifying Party shall have the right to conduct the defense or settlement of
                any
                such
                claim at the Indemnifying Party&#39;s sole expense, and the Indemnifying Party shall cooperate with
                the
                Indemnifying Party. The party not conducting the defense shall nonetheless have the right to
                participate
                in
                such defense at its own expense. The Indemnified Party shall have the right to approve the
                settlement of
                any
                claim that imposes any liability or obligation other than the payment of monetary damages.
            </li>
        </ul>
        <p>9. <b>Cooperation; Use of Information:</b></p>
        <ul>
            <li>
                Customer agrees to cooperate with ToCumulus in the performance of the services under the Engagement
                Letter and shall provide ToCumulus with timely access to and use of Customer&#39;s personnel,
                facilities,
                equipment, data, and information to the extent necessary for ToCumulus to perform the services under the
                Engagement Letter. The customer shall provide ToCumulus with license entitlement information. The
                Engagement Letter may set forth additional obligations of the Customer in connection with this
                engagement.
                Customer acknowledges that Customer&#39;s failure to assign Customer personnel with skills commensurate
                with
                their role concerning this engagement could adversely affect ToCumulus’s ability to provide the services
                under
                the Engagement Letter.
            </li>
            <li>
                Customer acknowledges and agrees that ToCumulus may, in performing its obligations pursuant to this
                Agreement, use data, material, and other information furnished by Customer without any independent
                investigation or verification and that ToCumulus shall be entitled to rely upon the accuracy and
                completeness
                of such information in performing the services under the Engagement Letter.
            </li>
        </ul>
        <p>10. <b>Force Majeure:</b> Neither Customer nor ToCumulus shall be liable for any delays resulting from
            circumstances or
            causes beyond its reasonable control, including, without limitation, fire or another casualty, the act of
            God, strike or
            Labor dispute, war or other violence, or any law, order or requirement of any governmental agency or
            authority.</p>
        <p>11. <b>Limitation on Actions:</b> No action, regardless of form, arising out of or relating to this
            engagement, may be brought
            by either party more than one year after the cause of action has accrued, except that an action for
            non-payment may
            be brought by a party not later than one year following the date of the last payment due to such party under
            the
            Engagement Letter.</p>
        <p>12. <b>Independent Contractor:</b> It is understood and agreed that each party hereto is an independent
            contractor and that
            neither party is or shall be considered an agent, distributor, or representative of the other. Neither party
            shall act or
            represent itself, directly or by implication, as an agent of the other or in any manner assume or create any
            obligation on
            behalf of, or in the name of, the other.</p>
    </div>
</div>
<div class="container p-5  border border-secondary h-100">
    <img src="assets/img/logo/logo.png" class="float-right w-25 ">
    <div class="row mt-5 text-secondary">
        <div class="col-5 mt-5">
            STATEMENT OF WORK
        </div>
        <div class="col-auto mt-5">
            CONFIDENTIAL
        </div>
    </div>
    <hr>
    <div>
        <p>13. <b>Confidentiality:</b></p>
        <ul class="lower-alpha">
            <li>“Confidential Information” means all documents, software, reports, data, records, forms, and other
                materials
                obtained by one party (the “Receiving Party”) from the other party (the “Disclosing Party”) while
                performing
                the services under the Engagement Letter:<br>
                <div class="sub">
                    <ul class="upper-roman">
                        <li>that have been marked as confidential.</li>
                        <li>whose confidential nature has been made known by the Disclosing Party to the Receiving
                            Party; or</li>
                        <li>that due to their character and nature, a reasonable person under like circumstances would
                            treat it as
                            confidential. Notwithstanding the foregoing, Confidential Information does not include
                            information
                            that:<br>
                            <div class="sub">
                                <ul class="upper-roman">
                                    <li>is already known to the Receiving Party at the time of disclosure by the
                                        Disclosing Party.</li>
                                    <li>is or becomes publicly known through no wrongful act of the Receiving Party.
                                    </li>
                                    <li>is independently developed by the Receiving Party without the benefit of the
                                        Disclosing
                                        Party’s Confidential Information or<br>
                                        <div class="sub">
                                            <ul class="lower-alpha">
                                                <li>is received by the Receiving Party from a third party without
                                                    restriction and
                                                    without a breach of an obligation of confidentiality.</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                The Receiving Party will deliver to the Disclosing Party all Confidential Information of the Disclosing
                Party and
                all copies thereof when the Disclosing Party requests the same, except for one copy thereof that the
                Receiving
                Party may retain for its records. The Receiving Party shall not use or disclose to any person, firm, or
                entity any
                Confidential Information of the Disclosing Party without the Disclosing Party’s express, prior written
                permission; provided, however, that notwithstanding the foregoing, and subject to Paragraph 13 (d), the
                Receiving Party may disclose Confidential Information to the extent that it is required to be disclosed
                pursuant
                to a statutory or regulatory provision or court order or to fulfill professional obligations and
                standards.
            </li>
            <li>
                Each party shall be deemed to have met its nondisclosure obligations under this Paragraph 13 if it
                exercises
                the same level of care to protect the other’s information as it exercises to protect its own
                confidential
                information but in no event less than reasonable care, except to the extent that applicable law or
                professional
                standards impose a higher requirement.
            </li>
            <li>
                If the Receiving Party receives a subpoena, other validly issued administrative or judicial demand, or
                has a
            </li>
            <li>
                professional obligation or standard requiring it to disclose the Disclosing Party’s Confidential
                Information, the
                Receiving Party shall provide prompt written notice to the Disclosing Party of such requirement or
                demand to
                permit it to seek a protective order or otherwise intervene to protect its interests in the Confidential
                Information. The Receiving Party shall provide full cooperation and assistance to the Disclosing Party
                in seeking
                to obtain such protection. So long as the Receiving Party gives prompt notice and fully cooperates, as
                provided
                herein, the Receiving Party shall be entitled to comply with such demand to the extent permitted by law,
                subject to any protective order or the like that may have been entered in the matter.
            </li>
        </ul>
        <p>14. <b>Survival:</b> The provisions of Paragraphs 1, 2, 4, 6, 7, 8, 11, 13, 16, 17, and 18(a) hereof shall
            survive the expiration or
            termination of this engagement.</p>
        <p>15. <b>Assignment:</b> Neither party may assign, transfer or delegate any of its rights or obligations
            without the prior written
            consent of the other party, such consent is not to be unreasonably withheld.</p>
        <p>16. <b>Severability:</b> If any term or provision of this Agreement shall be held to be invalid, void, or
            unenforceable, then the
            remainder of this Agreement shall not be affected, and each such term and provision of this Agreement shall
            be valid
            and enforceable to the fullest extent permitted by law.</p>
        <p>17. <b>Governing Law:</b> The Engagement Letter and these Standard Terms and Conditions shall be governed by
            and
            construed according to the laws of the Republic of India, without regard to the conflict of law provisions
            thereof.</p>
        <p>18. <b>Miscellaneous:</b> </p><br>
        <div>
            <ul class="lower-alpha">
                <li>ToCumulus may communicate with the Customer by electronic mail or otherwise transmit documents in
                    electronic form during this engagement. The customer accepts the inherent risks of these forms of
                    communication (including the security risks of interception of or unauthorized access to such
                    communications,
                    the risks of corruption of such communications, and the risks of viruses or other harmful devices)
                    and
                    agrees
                    that it may rely only upon a final hard copy version of a document or other communication that
                    ToCumulus
                    transmits to Customer.</li>
                <li>
                    ToCumulus may subcontract Services to be performed under the Engagement Letter. Notwithstanding this
                    subsection, ToCumulus’s use of subcontractors will not relieve ToCumulus of the responsibility for
                    the
                    subcontractor’s performance.
                </li>
            </ul>
        </div>
        <p>19. <b>Conflicts Check:</b> Prior to starting each project, ToCumulus will perform an internal search for any
            potential conflicts
            with identified third parties and promptly advise the Customer of conflicts of interest that could prevent
            ToCumulus
            from doing a compliance review of a particular third party.</p>
        <p>20. <b>Entire Agreement:</b> These terms, and the Engagement Letter including Exhibits hereto and thereto,
            constitute the
            entire agreement between ToCumulus and Customer with respect to this engagement and supersede all other oral
            and
            written r 14epresentation, understandings, or agreements relating to this engagement</p>
    </div>
</div>
</div>