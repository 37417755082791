<div class="row p-2">
    <div class="col">
        <div class="input-group mb-3 box-shadow ">
            <div class="input-group-prepend">
                <span class="input-group-text border-top-0 border-left-0 border-bottom-0"><i
                        class="fa fa-search"></i></span>
            </div>
            <input type="text" placeholder="Search by template name" class="form-control border-0">
            <div class="input-group-append pointer" (click)="viewForms()">
                <span class="input-group-text border-top-0 border-right-0 border-bottom-0"><i
                        class="fa fa-plus"></i>&nbsp;&nbsp;Add</span>
            </div>
        </div>
    </div>
</div>
<div class="row p-4">
    <div class="col-md-6 col-lg-4 col-xl-3 px-2 py-2" *ngFor="let i of [1];">
        <div class="card border-0 box-shadow">
            <div class="card-header border-0 rounded-0 text-muted py-2">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <img src="assets/img/ms.png" width="24px" height="24px" />
                        <h6 class="my-auto w-100 ml-3">
                            <b>SOW</b>
                        </h6>
                    </div>
                    <div class="d-flex">
                        <button class="btn text-primary p-0 mr-3" (click)="viewForms()"><i class="fa fa-eye"></i></button>
                        <button class="btn text-primary p-0"><i class="fa fa-edit"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>