<!-- <section>
    <form [formGroup]="vendorForm">
        <div class="row mt-3">
            <div class="col-lg-4 col-md-4 col-sm-12 card bg-light mx-2 p-3 mt-3 h-100">
                <div class="form-floating mb-3 mt-3">
                    <input type="text" formControlName="vendorName" class="form-control" id="floatingInput"
                        placeholder="name@example.com">
                    <label for="floatingInput">Vendor Name *</label>
                </div>
                <div class="form-floating">
                    <select class="form-select" formControlName="activeFlag" id="floatingSelect"
                        aria-label="Floating label select example">
                        <option value="true">Active</option>
                        <option value="false">InActive</option>
                    </select>
                    <label for="floatingSelect">Status *</label>
                </div>
                <div class="mt-4">
                    <button type="submit" class="btn btn-success" *ngIf="vendorForm.value?.vendorSno == null"
                        (click)="save()" [disabled]="vendorForm.invalid || isLoad">
                        <span class="spinner-border spinner-border-sm" *ngIf="isLoad"></span>
                        &nbsp;&nbsp;Save </button>

                    <button type="submit" class="btn btn-success" [disabled]="vendorForm.invalid || isLoad"
                        *ngIf="vendorForm.value?.vendorSno != null" (click)="update()">
                        <span class="spinner-border spinner-border-sm" *ngIf="isLoad"></span>
                        &nbsp;&nbsp;Update
                    </button>
                    <button class="btn btn-outline-secondary mx-3" (click)="clear()"
                        [disabled]="isLoad">Cancel</button>`
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 card bg-light mx-2 mt-3 p-3">
                <table class="mt-3">
                    <thead class="mt-3">
                        <tr>
                            <th class="p-2">Sno</th>
                            <th class="p-2">Vendor Name</th>
                            <th class="p-2">Staus</th>
                            <th class="p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let vendor of vendorList;let i=index">
                        <tr>
                            <td class="p-2">{{i+1}}</td>
                            <td class="p-2">{{vendor.vendorName}}</td>
                            <td>
                                <span class="text-success" *ngIf="vendor?.activeFlag == 'true' ">Active</span>
                                <span class="text-danger" *ngIf="vendor?.activeFlag == 'false' ">InActive</span>
                            </td>
                            <td class="p-2">
                                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                    <div class="btn-group mr-2 btn-group-sm" style="margin-right: 5px;" role="group"
                                        aria-label="First group">
                                        <button type="button" class="btn btn-light" (click)="edit(i)">
                                            <span class="menu-icon tf-icons iconify text-primary">
                                                <i class="fa fa-edit"></i>
                                            </span>
                                        </button>
                                    </div>

                                    <div class="btn-group btn-group-sm" role="group" aria-label="Third group">
                                        <button type="button" class="btn btn-light" (click)="deleteAlert(i)">
                                            <span class="menu-icon tf-icons iconify text-danger">
                                                <i class="fa fa-trash"></i>
                                            </span>
                                            <span class="spinner-border spinner-border-sm" *ngIf="isLoad"></span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</section> -->

<div class="row mb-1">
    <div class="col">
        <div class="input-group mb-3 box-shadow ">
            <div class="input-group-prepend">
                <span class="input-group-text border-top-0 border-left-0 border-bottom-0"><i
                        class="fa fa-search"></i></span>
            </div>
            <input type="text" [(ngModel)]="searchText" placeholder="Search vendor by name..."
                class="form-control border-0" (ngModelChange)="this.searchVendor.next($event)">
            <div class="input-group-append pointer">
                <span class="input-group-text border-top-0 border-right-0 border-bottom-0">
                    <fieldset (click)="updateVendor()"><i class="fa fa-refresh" [class]="isUpdate ? 'rotate' : ''"></i>
                    </fieldset>
                </span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="vendorList?.length" class="row">
    <div class="col-12">
        <div class="table-responsive bg-white box-shadow">
            <table class="table">
                <thead>
                    <tr class="bg-primary text-white">
                        <th>Vendor Name</th>
                        <th>Vendor Id</th>
                    </tr>
                </thead>
                <tbody class="table-hover">
                    <tr *ngFor="let vendor of vendorList | paginate: { itemsPerPage: 9, currentPage: p };let i =index;"
                        class="pointer">
                        <td class="align-middle text-truncate">{{vendor?.vendorName}}</td>
                        <td class="align-middle text-truncate">{{vendor?.vendorId}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="bg-white text-center">
            <pagination-controls autoHide="true" (pageChange)="p = $event"></pagination-controls>
        </div>
    </div>
</div>

<div *ngIf="!vendorList?.length && !isShowLoading" class="text-center">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div *ngIf="isShowLoading" class="text-center">
    <img src="assets/gif/loading.gif" class="position-absolute top-50 start-50" width="110px" />
</div>