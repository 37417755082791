// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB71Cr1GP_oKbyGt4lCktrVp3tZ_egw7CM",
    authDomain: "tocumulus-52149.firebaseapp.com",
    projectId: "tocumulus-52149",
    storageBucket: "tocumulus-52149.appspot.com",
    messagingSenderId: "316125497406",
    appId: "1:316125497406:web:c8c2db0ef8cfebf357696f",
    measurementId: "G-C5HXP4S3M5",
    vapidKey: "BIQHB59SWS8FyPBkrT07ivONtOBKFZmTz4tpQyuUWmS4RWQQ0I-dlUVjqjrKqvQNzgujy3pFvIC35twT7LZZMaQ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
