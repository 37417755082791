import { ComponentFactoryResolver, ComponentRef, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Injectable({
    providedIn: 'root'
})

export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    clientList: any = [];
    selectClientIndex: any;
    selectTopicIndex: any;
    selectSubTopicIndex: any;
    selectedClientSno: any;
    currentPage: any;
    src: any = "'assets/imgs/user-profile.png'";
    token: any;
   
    constructor(
        private router: Router,
        public toastrService: ToastrService
    ) {
        this.router.events.subscribe((event: any) => {
            if (event && event.url) {
                this.currentPage = event.url.split('?')[0];
            }
        });
    }

    requestPermission() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('firebse-message-sw.js').then((registration) => {
                const messaging = getMessaging();
                getToken(messaging,
                    { vapidKey: environment.firebase.vapidKey, serviceWorkerRegistration: registration }).then(
                        (currentToken) => {
                            if (currentToken) {
                                this.token = currentToken;
                                // console.log(currentToken);
                            } else {
                                // console.log('No registration token available. Request permission to generate one.');
                            }
                        }).catch((err) => {
                            // console.log('An error occurred while retrieving token. ', err);
                        });
            });
        }

    }

    // requestPermission() {
    //     const messaging = getMessaging();
    //     getToken(messaging,
    //         { vapidKey: environment.firebase.vapidKey }).then(
    //             (currentToken) => {
    //                 if (currentToken) {
    //                     this.token = currentToken;
    //                     // console.log(currentToken);
    //                 } else {
    //                     // console.log('No registration token available. Request permission to generate one.');
    //                 }
    //             }).catch((err) => {
    //                 // console.log('An error occurred while retrieving token. ', err);
    //             });
    // }

    receivedMsg() {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            // console.log('Message received. ', payload);
            this.toastrService.info(payload?.notification?.body,payload?.notification?.title);
        });
    }


}
