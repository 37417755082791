<table class="table">
    <thead>
        <tr>
            <th scope="col">Tasks</th>
            <th scope="col">Assigned To</th>
            <th scope="col">Status</th>
            <th scope="col">Start Date</th>
            <th scope="col">Due Date</th>
            <th scope="col">Duration</th>
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody class="table-hover">
        <tr *ngFor="let subTask of element?.projectSubTask;let i = index">
            <td>
                <input class="form-control" type="text" [(ngModel)]="subTask.subTaskName"
                    (ngModelChange)="this.taskNameChange.next(i)">
            </td>
            <td>
                <select [(ngModel)]="subTask.assignedAppUserSno" class="form-control w-75 m-auto"
                    (change)="updateSubTask('assign',subTask?.assignedAppUserSno,i)"
                    [disabled]="emptyStringValidation(subTask.subTaskName)">
                    <option *ngFor="let user of userList;let i =index;" [value]="user?.appUserSno">
                        {{user?.name}}</option>
                </select>
            </td>
            <td>
                <select [(ngModel)]="subTask.taskStatusCd" class="form-control w-75 m-auto"
                    (change)="updateSubTask('status',subTask?.taskStatusCd,i)"
                    [disabled]="emptyStringValidation(subTask.subTaskName)">
                    <option *ngFor="let project of taskStatusList; let i = index;" [value]="project?.codesDtlSno">
                        {{project?.cdValue}}
                    </option>
                </select>
            </td>
            <td>
                <input class="form-control w-75 m-auto" type="datetime-local"
                    [readonly]="emptyStringValidation(subTask.subTaskName)"
                    (change)="updateSubTask('startDate',subTask?.startDate,i)" [(ngModel)]="subTask.startDate">
            </td>
            <td>
                <input class="form-control w-75 m-auto" type="datetime-local"
                    [readonly]="emptyStringValidation(subTask.subTaskName)"
                    (change)="updateSubTask('endDate',subTask?.dueDate,i)" [(ngModel)]="subTask.dueDate">
            </td>
            <td>
                <span *ngIf="subTask?.startDate && subTask?.dueDate">
                    {{calculateDiff(subTask?.startDate,subTask?.dueDate)}} day
                </span>
                <span *ngIf="!subTask?.startDate || !subTask?.dueDate">
                    -
                </span>
            </td>
            <td>
                <button class="btn" (click)="deleteSubTask(i)" data-toggle="modal" data-target="#error-modal">
                    <i class="fa fa-trash"></i>
                </button>
            </td>
        </tr>
    </tbody>
</table>