<div class="row mb-4  ">
    <div class="col-12">
        <button mat-raised-button color="primary" class="p-3 ml-3 float-right mr-3" (click)="goToAddNewBill()">
            <mat-icon>add</mat-icon>Bill
        </button>
    </div>
</div>

<div class="row" *ngIf="billList?.length">
    <div class="col-12">
        <div class="table-responsive bg-white box-shadow">
            <table class="table">
                <thead>
                    <tr class="bg-primary text-white">
                        <th>DATE</th>
                        <th>BILL#</th>
                        <th>REFERENCE NUMBER</th>
                        <th>VENDOR NAME</th>
                        <th>STATUS</th>
                        <th>DUE DATE</th>
                        <th>AMOUNT</th>
                        <!-- <th>BALANCE DUE</th> -->
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody  class="table-hover"
                    *ngFor="let bd of billList |  paginate: { id: 'bill',  itemsPerPage: limit, currentPage: p,totalItems:billsCount };let i = index;">
                    <tr class="pointer">
                        <td>{{bd.billDate | date:'dd-MM-YYYY'}}</td>
                        <td class="text-primary">{{bd.billNo}}</td>
                        <td>{{bd.orderNo}}</td>
                        <td>{{bd.vendorName}}</td>
                        <td [style.color]="bd?.statusValue?.colorCode" *ngIf="authUser?.roleCdValue != 'Admin'">
                            {{bd?.statusValue?.msg}}</td>
                        <td *ngIf="authUser?.roleCdValue == 'Admin'">
                            <select class="form-control rounded-pill text-white text-center w-75"
                                [(ngModel)]="bd.statusCd" (change)="changeBillStatus(bd)">
                                <option class="option_bg" *ngFor="let status of statusList;"
                                    [value]="status?.codesDtlSno">
                                    {{status?.cdValue}}
                                </option>
                            </select>
                        </td>
                        <td>{{bd.dueDate | date:'dd-MM-YYYY'}}</td>
                        <td>{{bd.totalAmount | number :
                            '1.2-2'}}</td>
                        <!-- <td>{{bd.totalAmount}}</td> -->
                        <td>
                            <div class="btn-group" role="group">
                                <button class="btn btn-info btn-sm" (click)="edit(i)"><i
                                        class="fa fa-pencil"></i></button>
                                <button class="btn btn-info btn-sm" (click)="delete(i)"><i
                                        class="fa fa-trash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div *ngIf="!billList?.length && !isShowLoading" class="text-center">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div *ngIf="isShowLoading" class="text-center">
    <img src="assets/gif/loading.gif" class="position-absolute top-50 start-50" width="110px" />
</div>

<div class="row" *ngIf="billList?.length">
    <div class="col-12">
        <div class="bg-white text-center">
            <pagination-controls id="bill" (pageChange)="getBill($event);p=$event" responsive="true">
            </pagination-controls>
        </div>
    </div>
</div>

<app-confirm-dialog></app-confirm-dialog>