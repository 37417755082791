import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../providers/api/api.service';
import { ConfirmDialogModule } from '../theme/components/confirm-dialog/confirm-dialog.module';
import { ConfirmDialogService } from '../theme/components/confirm-dialog/confirm-dialog.service';
import { TokenStorageService } from '../pages/login/token-storage.service';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    NgbModule,
    MultiselectDropdownModule,
    ConfirmDialogModule,
    FormsModule,
    NgxPaginationModule
  ],
  styleUrls: ['./bills.component.scss'],
  standalone: true

})
export class BillsComponent {

  billList: any = [];
  isShowLoading: boolean = false;
  statusList: any = [];
  authUser: any = this.tokenStorage.getUser();
  billsCount: number = 0;
  public p: number = 1;
  limit: number = 10;
  lastIndex: number = 1;

  constructor(
    private router: Router,
    private api: ApiService,
    private confirmDialogService: ConfirmDialogService,
    private tokenStorage: TokenStorageService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getEnumStatus();
    this.getBillCount();
  }

  getEnumStatus() {
    let param: any = { codeType: "bill_status_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      // console.log(result.data);
      if (result != null && result?.data?.length) {
        this.statusList = result.data;
      }
    });
  }

  getBillCount() {
    this.isShowLoading = true;
    let param: any = {};
    if (this.authUser?.roleCdValue != 'Admin') {
      param.appUserSno = this.authUser?.appUserSno;
    }
    this.api.get("8090/api/get_bills_count", param).subscribe((result) => {
      // console.log(result?.data)
      if (result != null && result.data?.length) {
        this.billsCount = result?.data[0]?.billsCount;
      }
      if (this.billsCount) {
        this.getBill(1);
      } else {
        this.isShowLoading = false;
      }
    });
  }

  getBill(event: number) {
    this.lastIndex = event;
    this.isShowLoading = true;
    let param: any = {};
    if (this.authUser?.roleCdValue != 'Admin') {
      param.appUserSno = this.authUser?.appUserSno;
    }
    param.skip = (event - 1) * 10;
    param.limit = this.limit;
    this.api.get("8090/api/get_bills", param).subscribe((result) => {
      this.isShowLoading = false;
      // console.log(result?.data)
      if (result != null && result.data?.length) {
        this.billList = result?.data;
      }
    });
  }

  goToAddNewBill() {
    this.router.navigateByUrl('/add-bills')
  }

  edit(i) {
    let navigationExtras: any = {
      state: {
        data: this.billList[i]
      }
    };
    this.router.navigate(['/edit-bills'], navigationExtras);
  }

  delete(i) {
    let confirmText = "Are you sure to Delete ? ";
    this.confirmDialogService.confirmThis(confirmText, () => {
      // console.log(confirmText);
      let body: any = {};
      body.billSno = this.billList[i]?.billSno;
      body.zohoBillId = this.billList[i]?.zohoBillId;
      // console.log(body);
      this.api.delete("8090/api/delete_bills", body).subscribe((result: any) => {
        // console.log(result)
        if (result != null && result?.data) {
          this.billList.splice(i, 1);
        }
      });
    }, () => {
    });
  }

  changeBillStatus(bill: any) {
    // console.log(bill);
    let body: any = {};
    body.billsSno = bill?.billSno;
    body.statusCd = bill?.statusCd;
    if (bill?.zohoBillId) {
      // body.zohoBillId = bill?.zohoBillId;
    }
    // console.log(body);
    this.api.put('8090/api/update_bill_status', body).subscribe((result: any) => {
      // console.log(result)
      if (result != null) {
        if (result.data != null) {
          this.getBill(this.lastIndex);
        }
      }
    }, err => {
      this.toastrService.error(err)
    });
  }
}