import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { ApiService } from '../providers/api/api.service';

@Component({
  selector: 'app-viewtask',
  templateUrl: './viewtask.component.html',
  styleUrls: ['./viewtask.component.scss']
})
export class ViewtaskComponent implements OnInit {

  taskStatusList: any = [];
  authUser: any = this.tokenStorage.getUser();
  isAdmin: boolean = false;
  userList: any = [];

  constructor(
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.userList = this.data?.project?.userList;
    this.getTaskStatusEnum();
  }

  public getTaskStatusEnum() {
    let param: any = { codeType: 'project_task_cd' };
    this.api.get('8082/api/get_enum_names', param).subscribe(result => {
      // console.log(result);
      if (result != null && result.data) {
        this.taskStatusList = result.data;
      } else {

      }
    });
  }
}
