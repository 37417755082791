<div class="row mb-1">
    <div class="col-12 d-flex text-end justify-content-end">
        <!-- <div class="w-25 ms-auto">
            <div class="input-group">
                <div class="input-group-prepend mx-auto" style="width:50px">
                    <span class="input-group-text  text-center" style="width:50px"><i
                            class="fa fa-search mx-auto"></i></span>
                </div>
                <input [(ngModel)]="searchText" type="text" style="height:42px" class="w-25"
                    placeholder="Search account, project, employee..." class="form-control border-1 ">
            </div>
        </div> -->

        <mat-form-field appearance="outline" class="ml-2">
            <mat-label>Select Region</mat-label>
            <mat-select [(ngModel)]="selectedCountrySno" (ngModelChange)="getClientInfo()" placeholder="Account">
                <mat-option *ngFor="let country of countryList;let i = index;" [value]="country.countrySno">
                    {{country?.countryShortCode}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="ml-2">
            <mat-label>Select Account</mat-label>
            <mat-select [formControl]="clientCtrl" (ngModelChange)="getProjectList()" placeholder="Account"
                [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="clientFilterCtrl" placeholderLabel="Find Account.."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All"
                        [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                        (toggleAll)="toggleClientsSelectAll($event)">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let client of filteredClients | async "
                    style="border-bottom:1px solid #ced4da !important;" [value]="client.clientSno">
                    {{ client.companyName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
            <mat-label>Select Project</mat-label>
            <mat-select [formControl]="projectCtrl" (ngModelChange)="getUserList()" placeholder="Project"
                [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="projectFilterCtrl" placeholderLabel="Find project..."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All"
                        [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                        (toggleAll)="toggleSelectAll($event)">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>
                <!-- <mat-option style="border-bottom:1px solid #ced4da !important;" value =0>
                    All
                </mat-option> -->
                <mat-optgroup *ngFor="let project of filteredProjects | async " [label]="project.companyName">
                    <mat-option style="border-bottom:1px solid #ced4da !important;" *ngFor="let pro of project.projects"
                        [value]="pro.projectSno">
                        {{ pro.projectName }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
            <mat-label>Select Employee</mat-label>
            <mat-select [formControl]="projectUserCtrl" placeholder="Employee" [multiple]="true" #singleSelect>
                <mat-option>
                    <ngx-mat-select-search [formControl]="projectUserFilterCtrl" placeholderLabel="Find Employee..."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All"
                        [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                        (toggleAll)="toggleProjectUserSelectAll($event)">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-optgroup *ngFor="let user of filteredProjectUsers | async " [label]="user?.projectName">
                    <mat-option *ngFor="let u of user?.users " style="border-bottom:1px solid #ced4da !important;"
                        [value]="u?.appUserSno">
                        {{ u?.name }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
        <!-- -->
        <input type="date" class="form-control text-center rounded ml-3" style="width:10%" id="input-14 "
            [(ngModel)]="fromDate">

        <input type="date" class="form-control text-center rounded ml-3 " style="width:10%" id="input-14"
            [(ngModel)]="toDate">
        <button mat-raised-button color="primary" class="p-4 ml-3 " (click)="search()">
            <i class="fa fa-search" aria-hidden="true"></i>
            &nbsp;Search
        </button>
        <div class="btn-group mb-4 border mx-2" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-primary " style="height: 2.8rem;" (click)="extractXl()"><i
                    class="fa fa-download "></i></button>
        </div>
    </div>
</div>

<div *ngIf="isNoRecord && !isShowLoading" class="text-center mt-5">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>
<div *ngIf="isShowLoading" class="text-center mt-5">
    <img src="assets/gif/loading.gif" class="position-absolute top-50 start-50" width="110px" />
</div>

<div class="row mt-3 p-2" *ngIf="!isNoRecord">
    <div class="col-xl-6 mb-4">
        <div widget class="card border-0 box-shadow">
            <div class="card-header transparent border-0 text-muted">
                <h5 class="mb-0">Vertical Bar Chart</h5>
            </div>
            <div class="card-body widget-body">
                <div class="w-100 h-300p">
                    <ngx-charts-bar-vertical [scheme]="colorScheme" [gradient]="gradient" [xAxis]="showXAxis"
                        [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                        [results]="data" (select)="onSelect($event)">
                    </ngx-charts-bar-vertical>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6 mb-4">
        <div widget class="card border-0 box-shadow">
            <div class="card-header transparent border-0 text-muted">
                <h5 class="mb-0">Pie Chart</h5>
            </div>
            <div class="card-body widget-body">
                <div class="w-100 h-300p">
                    <ngx-charts-pie-chart [scheme]="colorScheme" [results]="data" [legend]="showLegend"
                        [explodeSlices]="explodeSlices" [labels]="showLabels" [doughnut]="doughnut"
                        [gradient]="gradient" (select)="onSelect($event)">
                    </ngx-charts-pie-chart>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row p-2" *ngIf="!isNoRecord">
    <div class="col-12">
        <div class="table-responsive bg-white box-shadow">
            <table class="table">
                <thead>
                    <tr class="bg-primary text-white">
                        <th class="text-center">#</th>
                        <th><input type="checkbox" id="account" checked /> Account Name</th>
                        <th><input type="checkbox" id="project" checked /> Project Name</th>
                        <th><input type="checkbox" id="employee" checked /> Employee Name</th>
                        <th class="text-center">Worked Date</th>
                        <th class="text-center">Worked Time</th>
                    </tr>
                </thead>
                <tbody class="table-hover">
                    <tr *ngFor="let report of timesheetReportList;let i = index">
                        <td class="text-center">{{i+1}}</td>
                        <td>{{report.companyName}}</td>
                        <td>{{report.projectName}}</td>
                        <td>{{report.employeeName}}</td>
                        <td class="text-center">{{report.workedDate}}</td>
                        <td class="text-center">{{report.workedTime}}</td>
                    </tr>
                    <tr *ngIf="timesheetReportList?.length">
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td class="text-center fw-bold">
                            Total
                        </td>
                        <td class="text-center fw-bold">
                            &nbsp; {{total}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- <div *ngIf="isNoRecord" class="text-center mt-5">
    <img src="assets/img/norecord.png" />
</div> -->

<!-- <div class="row mb-5 " *ngIf="!isNoRecord">
    <div class="col-12">
        <div widget class="card border-0 box-shadow m-0 ">
            <div class="card-header transparent border-0 text-muted p-0 m-0 ">
                <div class="row bg-light p-2 m-0">
                    <div class="col-1">
                        #
                    </div>
                    <div class="col-4">
                        Project Name
                    </div>
                    <div class="col-3">
                        Employee Name
                    </div>
                    <div class="col-2">
                        Worked Date
                    </div>
                    <div class="col-2">
                        Worked Time
                    </div>
                </div>
            </div>

            <div class="card-body pt-0 widget-body">
                <div class="row p-2 mt-2 " *ngFor="let report of timesheetReportList;let i = index">
                    <div class="col-1">
                        {{i+1}}
                    </div>
                    <div class="col-4">
                        {{report.ProjectName}}
                    </div>
                    <div class="col-3">
                        {{report.EmployeeName}}
                    </div>
                    <div class="col-2">
                        {{report.WorkedDate}}
                    </div>
                    <div class="col-2">
                        {{report.WorkedTime}}
                    </div>
                </div>

                <div class="row bg-light p-2 m-0 mt-3" *ngIf="timesheetReportList?.length">
                    <div class="col-1">
                    </div>
                    <div class="col-4">
                    </div>
                    <div class="col-3">
                    </div>
                    <div class="col-2 font-weight-bold">
                        Total Worked Hours
                    </div>
                    <div class="col-2 font-weight-bold">
                        &nbsp; {{total}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="row mt-4 ">
    <div class="col-2">
        <h3 class="font-weight-bold">Time sheet Report</h3>
    </div>
    <div class="col-10 text-right">
        <div class="btn-group mb-4 mr-4 border">
            <select class="form-control w-auto" [(ngModel)]="selectedProject">
                <option value="0">All</option>
                <option *ngFor="let project of projectList;let i = index;" [value]="project?.projectSno">
                    {{project?.projectName}}</option>
            </select>
        </div>
        <div class="btn-group mb-4 mr-4 border " role="group" aria-label="Basic example">
            <select class="form-control " [(ngModel)]="selectedEmp">
                <option value="0">All</option>
                <option *ngFor="let user of projectUserList;let i = index;" [value]="user?.appUserSno">
                    {{user?.name}}</option>
            </select>
        </div>

        <div class="btn-group mb-4 mr-4 border" role="group" aria-label="Basic example">
            <input type="date" class="form-control text-center" id="input-14" [(ngModel)]="fromDate">
        </div>
        <div class="btn-group mb-4 mr-4 border" role="group" aria-label="Basic example">
            <input type="date" class="form-control text-center" id="input-14" [(ngModel)]="toDate">
        </div>
        <div class="btn-group mb-4 mr-4 border ml-1" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-light border-left" (click)="search()"><i
                    class="fa fa-search mr-2"></i></button>
        </div>
        <div class="btn-group mb-4  border ml-1" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-light border-left" (click)="extractXl()"> <i class="fa fa-download"
                    aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>


<div *ngIf="isNoRecord" class="text-center mt-5">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div class="row mb-5 " *ngIf="!isNoRecord">
    <div class="col-12">
        <div widget class="card border-0 box-shadow m-0 ">
            <div class="card-header transparent border-0 text-muted p-0 m-0 ">
                <div class="row bg-light p-2 m-0">
                    <div class="col-1">
                        #
                    </div>
                    <div class="col-4">
                        Project Name
                    </div>
                    <div class="col-3">
                        Employee Name
                    </div>
                    <div class="col-2">
                        Worked Date
                    </div>
                    <div class="col-2">
                        Worked Time
                    </div>
                </div>
            </div>

            <div class="card-body pt-0 widget-body">
                <div class="row p-2 mt-2 " *ngFor="let report of timesheetReportList;let i = index">
                    <div class="col-1">
                        {{i+1}}
                    </div>
                    <div class="col-4">
                        {{report.ProjectName}}
                    </div>
                    <div class="col-3">
                        {{report.EmployeeName}}
                    </div>
                    <div class="col-2">
                        {{report.WorkedDate}}
                    </div>
                    <div class="col-2">
                        {{report.WorkedTime}}
                    </div>
                </div>

                <div class="row bg-light p-2 m-0 mt-3" *ngIf="timesheetReportList?.length">
                    <div class="col-1">
                    </div>
                    <div class="col-4">
                    </div>
                    <div class="col-3">
                    </div>
                    <div class="col-2 font-weight-bold">
                        Total Worked Hours
                    </div>
                    <div class="col-2 font-weight-bold">
                        &nbsp; {{total}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->