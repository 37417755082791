import { Component } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ApiService } from '../providers/api/api.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PhotoService } from '../photoservice/photoservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import * as moment from 'moment';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-add-expense',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    NgbModule,
    MatSelectModule,
  ],
  templateUrl: './add-expense.component.html',
  styleUrls: ['./add-expense.component.scss']
})
export class AddExpenseComponent {

  expenseTypeList: any = [];
  expenseValue: any = 'Goods';
  vendorList: any = [];
  taxList: any = [];
  clientList: any = [];
  expenseForm: any = FormGroup;
  public file: any;
  expenseDate: any;
  expenseSno: any;
  paidList: any = [];
  stateList: any = [];
  gstList: any = [];
  accountList: any = [];
  countryList: any = [];
  authUser: any = this.tokenStorage.getUser();
  data: any;

  isLoading: boolean = false;

  constructor(
    private router: Router,
    private api: ApiService,
    public toastrService: ToastrService,
    private photoService: PhotoService,
    public domSanitizer: DomSanitizer,
    private location: Location,
    private tokenStorage: TokenStorageService
  ) {
    this.expenseForm = new FormGroup({
      expenseSno: new FormControl(null),
      date: new FormControl(moment(new Date()).format('YYYY-MM-DD'), Validators.required),
      accountSno: new FormControl(null, Validators.required),
      amount: new FormControl(null, Validators.required),
      paidThroughCd: new FormControl(null, Validators.required),
      invoice: new FormControl(null, Validators.required),
      notes: new FormControl(null, Validators.required),
      media: new FormControl(null, Validators.required),
      // currencyShortTypeCd: new FormControl(null, Validators.required),
      countrySno: new FormControl(null, Validators.required),
    });
  }

  // onDateSave(event: any, type?: any) {
  //   if (type == 'date') {
  //     this.expenseDate = 0;
  //     let dates = event;
  //     let year = dates.year;
  //     let month = dates.month <= 9 ? '0' + dates.month : dates.month;
  //     let day = dates.day <= 9 ? '0' + dates.day : dates.day;;
  //     let finalDate = year + "-" + month + "-" + day;
  //     this.expenseDate = finalDate;
  //     return finalDate;
  //   }

  // }

  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = JSON.stringify(this.authUser?.countries);
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result?.data) {
        this.countryList = result?.data;
        if (this.countryList?.length) {
          this.expenseForm.patchValue({
            countrySno: this.countryList[0]?.countrySno
          });
        }
        this.getEnumPaidThrough();
        this.getAccount();
      } else {
      }
    });
  }


  fileChange(input) {
    const reader = new FileReader();
    if (input.files.length) {
      this.file = input.files[0].name;
      // console.log(input.files[0])
    }
    this.changeFile(input, ['png', 'jpeg', 'jpg', 'webp', 'pdf', '.doc', '.docx', '.xml'], 'conSentForm');
  }

  changeFile(e: any, fileFormat: any, type: any) {
    this.photoService.onFileChange(e, fileFormat, type, (result: any) => {
      if (result != null && result != undefined) {
        // console.log(result);
        result[0].azureId = result[0].fileType;
        this.expenseForm.patchValue({
          media: result
        });
        // if(this.guardianForm.value.conSentForm?.mediaSno){
        //   this.mediaSno = this.guardianForm.value.conSentForm?.mediaSno;
        //   this.deleteMediaList.push({ mediaDetailSno: this.guardianForm.value.conSentForm?.mediaDetailSno });
        // }
        // this.guardianForm.patchValue({
        //   conSentForm: result[0]
        // })
        // // console.log( this.guardianForm.value.conSentForm);

        // result[0].isUploaded = null;
        // result[0].mediaSize = null;
      }
    });
  }

  removeFile(): void {
    this.file = '';
  }

  // onDateSelectDob(event) {
  //   let year = event.year;
  //   let month = event.month <= 9 ? '0' + event.month : event.month;
  //   let day = event.day <= 9 ? '0' + event.day : event.day;;
  //   // this.age = this.getAge(month + '/' + day + '/' + year);
  //   // // console.log(this.age)
  // }

  back() {
    this.router.navigateByUrl('/expenses')
  }

  onSubmit() {

  }

  ngOnInit() {
    let param: any = window.history.state;
    if (param?.data) {
      this.data = param?.data;
      // console.log(param?.data);
      let interval = setInterval(() => {
        if (this.paidList?.length && this.accountList?.length && this.countryList?.length) {
          clearInterval(interval);
          this.setFormValue(param?.data);
        }
      }, 1000);
    }
    this.getCountry();
    // this.getCurrencyType();
    // this.getExpense();
    // this.getState();
    // this.getEnumGstTreatment();
    // this.getVendor();
    // this.getTaxCdName();
    // this.getClients();
  }

  setFormValue(data: any) {
    let obj = {
      expenseSno: data?.expenseSno,
      date: data?.date,
      accountSno: data?.accountSno,
      // expenseTypeCd: data?.expenseTypeCd,
      // hsnCode: data?.hsnCode,
      // sacCode: data?.sacCode,
      amount: data?.amount,
      paidThroughCd: data?.paidThroughCd,
      // vendorSno: data?.vendorSno,
      // gstTreatmentCd: data?.gstTreatmentCd,
      // sourceOfSupplyCd: data?.sourceOfSupplyCd,
      // destinationOfSupplyCd: data?.destionationOfSupplyCd,
      // reverseCharge: data?.reverseCharge,
      // taxCd: data?.taxCd,
      invoice: data?.invoice,
      notes: data?.notes,
      // customerSno: data?.customerSno,
      media: data?.media,
      // currencyShortTypeCd: data?.currencyShortTypeCd,
      countrySno: data?.countrySno
    }
    this.expenseForm.setValue(obj);
  }

  zohoDetails() {
    let body: any = {};
    body = this.expenseForm.value;
    let sourceOfSupply: any = '', destinationOfSupply: any = '', expenseAccount: any = '',
      paidThroughAccount: any = '', vendorId: any = '', customerId: any = '';
    let sourceIndex: number, destinationIndex: number, expenseAccountIndex: number, paidThroughAccountIndex: number
      , vendorIndex: number, customerIndex: number;

    sourceIndex = this.stateList.findIndex((state) => state?.stateSno == body?.sourceOfSupplyCd);
    destinationIndex = this.stateList.findIndex((state) => state?.stateSno == body?.destinationOfSupplyCd);
    expenseAccountIndex = this.accountList.findIndex((account) => account?.accountSno == body?.accountSno);
    paidThroughAccountIndex = this.paidList.findIndex((paid) => paid?.codesDtlSno == body?.paidThroughCd);
    vendorIndex = this.vendorList.findIndex((vendor: any) => vendor?.vendorSno == body?.vendorSno);
    customerIndex = this.clientList.findIndex((client: any) => client?.clientSno == body?.customerSno);


    // console.log(sourceIndex);
    // console.log(destinationIndex);
    // console.log(expenseAccountIndex);
    // console.log(paidThroughAccountIndex);
    // console.log(vendorIndex);
    // console.log(customerIndex);

    if (sourceIndex != null && sourceIndex != -1) {
      // console.log(this.stateList[sourceIndex]);
      sourceOfSupply = this.stateList[sourceIndex]?.stateShortCode
    }
    if (destinationIndex != null && destinationIndex != -1) {
      // console.log(this.stateList[destinationIndex]);
      destinationOfSupply = this.stateList[destinationIndex]?.stateShortCode
    }
    if (expenseAccountIndex != null && expenseAccountIndex != -1) {
      // console.log(this.accountList[expenseAccountIndex]);
      expenseAccount = this.accountList[expenseAccountIndex]?.zohoAccountId;
    }
    if (paidThroughAccountIndex != null && paidThroughAccountIndex != -1) {
      // console.log(this.paidList[paidThroughAccountIndex]);
      paidThroughAccount = this.paidList[paidThroughAccountIndex]?.filter1;
    }
    if (vendorIndex != null && vendorIndex != -1) {
      // console.log(this.vendorList[vendorIndex]);
      vendorId = this.vendorList[vendorIndex]?.vendorId;
    }
    if (customerIndex != null && customerIndex != -1) {
      // console.log(this.clientList[customerIndex]);
      customerId = this.clientList[customerIndex]?.clientDetails?.contact_id;
    }

    return {
      "date": moment(body?.date ? body?.date : new Date()).format('YYYY-MM-DD'),
      "account_id": expenseAccount,
      // "account_id": "4195478000000000403",

      "product_type": 'goods',
      // "hsn_or_sac": body?.hsnCode,
      "amount": body?.amount,
      "paid_through_account_id": paidThroughAccount,
      // "paid_through_account_id": "4195478000000000361",
      // "vendor_id": vendorId,
      // "source_of_supply": sourceOfSupply,
      // "destination_of_supply": destinationOfSupply,
      "reference_number": body?.invoice,
      "description": body?.notes,
      "customer_id": customerId,
      "organizationCode": parseInt(this.countryList?.find((country) => country?.countrySno == body?.countrySno)?.organizationCode)
    };
  }

  saveExpense() {
    let body: any = {};
    body = this.expenseForm.value;
    body.appUserSno = this.authUser?.appUserSno;
    body.createdOn = this.api.dateToSavingStringFormatConvertion(new Date());
    if ((this.authUser.roleCdValue == 'Super Admin') || (this.authUser.roleCdValue == 'Global Admin') || (this.authUser.roleCdValue == 'Admin')) {
      body.zohoDetails = this.zohoDetails();
      body.isAdmin = true;
    }
    this.isLoading = true;
    console.log(body);
    this.api.post('8090/api/insert_expense', body).subscribe(result => {
      this.isLoading = false;
      console.log(result)
      if (result != null) {
        if (result.data != null) {
          this.expenseForm.patchValue({
            expenseSno: result.data.expenseSno
          })
          this.expenseSno = result.data.expenseSno
          this.toastrService.success('Saved Successfully');
          this.location.back();
        }
      }
    }, err => {
      this.toastrService.error(err)
    });
  }

  updateExpense() {
    let body: any = {}
    body = this.expenseForm.value;
    if ((this.authUser.roleCdValue == 'Super Admin') || (this.authUser.roleCdValue == 'Global Admin') || (this.authUser.roleCdValue == 'Admin')) {
      body.zohoDetails = this.zohoDetails();
      body.zohoExpenseId = this.data?.zohoExpenseId?.toString();
      body.isAdmin = true;
    }
    // console.log(body);
    this.isLoading = true;
    this.api.put('8090/api/update_expense', body).subscribe((result: any) => {
      // console.log(result)
      this.isLoading = false;
      if (result != null) {
        if (result.data != null) {
          this.expenseForm.patchValue({
            expenseSno: result.data.expenseSno
          })
          this.expenseSno = result.data.expenseSno
          this.toastrService.success('Updated Successfully');
          this.location.back();
        }
      }
    }, err => {
      this.toastrService.error(err)
    });
  }

  getEnumPaidThrough() {
    let param: any = {
      codeType: "paid_through_cd",
      filter2: this.expenseForm.value.countrySno
    };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      // console.log(result.data);
      if (result != null) {
        this.paidList = result.data;
        // this.expenseForm.patchValue({
        //   paidThroughCd:result.data[0].codesDtlSno,
        // })
      }
    });
  }

  getEnumGstTreatment() {
    let param: any = { codeType: "gst_treatment_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      if (result != null && result.data.length > 0) {
        this.gstList = result.data;
        // this.expenseForm.patchValue({
        //   paidThroughCd:result.data[0].codesDtlSno,
        // })
      }
    });
  }

  // getEnumExpenseType() {
  //   let param: any = { codeType: "expense_type_cd" };
  //   this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
  //     if (result != null && result.data.length > 0) {
  //       this.expenseTypeList = result.data;
  //       // this.expenseForm.patchValue({
  //       //   paidThroughCd:result.data[0].codesDtlSno,
  //       // })
  //     }
  //   });
  // }

  getTaxCdName() {
    let param: any = {
      codeType: "tax_cd"
    };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      if (result != null && result.data.length > 0) {
        this.taxList = result.data;
        // this.expenseForm.patchValue({
        //   paidThroughCd:result.data[0].codesDtlSno,
        // })
      }
    });
  }

  getVendor() {
    let param: any = {};
    this.api.get("8089/api/get_vendor", param).subscribe(result => {
      // console.log(result)
      if (result != null) {
        if (result.data != null && result.data.length > 0) {
          this.vendorList = result.data;
        } else {
        }
      } else {
        this.toastrService.error('Something went wrong');
      }
    }, err => {
      this.toastrService.error(err)
    }
    );
  }

  getState() {
    let param: any = {};
    this.api.get("8089/api/get_state", param).subscribe((result) => {
      // console.log(result);
      if (result != null && result?.data?.length > 0) {
        this.stateList = result.data;
        // this.expenseForm.patchValue({
        //   paidThroughCd:result.data[0].codesDtlSno,
        // })
      }
    });
  }

  getAccount() {
    let param: any = {};
    param.countrySno = this.expenseForm.value.countrySno;
    this.api.get("8089/api/get_account", param).subscribe((result) => {
      if (result != null) {
        this.accountList = result.data;
        // this.expenseForm.patchValue({
        //   paidThroughCd:result.data[0].codesDtlSno,
        // })
      }
    });
  }

  getClients() {
    let param = {}
    // this.isShowLoading = true;
    this.api.get('8084/api/get_client', param).subscribe(result => {
      // this.isShowLoading = false;
      // console.log(result?.data);
      if (result && result?.data?.length) {
        this.clientList = result.data;
      }
    }, error => {

    });
  }

  // getCurrencyType() {
  //   let param: any = {
  //     codeType: "currency_short_type_cd"
  //   };
  //   this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
  //     // console.log(result);
  //     if (result != null && result?.data?.length) {
  //       this.currencyList = result?.data;
  //       for (let currency of this.currencyList) {
  //         if (currency?.cdValue == 'INR') {
  //           this.expenseForm.patchValue({
  //             currencyShortTypeCd: currency?.codesDtlSno
  //           })
  //         }
  //       }
  //     }
  //   });
  // }

  getExpense() {
    let param: any = {
      codeType: "expense_type_cd"
    };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      // console.log(result);
      if (result != null && result?.data?.length) {
        this.expenseTypeList = result?.data;
        if (this.expenseTypeList?.length) {
          this.expenseForm.patchValue({
            expenseTypeCd: this.expenseTypeList[0]?.codesDtlSno
          });
        }
      }
    });
  }

  removeMedia() {
    $('#expense_media').val('').clone(true);
    this.expenseForm.patchValue({
      media: null
    });
  }
}


