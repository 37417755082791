// length.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'length'
})
export class LengthPipe implements PipeTransform {
  transform(value: any): number {
    if (Array.isArray(value)) {
      return value.length;
    }
    return 0;
  }
}