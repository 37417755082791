import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import * as $ from 'jquery';
import { ApiService } from 'src/app/providers/api/api.service';
import * as moment from 'moment';
import { TokenStorageService } from '../login/token-storage.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

// export interface Car {
//   id: string;
//   name: string;
// }

// /** list of cars */
// export const CARS: Car[] = [
//   { projectName: 'Mercedes-Benz', id: 'A' },
//   { projectName: 'Tesla', id: 'B' },
//   { projectName: 'BMW', id: 'C' },
//   { projectName: '	Volvo', id: 'D' },
// ];

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TimesheetComponent implements OnInit {

  public timeMeridian = { hour: 5, minute: 30, second: 20 };
  public spinners: boolean = false;
  time: any;
  public secondControlOptions: IMultiSelectOption[] = [
    { id: 1, name: 'Copy Last Week' },
    { id: 2, name: 'Copy Activities Only' },
    { id: 2, name: 'Copy Activities and Time' }
  ];

  public secondControlTexts: IMultiSelectTexts = {
    checked: 'item selected',
  };

  public secondControlSettings: IMultiSelectSettings = {
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-secondary btn-block',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true,
    showCheckAll: true,
    showUncheckAll: true
  };

  public secondControlModel: number[] = [1];
  public timesheetList: any = [];
  public totalList: any = [];
  @ViewChild('datepicker') datepicker: ElementRef | any;
  fromDate: any;
  toDate: any;
  displayDate: any;

  appUser: any;
  dateList: any = [];
  monthList: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  WeekList: any = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  selectWeek: any;
  currentWeek: any;
  currentYear: any;
  hours: any = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes: any = ['00', '15', '30', '45'];
  public projectList: any = [];

  /** control for the selected car */
  public projectCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public projectFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of cars filtered by search keyword */
  public filteredProjects: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect', { static: true })
  singleSelect!: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  /** flags to set the toggle all checkbox state */
  isIndeterminate = false;
  isChecked = false;

  countryList:any = [];
  allSelected:boolean = false;
  selectedCountry:any = [];
  @ViewChild('select') select: MatSelect;

  authUser: any = this.tokenStorageService.getUser();

  constructor(private api: ApiService, private tokenStorageService: TokenStorageService,
    public toastrService: ToastrService
  ) {

  }

  ngOnInit() {
    this.displayDate = moment().format('MMMM DD') + '-' + moment().add(6, 'd').format('MMMM DD')
    this.appUser = this.tokenStorageService.getUser();
    jQuery('.tt').tooltip({
      sanitize: false,
      sanitizeFn: function (content) {
        return null;
      }
    });
    var currentDate: any = moment();
    this.currentWeek = (currentDate.week() - 1);
    this.currentYear = currentDate.get('year');
    if (this.currentWeek < 10) {
      this.currentWeek = '0' + this.currentWeek;
    }
    this.selectWeek = this.currentYear + '-W' + this.currentWeek;
    this.fromDate = currentDate.clone().startOf('week').add(1, 'd');
    this.toDate = currentDate.clone().endOf('week').add(1, 'd');

    this.dateList = [];
    for (let i = 0; i <= 6; i++) {
      let date: any = moment(this.fromDate).add(i, 'days');
      this.dateList.push({ displayDate: date.format("ddd , MMM D "), fullDate: date.format('YYYY-MM-DD') });
    };
    this.displayDate = this.fromDate.format('MMM DD') + ' - ' + this.toDate.format('MMM DD');
    this.getTimeSheet();

    this.getCountry();
  }


  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = JSON.stringify(this.authUser?.countries);
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length) {
          $(document).ready(() => {
            this.allSelected = true;
            this.toggleAllSelection();
          });
        }
      } else {
      }
    });
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.getUserProjectList();
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.selectedCountry = this.countryList.map((obj)=> obj.countrySno);
    } else {
      this.selectedCountry = [];
    }
    this.getUserProjectList();
  }

  refresh() {
    this.monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    this.WeekList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    this.hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    this.minutes = ['00', '15', '30', '45'];
    this.selectWeek = null;
    this.currentWeek = null;
    this.currentYear = null;
    this.projectList = [];
    this.timesheetList = [];
    this.fromDate=null;
    this.toDate = null;
    this.displayDate = null;
    this.time = null;
    this.ngOnInit();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          this.projectCtrl.patchValue(val);
        } else {
          this.projectCtrl.patchValue([]);
        }
      });
  }

  chooseProject(projectSno: any, index: any) {
    for (let i in this.projectList) {
      if (this.projectList[i].projectSno == projectSno) {
        this.timesheetList[index].projectName = this.projectList[i].projectName;
        break;
      }
    }
    this.spliceSelectedProject();
  }

  initialTotalList() {
    this.totalList = [
      { 'd1': 'M', 'total': 0 },
      { 'd2': 'M', 'total': 0 },
      { 'd3': 'M', 'total': 0 },
      { 'd4': 'M', 'total': 0 },
      { 'd5': 'M', 'total': 0 },
      { 'd6': 'M', 'total': 0 },
      { 'd7': 'M', 'total': 0 },
      { 'd8': 'M', 'total': 0 }
    ];
  }


  getTimeSheet() {
    // this.getUserProjectList();
    this.initialTotalList();
    let param: any = { appUserSno: this.appUser.appUserSno, fromDate: this.fromDate.format('YYYY-MM-DD'), toDate: this.toDate.format('YYYY-MM-DD') }
    this.api.get('8086/api/get_timesheet', param).subscribe((result) => {
      if (result != null && result.data) {
        this.timesheetList = result.data;
        // console.log(this.timesheetList)
        this.overAllTotal();
        this.timeConvertion();

      } else {
        this.timesheetList = [];
        // if (this.projectList.length == 0)
        //   this.getUserProjectList();
        this.addNewRow();
      }
    });
  }

  selectBoxChange(timesheetSno, projectSno, j, i) {
    this.timesheetList[j].timesheet.workingTime[i].workedTime = this.timesheetList[j].timesheet.workingTime[i].selectedHours + ':' + this.timesheetList[j].timesheet.workingTime[i].selectedMinutes;
    this.keyInTime(timesheetSno, projectSno, this.timesheetList[j].timesheet.workingTime[i].workedTime, j, i);
  }

  overAllTotal() {
    for (let i in this.timesheetList) {
      for (let j in this.timesheetList[i].timesheet.workingTime) {
        if (this.timesheetList[i].timesheet.workingTime[j].workedTime)
          this.totalList[j].total = this.totalList[j].total + this.timesheetList[i].timesheet.workingTime[j].workedTime;
      }
    }

    let totalTime = 0;
    for (let i in this.totalList) {
      totalTime = totalTime + this.totalList[i].total;
      this.totalList[i].total = this.getHoursAndMinutes(this.totalList[i].total);
    }

    this.totalList[this.totalList.length - 1].total = this.getHoursAndMinutes(totalTime);
  }

  timeConvertion() {
    for (let i in this.timesheetList) {
      for (let j in this.timesheetList[i].timesheet.workingTime) {
        this.timesheetList[i].timesheet.workingTime[j].workedTime = this.getHoursAndMinutes(this.timesheetList[i].timesheet.workingTime[j].workedTime);
        let time = this.timesheetList[i].timesheet?.workingTime[j]?.workedTime?.split(':');
        this.timesheetList[i].timesheet.workingTime[j].selectedHours = time[0] || "00";
        this.timesheetList[i].timesheet.workingTime[j].selectedMinutes = time[1] || "00";
      }
      this.timesheetList[i].timesheet.total = this.getHoursAndMinutes(this.timesheetList[i].timesheet.total)
    }
  }

  getUserProjectList() {
    this.projectList = [];
    let params: any = { appUserSno: this.appUser.appUserSno, fromDate: this.fromDate.format('YYYY-MM-DD'), toDate: this.toDate.format('YYYY-MM-DD') };
   
    if(this.selectedCountry && this.selectedCountry.length > 0){
      params.countries = JSON.stringify(this.selectedCountry );
    }
    
    this.api.get('8085/api/get_user_project', params).subscribe(result => {
      if (result != null && result.data) {
        this.projectList = result.data;
        this.projectCtrl.setValue(this.projectList)

        this.filteredProjects.next(this.projectList.slice())

        this.projectFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterProjects();
          });
      }
    });
  }

  // protected setInitialValue() {
  //   this.filteredProjects
  //     .pipe(take(1), takeUntil(this._onDestroy))
  //     .subscribe(() => {
  //       this.singleSelect.compareWith = (a: any, b: any) =>
  //         a && b && a.projectSno < b.projectSno;
  //     });
  // }

  protected filterProjects() {
    if (!this.projectList) {
      return;
    }
    // get the search keyword
    let search = this.projectFilterCtrl.value;
    if (!search) {
      this.filteredProjects.next(this.projectList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the cars
    this.filteredProjects.next(
      this.projectList.filter((project) => project.projectName.toLowerCase().indexOf(search) > -1)
    );
  }

  ngAfterViewInit() {
    // this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  addNewRow() {
    this.timesheetList.push({ projectSno: 0, timesheet: { workingTime: [], total: '00:00' } });
    for (let i in this.dateList) {
      this.timesheetList[this.timesheetList.length - 1].timesheet.workingTime.push({ timeSheetSno: null, workedTime: '00:00', selectedHours: "00", selectedMinutes: "00", workedDate: this.dateList[i].fullDate });
    }
    this.spliceSelectedProject();
  }

  spliceSelectedProject() {
    if (this.projectList) {
      for (let i in this.timesheetList) {
        for (let j in this.projectList) {
          if (this.projectList[j].projectSno == this.timesheetList[i].projectSno) {
            this.projectList.splice(j, 1);
            break;
          }
        }
      }
      this.filteredProjects.next(this.projectList.slice());
    }
  }

  keyInTime(timesheetSno, projectSno, workedTime, j, i) {
    let prevTotalMinutes = 0;
    let workedDate = moment(this.timesheetList[j].timesheet.workingTime[i].workedDate).format('yyyy-MM-DD');
    // console.log(workedDate)
    for (let k in this.timesheetList) {
      for (let m in this.timesheetList[k].timesheet.workingTime) {
        if (workedDate == moment(this.timesheetList[k].timesheet.workingTime[m].workedDate).format('yyyy-MM-DD')) {
          prevTotalMinutes = prevTotalMinutes + moment(this.timesheetList[k].timesheet.workingTime[m].workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes');
        }
      }
    }

    let workedTimeMins = moment(workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes');
    if (prevTotalMinutes <= 1440) {
      if (!timesheetSno) {
        let timesheet = { projectSno: projectSno, appUserSno: this.appUser.appUserSno, workedDate: this.dateList[i].fullDate, workedTime: workedTimeMins };
        // console.log(timesheet)
        this.insertTimeSheet(timesheet, j, i);
        this.timesheetList[j].timesheet.workingTime[i].workedStatus = 'created';
      } else {
        let timesheet = { timesheetSno: timesheetSno, workedTime: workedTimeMins, projectSno: projectSno, appUserSno: this.appUser.appUserSno };
        this.updateTimeSheet(timesheet, j, i);
        this.timesheetList[j].timesheet.workingTime[i].workedStatus = 'edited';
      }
    } else {
      this.timesheetList[j].timesheet.workingTime[i].workedStatus = 'warning'
      if (timesheetSno) {
        let tempWorkedTime = this.getHoursAndMinutes(this.timesheetList[j].timesheet.workingTime[i].tempWorkedTime)?.split(':');
        if (tempWorkedTime) {
          // setTimeout(() => {
          //   this.timesheetList[j].timesheet.workingTime[i].selectedHours = null;
          //   this.timesheetList[j].timesheet.workingTime[i].selectedMinutes = null;
          //   this.timesheetList[j].timesheet.workingTime[i].selectedHours = tempWorkedTime[0];
          //   this.timesheetList[j].timesheet.workingTime[i].selectedMinutes = tempWorkedTime[1];
          //   this.timesheetList[j].timesheet.workingTime[i].workedTime = tempWorkedTime[0] + ':' + tempWorkedTime[1];
          // }, 500)
          // // console.log(this.timesheetList[j].timesheet.workingTime[i])
        }
      } else {
        // setTimeout(() => {
        //   this.timesheetList[j].timesheet.workingTime[i].selectedHours = null;
        //   this.timesheetList[j].timesheet.workingTime[i].selectedMinutes = null;
        //   this.timesheetList[j].timesheet.workingTime[i].selectedHours = "00";
        //   this.timesheetList[j].timesheet.workingTime[i].selectedMinutes = "00";
        //   this.timesheetList[j].timesheet.workingTime[i].workedTime = "00:00";
        // }, 500)

      }

      // this.toastrService.warning('Max limit reached.per day working limit is 9 hours only', 'Warning')
    }


  }

  insertTimeSheet(timesheet, j, i) {
    this.timesheetList[j].timesheet.workingTime[i].isReadOnly = true;
    this.api.post('8086/api/insert_timesheet', timesheet).subscribe(result => {
      // console.log(result)
      this.timesheetList[j].timesheet.workingTime[i].isReadOnly = false;
      if (result != null && result.data) {
        this.timesheetList[j].timesheet.workingTime[i].workedStatus = result.data.timesheetStatusCdValue;
        this.timesheetList[j].timesheet.workingTime[i].timesheetSno = result.data.timesheetSno;
        this.timesheetList[j].isAdmin = result.data.isAdmin;
        for (let i in this.projectList) {
          if (this.projectList[i].projectSno == timesheet.projectSno) {
            this.timesheetList[j].projectName = this.projectList[i].projectName;
            break;
          }
        }
        this.timesheetList[j].timesheet.workingTime[i].tempWorkedTime = moment(this.timesheetList[j].timesheet.workingTime[i].workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes')
        this.calculateTotal(j);
        this.getUserProjectList();
      }
    });
  }

  updateTimeSheet(timesheet, j, i) {
    this.timesheetList[j].timesheet.workingTime[i].isReadOnly = true;
    timesheet.timesheetStatus = 'edited';
    this.api.put('8086/api/update_timesheet', timesheet).subscribe((result: any) => {
      // console.log(result)
      this.timesheetList[j].timesheet.workingTime[i].isReadOnly = false;
      if (result != null && result.data) {
        this.timesheetList[j].timesheet.workingTime[i].timesheetSno = result.data.timesheetSno;
        this.timesheetList[j].timesheet.workingTime[i].workedStatus = result.data.timesheetStatusCdValue;
        this.timesheetList[j].isAdmin = result.data.isAdmin;
        this.timesheetList[j].timesheet.workingTime[i].tempWorkedTime = moment(this.timesheetList[j].timesheet.workingTime[i].workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes')
        // console.log(this.timesheetList[j].timesheet.workingTime[i])
        this.calculateTotal(j);
      }
    });
  }

  calculateTotal(j) {
    let totalTimeMins = 0;
    for (let i in this.timesheetList[j].timesheet.workingTime) {
      let workedTimeMins = moment(this.timesheetList[j].timesheet.workingTime[i].workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes');
      // this.timesheetList[j].timesheet.workingTime[i].workedTime = workedTimeMins;
      totalTimeMins = totalTimeMins + workedTimeMins;
    }
    this.timesheetList[j].timesheet.total = this.getHoursAndMinutes(totalTimeMins);
    //
    // this.timesheetList[j].timesheet.total = this.getHoursAndMinutes(totalTimeMins);
    this.convertStringTimeToMins();
    this.initialTotalList();
    this.overAllTotal();
    this.timeConvertion();
    this.timesheetList[j].timesheet.total = this.getHoursAndMinutes(totalTimeMins);
    // this.timesheetList[j].timesheet.total = ;
  }

  convertStringTimeToMins() {

    for (let i in this.timesheetList) {
      for (let j in this.timesheetList[i].timesheet.workingTime) {
        this.timesheetList[i].timesheet.workingTime[j].workedTime = moment(this.timesheetList[i].timesheet.workingTime[j].workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes');
      }
      this.timesheetList[i].timesheet.total = moment(this.timesheetList[i].timesheet.total, 'HH:mm').diff(moment().startOf('day'), 'minutes');
    }
  }


  getHoursAndMinutes(totalTimeInMin: any) {
    // // console.log(totalTimeInMin)
    let hours = Math.floor(totalTimeInMin / 60);
    let minutes = totalTimeInMin % 60;
    let display = (hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()) + ':' + (minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString());
    // // console.log(display)
    return display;
  }

  nextWeek() {
    let nextWeek = this.selectWeek.split('W');
    let incrementWeek: any = parseInt(nextWeek[1]) + 1;
    if (incrementWeek > 52) {
      let year = nextWeek[0].split('-');
      this.selectWeek = (parseInt(year) + 1) + '-W01';
    } else {
      if (incrementWeek < 10) {
        incrementWeek = '0' + incrementWeek;
      }
      this.selectWeek = nextWeek[0] + 'W' + incrementWeek;
    }
    this.changeWeek();
  }

  previousWeek() {
    let previousWeek = this.selectWeek.split('W');
    let decrementWeek: any = parseInt(previousWeek[1]) - 1;
    if (decrementWeek == 0) {
      let year = previousWeek[0].split('-');
      this.selectWeek = (parseInt(year) - 1) + '-W52';
    } else {
      if (decrementWeek < 10) {
        decrementWeek = '0' + decrementWeek;
      }
      this.selectWeek = previousWeek[0] + 'W' + decrementWeek;
    }

    this.changeWeek();
  }

  changeWeek() {
    let selectedWeek = this.selectWeek.split('-');
    let selectWeekYear = selectedWeek[0];
    let selectweek = selectedWeek[1].split('W')[1];
    // console.log(selectweek)
    let selectedWeekStartDate = this.getDateOfISOWeek(parseInt(selectweek), parseInt(selectWeekYear));
    this.fromDate = moment(selectedWeekStartDate);
    this.toDate = this.fromDate.clone().endOf('week').add(1, 'd');

    this.dateList = [];
    for (let i = 0; i <= 6; i++) {
      let date: any = moment(this.fromDate).add(i, 'days');
      this.dateList.push({ displayDate: date.format("ddd , MMM D "), fullDate: date.format('YYYY-MM-DD') });
    };
    this.displayDate = this.fromDate.format('MMM DD') + ' - ' + this.toDate.format('MMM DD');
    this.getTimeSheet();
  }


  // getDateOfWeek(w, y) {
  //   var d: any = (1 + (parseInt(w) - 1) * 7); // 1st of January + 7 days for each week
  //   // d = d + 2;
  //   if (d < 10) {
  //     d = '0' + d;
  //   }
  //   // console.log(d);
  //   return new Date(y, 0, d);
  // }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;

    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    return ISOweekStart;
  }
}