<div mat-dialog-title>
    <div class="row">
        <div class="col-10 text-start">
            <h2 class="m-auto">New Task</h2>
        </div>
        <div class="col-2 text-end">
            <button class="btn" mat-dialog-close>
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
</div>
<form [formGroup]="form" mat-dialog-content>
    <div class="mt-3">
        <div class="form-group" *ngIf="data?.pageName == 'task'">
            <label for="exampleInputEmail1">Project *</label>
            <select class="form-control" formControlName="projectSno" (change)="getProjectUser()">
                <option *ngFor="let project of projectList;" [value]="project?.projectSno">
                    {{project?.projectName}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Tasks *</label>
            <input class="form-control" type="text" formControlName="taskName" placeholder="Enter task name">
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Description</label>
            <textarea class="form-control" type="text" formControlName="description" rows="5"></textarea>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Assigned To</label>
            <select formControlName="assignedAppUserSno" class="form-control">
                <option *ngFor="let user of userList;let i =index;" [value]="user?.appUserSno">
                    {{user?.name}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Status</label>
            <select formControlName="taskStatusCd" class="form-control">
                <option *ngFor="let taskStatus of taskStatusList; let i = index;" [value]="taskStatus?.codesDtlSno">
                    {{taskStatus?.cdValue}}
                </option>
            </select>
        </div>
        <div class="row">
            <div class="form-group col-6 col-sm-12 col-md-6">
                <label for="exampleInputEmail1">Start Date</label>
                <input class="form-control" type="date" formControlName="startDate">
            </div>
            <div class="form-group col-6 col-sm-12 col-md-6">
                <label for="exampleInputEmail1">Due Date</label>
                <input class="form-control" type="date" formControlName="dueDate">
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-4" *ngIf="!form.value?.projectTaskSno" [disabled]="form.invalid"
                (click)="addTask()">
                Add Task
            </button>
            <button class="btn btn-primary mt-4" *ngIf="form.value?.projectTaskSno" [disabled]="form.invalid"
                (click)="updateTask()">
                Update Task
            </button>
        </div>
    </div>
</form>