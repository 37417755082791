import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogModule } from '../theme/components/confirm-dialog/confirm-dialog.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../providers/api/api.service';
import { ConfirmDialogService } from '../theme/components/confirm-dialog/confirm-dialog.service';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
  selector: 'app-expense-approval',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    ConfirmDialogModule,
    NgxPaginationModule,
    FormsModule,
    InfiniteScrollModule
  ],
  templateUrl: './expense-approval.component.html',
  styleUrls: ['./expense-approval.component.scss']
})
export class ExpenseApprovalComponent {

  tableList: any = []
  isShowLoading: boolean = false;
  isShow: boolean = false;
  selectedExpanse: any;
  authUser: any = this.tokenStorage.getUser();
  statusList: any = [];
  expensesCount: number = 0;
  public p: number = 1;
  limit: number = 20;
  isAdmin: boolean = false;
  isLoad: boolean = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private confirmDialogService: ConfirmDialogService,
    private tokenStorage: TokenStorageService,
    public toastrService: ToastrService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.params.subscribe(() => {
      let param: any = window.history.state;
      this.isAdmin = param.isAdmin;
    });
    this.getEnumStatus();
    this.getExpensesCount();
  }

  getExpensesCount() {
    this.isShowLoading = true;
    let param: any = {};
    // if ((this.authUser?.roleCdValue != 'Admin') && !this.isAdmin) {
    //   param.appUserSno = this.authUser?.appUserSno;
    // }
    // console.log(param)
    this.api.get("8090/api/get_expense_count", param).subscribe((result) => {
      // console.log(result)
      if (result != null && result.data?.length) {
        this.expensesCount = result?.data[0]?.expenseCount;
      }
      if (this.expensesCount) {
        this.getExpenses();
      } else {
        this.isShowLoading = false;
      }
    });
  }

  getExpenses(expense?: any) {
    if (!this.isLoad) {
      this.isLoad = true;
      let param: any = {};
      // if ((this.authUser?.roleCdValue != 'Admin') && !this.isAdmin) {
      //   param.appUserSno = this.authUser?.appUserSno;
      // }
      param.limit = this.limit;
      if (expense) {
        param.expenseSno = expense?.expenseSno;
      } else {
        param.skip = this.tableList?.length | 0;
      }
      this.isShowLoading = true;
      this.api.get("8090/api/get_expense", param).subscribe((result) => {
        console.log(result);
        this.isShowLoading = false;
        this.isLoad = false;
        if (result != null && result.data?.length) {
          if (expense) {
            console.log(this.tableList);
            let index: number = this.tableList.findIndex((table) => table?.expenseSno == expense?.expenseSno);
            console.log(index);
            if (index != -1 && result?.data?.length) {
              this.tableList[index] = result?.data[0];
              this.selectedExpanse = result?.data[0];
            }
          } else {
            for (let i in result?.data) {
              this.tableList.push(result?.data[i]);
            }
          }
          // this.tableList = result?.data;
        }
      });
    }
  }

  goToAddExpense() {
    this.router.navigateByUrl('/add-expense')
  }

  edit(i) {
    let navigationExtras: any = {
      state: {
        data: this.tableList[i]
      }
    };
    this.router.navigate(['/edit-expense'], navigationExtras);
  }

  delete(i) {
    let confirmText = "Are you sure to Delete ? ";
    this.confirmDialogService.confirmThis(confirmText, () => {
      // console.log(confirmText);
      let body: any = {};
      body.expenseSno = this.tableList[i].expenseSno;
      body.zohoExpenseId = this.tableList[i]?.zohoExpenseId?.toString();
      // console.log(body);
      this.api.delete("8090/api/delete_expense", body).subscribe((result: any) => {
        // console.log(result)
        if (result != null && result?.data) {
          this.tableList.splice(i, 1);
        }
      });
    }, () => {
    });
  }

  viewExpanse(expense) {
    this.isShow = true;
    this.selectedExpanse = expense;
    // console.log(this.selectedExpanse);
  }

  editExpenses() {
    let index = this.tableList.findIndex((expense) => expense?.expenseSno == this.selectedExpanse?.expenseSno);
    this.edit(index);
  }

  getEnumStatus() {
    let param: any = { codeType: "status_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      // console.log(result.data);
      if (result != null && result?.data?.length > 0) {
        this.statusList = result.data;
      }
    });
  }

  changeExpensesStatus(expense: any) {
    console.log(expense);
    let body: any = {};
    body.expenseSno = expense?.expenseSno;
    expense.zohoDetails.date = moment(new Date()).format('YYYY-MM-DD');
    expense.zohoDetails.product_type = expense?.zohoDetails?.product_type?.toLowerCase();
    body.zohoDetails = expense?.zohoDetails;
    body.statusCd = expense?.statusCd;
    body.attachment = expense?.media;
    if (expense?.zohoExpenseId) {
      body.zohoExpenseId = expense?.zohoExpenseId;
    }
    // console.log(body);
    this.api.put('8090/api/update_expense_status', body).subscribe((result: any) => {
      console.log(result)
      if (result != null) {
        if (result.data != null) {
          // this.tableList = [];
          this.getExpenses(expense);
        }
      }
    }, err => {
      this.toastrService.error(err)
    });
  }

  // downloadDocument() {
  //   var a = document.createElement("a");
  //   a.href = this.selectedExpanse?.media[0]?.mediaUrl;
  //   a.click();
  // }

  viewDocument() {
    window.open(this.selectedExpanse?.media[0]?.mediaUrl, '_blank');
  }
}