import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { ApiService } from '../providers/api/api.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { ToastrService } from 'ngx-toastr';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';

@Component({
  selector: 'app-project-plan',
  standalone: true,
  imports: [
    CommonModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    CdkTableModule
  ],
  templateUrl: './project-plan.component.html',
  styleUrls: ['./project-plan.component.scss'],
  providers: []
})
export class ProjectPlanComponent {

  itemList: any = [];
  projectList: any = [];
  authUser: any = this.tokenStorage.getUser();

  // Autocomplete User
  public projectCtrl: FormControl = new FormControl();
  public projectFilterCtrl: FormControl = new FormControl();
  public filteredProject: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  selectedProject: any;
  public form: FormGroup;
  userList: any = [];
  priorityStatusList: any = [];
  taskStatusList: any = [];
  milestoneStatusList: any = [];
  deletedPlanList: any = [];
  deletedTaskList: any = [];

  constructor(
    private tokenStorage: TokenStorageService,
    public api: ApiService,
    public fb: FormBuilder,
    public toastrService: ToastrService,
    private datePipe: DatePipe
  ) {
    this.form = this.fb.group({
      projectPlan: this.fb.array([])
    });
  }

  ngOnInit() {
    this.getMyProject();
    this.getEnumPriority();
    this.getTaskStatusEnum();
    this.getEnumMilestone();
  }

  insertProjectPlan() {
    this.projectPlan().push(this.fb.group({
      projectPlanSno: [null],
      taskName: [null, Validators.compose([Validators.required])],
      startDate: [null, Validators.compose([Validators.required])],
      endDate: [null, Validators.compose([Validators.required])],
      taskList: this.fb.array([]),
      milestoneStatusCd: [null],
    }));
  }

  projectPlan(): FormArray {
    return this.form.controls["projectPlan"] as FormArray;
  }

  projectTask(i: number) {
    return this.projectPlan().at(i).get('taskList') as FormArray;
  }

  getMyProject() {
    this.projectList = [];
    let params: any = { appUserSno: this.authUser.appUserSno };
    this.api.get('8085/api/get_my_project', params).subscribe(result => {
      console.log(result);
      if (result != null && result.data) {
        this.projectList = result.data;
        this.filteredProject.next(this.projectList?.slice());
        this.projectFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterProject();
          });
      }
    })
  }

  getProjectUser() {
    if (this.projectCtrl.value) {
      let param: any = { projectSno: this.projectCtrl.value };
      this.api.get('8085/api/get_project_user', param).subscribe(result => {
        if (result != null && result?.data) {
          this.userList = [];
          for (let i in result.data) {
            this.userList.push({
              appUserSno: result.data[i].appUserSno,
              name: result.data[i].name
            });
          }
        } else {

        }
      });
    } else {
      this.userList = [];
    }
  }

  filterProject() {
    if (!this.projectList?.length) {
      return;
    }
    let search = this.projectFilterCtrl.value;
    if (!search) {
      this.filteredProject.next(this.projectList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredProject.next(
      this.projectList.filter(project => project?.projectName?.toLowerCase().indexOf(search) > -1)
    );
  }

  createPlan() {
    this.itemList.length++;
  }

  removePlan(i: number) {
    if (this.projectPlan().at(i).value.projectPlanSno) {
      this.deletedPlanList.push(this.projectPlan().at(i).value.projectPlanSno);
    }
    for (let j in this.projectTask(i).value) {
      if (this.projectTask(i).at(parseInt(j)).value.projectTaskSno) {
        this.deletedTaskList.push(this.projectTask(i).at(parseInt(j)).value.projectTaskSno);
      }
    }
    this.projectPlan().removeAt(i);
  }

  getSelectedProject() {
    this.form = this.fb.group({
      projectPlan: this.fb.array([])
    });
    console.log(this.projectCtrl.value);
    let param: any = {};
    if (this.projectCtrl.value) {
      param.projectSno = this.projectCtrl.value;
    }
    this.api.get('8085/api/get_project', param).subscribe(result => {
      console.log(result);
      if (result != null && result?.data) {
        // this.projects = result.data;
        this.selectedProject = result.data[0];
        this.calculatePercentage(this.selectedProject);
      } else {
        this.selectedProject = null;
      }
      this.getProjectUser();
    });
  }
  

  getSelectedProjectPlan() {
    let param: any = {};
    param.projectSno = this.projectCtrl.value;
    this.api.get('8085/api/get_project_plan', param).subscribe(result => {
      console.log(result);
      if (result != null && result?.data) {
        for (let i in result?.data) {
          this.insertProjectPlan();
          for (let j in result?.data[i]?.taskList) {
            this.insertTask(parseInt(i));
          }
        }
        this.form.setValue({
          projectPlan: result.data
        });
      } else {
        this.insertProjectPlan();
      }
    });
  }

  calculatePercentage(row) {
    if (!row?.totalPercentage) {
      row.totalPercentage = 0;
    }
    row.totalPercentage = ((row?.completedTask / (row?.completedTask + row?.pendingTask)) * 100)?.toFixed(0);
    if (isNaN(row.totalPercentage)) row.totalPercentage = 0;
  }

  calculateDiff() {
    if (!this.selectedProject?.duration) {
      this.selectedProject.duration = 0;
    }
    let startDate = new Date(this.selectedProject?.startDate);
    let endDate = new Date(this.selectedProject?.endDate);
    this.selectedProject.duration = Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24));
    return this.selectedProject.duration;
  }

  insertTask(i: number) {
    this.projectTask(i).push(this.fb.group({
      projectTaskSno: [null],
      taskName: [null, Validators.compose([Validators.required])],
      description: null,
      startDate: [null],
      dueDate: [null],
      assignedAppUserSno: [null],
      taskStatusCd: [null],
      priorityStatusCd: [null],
      checkListItems: [null],
      milestoneStatusCd: [null],
    }));
  }

  getEnumPriority() {
    let param: any = { codeType: "priority_status_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      console.log(result);
      if (result != null && result.data?.length > 0) {
        this.priorityStatusList = result.data;
      }
    });
  }

  public getTaskStatusEnum() {
    let param: any = { codeType: 'project_task_cd' };
    this.api.get('8082/api/get_enum_names', param).subscribe(result => {
      console.log(result);
      if (result != null && result.data) {
        this.taskStatusList = result.data;
      } else {

      }
    });
  }

  getEnumMilestone() {
    let param: any = { codeType: "milestone_status_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      console.log(result);
      if (result != null && result.data?.length > 0) {
        this.milestoneStatusList = result.data;
      }
    });
  }

  removeTask(i: number, j: number) {
    if (this.projectTask(i).at(j).value.projectTaskSno) {
      this.deletedTaskList.push(this.projectTask(i).at(j).value.projectTaskSno);
    }
    this.projectTask(i).removeAt(j);
  }

  updateTask() {
    let body: any = {};
    body = this.form.value;
    body.deletedPlanList = this.deletedPlanList;
    body.deletedTaskList = this.deletedTaskList;
    body.projectSno = this.selectedProject?.projectSno;
    console.log(body);
    this.api.put('8085/api/update_project_plan', body).subscribe((result: any) => {
      if (result != null && result.data) {
        this.toastrService.success('Project Plan Updated Successfully');
        // this.form.reset();
      } else {

      }
    });
  }

  exportExcel() {
    let xlList: any = [];
    let projectPlan: any = this.projectPlan().value;
    for (let i in projectPlan) {
      let index = this.milestoneStatusList.findIndex((milestone) => milestone?.codesDtlSno == projectPlan[i]?.milestoneStatusCd);
      xlList.push({
        "S.No": (parseInt(i) + 1).toString(),
        "Task Name": projectPlan[i]?.taskName,
        "Responsibility": "",
        "Start": projectPlan[i]?.startDate,
        "Finish": projectPlan[i]?.endDate,
        "% complete": "0%",
        "Milestone": this.milestoneStatusList[index]?.cdValue,
        "Priority": "",
        "Status": "",
        "Comments": "",
        "Checklist Items": "",
        "isPlan": true
      })
      let pendingTask: number = 0;
      let completedTask: number = 0;
      for (let j in projectPlan[i]?.taskList) {
        let milestoneIndex = this.milestoneStatusList.findIndex((milestone) => milestone?.codesDtlSno == projectPlan[i]?.taskList[j]?.milestoneStatusCd);
        let priorityIndex = this.priorityStatusList.findIndex((priority) => priority?.codesDtlSno == projectPlan[i]?.taskList[j]?.priorityStatusCd);
        let taskIndex = this.taskStatusList.findIndex((task) => task?.codesDtlSno == projectPlan[i]?.taskList[j]?.taskStatusCd);
        let userIndex = this.userList.findIndex((user) => user?.appUserSno == projectPlan[i]?.taskList[j]?.assignedAppUserSno);
        console.log(this.taskStatusList[taskIndex]?.cdValue)
        if (this.taskStatusList[taskIndex]?.cdValue != 'Closed') {
          pendingTask = pendingTask + 1;
        }

        if (this.taskStatusList[taskIndex]?.cdValue == 'Closed') {
          completedTask = completedTask + 1;
        }
        xlList.push({
          "S.No": (parseInt(i) + 1) + '.' + (parseInt(j) + 1),
          "Task Name": projectPlan[i]?.taskList[j]?.taskName,
          "Responsibility": this.userList[userIndex]?.name,
          "Start": projectPlan[i]?.taskList[j]?.startDate,
          "Finish": projectPlan[i]?.taskList[j]?.dueDate,
          "% complete": "",
          "Milestone": this.milestoneStatusList[milestoneIndex]?.cdValue,
          "Priority": this.priorityStatusList[priorityIndex]?.cdValue,
          "Status": this.taskStatusList[taskIndex]?.cdValue,
          "Comments": projectPlan[i]?.taskList[j]?.description,
          "Checklist Items": projectPlan[i]?.taskList[j]?.checkListItems,
          "isPlan": false
        });
      }
      let newIndex: number = 0;
      if (parseInt(i) != 0) {
        newIndex = projectPlan[i]?.taskList?.length || 0
        if (newIndex > 0) newIndex = newIndex + parseInt(i) + 1;
        if (!xlList[newIndex]?.totalPercentage) {
          xlList[newIndex].totalPercentage = 0;
        }
      }
      xlList[newIndex].totalPercentage = ((completedTask / (completedTask + pendingTask)) * 100)?.toFixed(0);
      if (!xlList[newIndex]?.totalPercentage || isNaN(xlList[newIndex].totalPercentage)) xlList[newIndex].totalPercentage = 0;

      xlList[newIndex]['% complete'] = (xlList[i]?.totalPercentage || 0) + '%';
      delete xlList[newIndex].totalPercentage;
    }

    let complete: number = 0;
    for (let i in xlList) {
      if (xlList[i]['% complete']?.replace('%', '')) {
        complete = complete + parseInt(xlList[i]['% complete'].replace('%', ''));
      }
    }

    let row = [
      [
        { v: "", t: "s" }
      ],
      [
        {
          v: "Project name", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: this.selectedProject?.projectName, t: "s", s: {
            fill: {
              fgColor: {
                rgb: 'c3cfd8'
              }
            },
            font: {
              color: {
                rgb: '000000'
              }
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
      ],
      [
        {
          v: "Project manager", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: this.selectedProject?.projectAdmin?.length ? this.selectedProject?.projectAdmin[0]?.name : '', t: "s", s: {
            fill: {
              fgColor: {
                rgb: 'c3cfd8'
              }
            },
            font: {
              color: {
                rgb: '000000'
              }
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
      ],
      [
        {
          v: "Project start date", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: this.selectedProject?.startDate ? this.datePipe.transform(this.selectedProject?.startDate, 'dd-MMM-yyyy') : '', t: "s", s: {
            fill: {
              fgColor: {
                rgb: 'c3cfd8'
              }
            },
            font: {
              color: {
                rgb: '000000'
              }
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
      ],
      [
        {
          v: "Project finish date", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: this.selectedProject?.endDate ? this.datePipe.transform(this.selectedProject?.endDate, 'dd-MMM-yyyy') : '', t: "s", s: {
            fill: {
              fgColor: {
                rgb: 'c3cfd8'
              }
            },
            font: {
              color: {
                rgb: '000000'
              }
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
      ],
      [
        {
          v: "Duration", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: this.selectedProject?.duration + (this.selectedProject?.duration > 1 ? 'days' : 'day'), t: "s", s: {
            fill: {
              fgColor: {
                rgb: 'c3cfd8'
              }
            },
            font: {
              color: {
                rgb: '000000'
              }
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
      ],
      [
        {
          v: "% complete", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: ((complete / projectPlan.length) | 0) + "%", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '5ecc62'
              }
            },
            font: {
              color: {
                rgb: '000000'
              }
            },
            border: {
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
      ],
      [
        { v: "", t: "s" }
      ],
      [
        {
          v: "S.No", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Task Name", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Responsibility", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Start", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Finish", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "% complete", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Milestone", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Priority", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Status", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Comments", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              right: {
                color: 'f7f7f7',
                style: "thin"
              },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
        {
          v: "Checklist Items", t: "s", s: {
            fill: {
              fgColor: {
                rgb: '003580'
              }
            },
            font: {
              bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            },
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: {
              // bottom: {
              //   color: 'f7f7f7',
              //   style: "thin"
              // },
              bottom: {
                color: 'f7f7f7',
                style: "thin"
              }
            }
          }
        },
      ],
    ];

    console.log(xlList);


    for (let i in xlList) {
      let newRow: any = [
        {
          v: xlList[i]['S.No'], t: "s", s: {
            alignment: {
              horizontal: "center",
              vertical: "center"
            }
          }
        },
        {
          v: xlList[i]['Task Name'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Responsibility'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Start'] ? xlList[i]['Start'] : '', t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Finish'] ? xlList[i]['Finish'] : '', t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['% complete'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: 'e5ff00'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: xlList[i]['isPlan'] ? {
                rgb: '000000'
              } : {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Milestone'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Priority'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Status'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Comments'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
            border: xlList[i]['isPlan'] ? {
              right: {
                color: 'f7f7f7',
                style: "thin"
              }
            } : {}
          }
        },
        {
          v: xlList[i]['Checklist Items'], t: "s", s: {
            fill: xlList[i]['isPlan'] ? {
              fgColor: {
                rgb: '003580'
              }
            } : '',
            font: xlList[i]['isPlan'] ? {
              // bold: true,
              color: {
                rgb: 'f7f7f7'
              }
            } : '',
            alignment: {
              horizontal: "center",
              vertical: "center"
            },
          }
        },
      ];
      row.push(newRow);
    }

    row.push(
      [
        { v: "", t: "s" }
      ]
    );

    const wb = XLSX.utils.book_new();
    let sheet1: any = XLSX.utils.aoa_to_sheet(row);
    // let sheet2: any = XLSX.utils.json_to_sheet(xlList);
    XLSX.utils.book_append_sheet(wb, sheet1, "worksheet 1")
    // XLSX.utils.book_append_sheet(wb, sheet2, "worksheet 2")
    XLSX.writeFile(wb, 'filename.xlsx');
  }
}