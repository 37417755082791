<div mat-dialog-title>
    <div class="row">
        <div class="col-10 text-start">
            <h2 class="m-auto">{{form.value?.dealTaskSno ? 'Update' : 'New'}} Task</h2>
        </div>
        <div class="col-2 text-end">
            <button class="btn" mat-dialog-close>
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
</div>
<form [formGroup]="form" mat-dialog-content>
    <div class="mt-3">
        <div class="form-group">
            <label for="exampleInputEmail1">Country <span class="text-danger">*</span></label>
            <select [(ngModel)]="selectCountrySno" [ngModelOptions]="{standalone: true}" class="form-control"
                (ngModelChange)="changeCountry();">
                <option *ngFor="let country of countryList;let i = index;" [value]="country.countrySno">
                    {{country?.countryShortCode}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Deal Id <span class="text-danger">*</span></label>
            <mat-select formControlName="dealSno" #singleSelect class="form-control">
                <mat-option>
                    <ngx-mat-select-search placeholderLabel="Find deal..." [formControl]="dealFilterCtrl"
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="false"
                        [toggleAllCheckboxChecked]="false">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let deal of filteredDeal | async;"
                    style="border-bottom:1px solid #ced4da !important;" [value]="deal?.dealSno">
                    {{ deal?.dealId }} ({{deal?.stageTypeValue}})
                </mat-option>
            </mat-select>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Tasks <span class="text-danger">*</span></label>
            <input class="form-control" type="text" formControlName="taskName" placeholder="Enter task name">
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Description</label>
            <textarea class="form-control" type="text" formControlName="description" rows="5"></textarea>
        </div>
        <div class="form-group"
            *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin">
            <label for="exampleInputEmail1">Assigned To <span class="text-danger">*</span></label>
            <select formControlName="assignedAppUserSno" class="form-control">
                <option *ngFor="let user of userList;let i =index;" [value]="user?.appUserSno">
                    {{user?.name}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Status <span class="text-danger">*</span></label>
            <select formControlName="taskStatusCd" class="form-control">
                <option *ngFor="let taskStatus of taskStatusList; let i = index;" [value]="taskStatus?.codesDtlSno">
                    {{taskStatus?.cdValue}}
                </option>
            </select>
        </div>
        <div class="row">
            <div class="form-group col-6 col-sm-12 col-md-6">
                <label for="exampleInputEmail1">Start Date <span class="text-danger">*</span></label>
                <input class="form-control" type="date" formControlName="startDate">
            </div>
            <div class="form-group col-6 col-sm-12 col-md-6">
                <label for="exampleInputEmail1">Due Date</label>
                <input class="form-control" type="date" [min]="form.value.startDate" formControlName="dueDate">
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-4" *ngIf="!form.value?.dealTaskSno" [disabled]="form.invalid"
                (click)="addTask()">
                Save
            </button>
            <button class="btn btn-primary mt-4" *ngIf="form.value?.dealTaskSno" [disabled]="form.invalid"
                (click)="updateTask()">
                Update
            </button>
        </div>
    </div>
</form>