import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../providers/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-menu-permission',
  standalone: true,
  imports: [
    CommonModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule
  ],
  templateUrl: './menu-permission.component.html',
  styleUrls: ['./menu-permission.component.scss']
})
export class MenuPermissionComponent implements OnInit {

  public menuList: any = [];
  public menuCtrl: FormControl = new FormControl();
  public menuFilterCtrl: FormControl = new FormControl();
  public filteredMenu: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  isIndeterminate = false;
  isChecked = false;
  isAdmin: boolean = false;
  authUser: any = this.tokenStorage.getUser();
  previousIndex: any;
  currentIndex: any;
  selectedIndex: any;
  searchAssignVal: any = "";
  searchUnAssignVal: any = "";
  isShowLoading: boolean = false;
  users: any;
  isNoData: boolean = false;
  searchUnAssingedUser = new Subject<string>();
  searchAssingedUser = new Subject<string>();

  selectAppMenuSno: any;
  public filteredAssignUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredUnAssignUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  assignUser: any = [];
  unAssignUser: any = [];
  userType: any;
  selectCountrySno: any;
  countryList: any = [];
  roleList: any = [];
  selectRoleSno: any;
  selectedRoleValue: any;

  constructor(
    private router: Router,
    public api: ApiService,
    public toastrService: ToastrService,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute
  ) {
  }

  crmList: any = [
    {
      menuStatus: 'UnAssigned User',
      deals: [
        { dealName: 'Feltz Printing Service', dealOwner: 'Veera M', accountName: 'Feltz Printing Service (sample) - Capla Paprocki(sample)', amount: 35000.00, closingDate: '20/02/2023', isAdmin: false },
        { dealName: 'Feltz Printing Service', dealOwner: 'Veera M', accountName: 'Feltz Printing Service (sample) - Capla Paprocki(sample)', amount: 35000.00, closingDate: '20/02/2023', isAdmin: false }
      ],
    },
    {
      menuStatus: 'Assigned User',
      deals: [
        { dealName: 'Feltz Printing Service', dealOwner: 'Veera M', accountName: 'Feltz Printing Service (sample) - Capla Paprocki(sample)', amount: 35000.00, closingDate: '20/02/2023', isAdmin: true },
        { dealName: 'Feltz Printing Service', dealOwner: 'Veera M', accountName: 'Feltz Printing Service (sample) - Capla Paprocki(sample)', amount: 35000.00, closingDate: '20/02/2023', isAdmin: true }
      ]
    },
    // {
    //   menuStatus: 'Value Proposition',
    //   deals: [
    //     {dealName:'Feltz Printing Service',dealOwner:'Veera M',accountName:'Feltz Printing Service (sample) - Capla Paprocki(sample)',amount:35000.00,closingDate:'20/02/2023'},
    //     {dealName:'Feltz Printing Service',dealOwner:'Veera M',accountName:'Feltz Printing Service (sample) - Capla Paprocki(sample)',amount:35000.00,closingDate:'20/02/2023'}

    //   ]
    // },
    // {
    //   statusName: 'Identify Decision Makers',
    //   deals: []
    // },
    // {
    //   statusName: 'Proposal/Price Quote',
    //   deals: []
    // },
    // {
    //   statusName: 'Negotiation/Review',
    //   deals: []
    // },
    // {
    //   statusName: 'Closed Won',
    //   deals: []
    // },
    // {
    //   statusName: 'Closed Lost',
    //   deals: []
    // },
    // {
    //   statusName: 'Closed-Lost to Competition',
    //   deals: []
    // }
  ]

  ngOnInit() {
    this.route.params.subscribe(() => {
      let index = this.authUser?.menus?.findIndex((menu) => menu?.routerLink == '/menu-permission');
      if (index != -1) {
        this.userType = this.authUser?.menus[index]?.userType;
      }
      console.log(this.userType);
    });
    if (this.userType?.isSuperAdmin) {
      this.getRole();
    } else {
      this.selectCountrySno = this.authUser?.countrySno;
      this.selectedRoleValue = 'Employee'
      this.getMenu();
    }
    this.menuFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filtereMenu();
      });
    this.searchUnAssingedUser.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe((data: any) => {
        this.searchUnAssignVal = data.target.value;
        let search = this.searchUnAssignVal?.toString()?.toLowerCase();
        this.filteredUnAssignUsers.next(
          this.unAssignUser.filter(client => (client.name?.toLowerCase().indexOf(search) > -1) || (client.surname?.toLowerCase().indexOf(search) > -1) || (client.email?.toLowerCase().indexOf(search) > -1))
        );
      });
    this.searchAssingedUser.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe((data: any) => {
        this.searchAssignVal = data.target.value;
        let search = this.searchAssignVal?.toString()?.toLowerCase();
        this.filteredAssignUsers.next(
          this.assignUser.filter(client => (client.first_name?.toLowerCase().indexOf(search) > -1) || (client.last_name?.toLowerCase().indexOf(search) > -1) || (client.email?.toLowerCase().indexOf(search) > -1))
        );
      });
  }

  filtereMenu() {
    if (!this.menuList) {
      return;
    }
    // get the search keyword
    let search = this.menuFilterCtrl.value;
    if (!search) {
      this.filteredMenu.next(this.menuList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // // filter the categroy
    this.filteredMenu.next(
      this.menuList.filter(menu => menu?.title?.toLowerCase()?.indexOf(search) > -1)
    );
  }

  getProjectCount() {

  }

  toggleMenuSelectAll(selectAllValue: boolean) {
    this.filteredMenu
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          let menuList = [];
          for (let menus of val) {
            menuList.push(menus?.menuSno)
          }
          this.menuCtrl.patchValue(menuList);
        } else {
          this.menuCtrl.patchValue([]);
        }
      });

  }

  // changeAdmin(i: any, j: any) {
  //   let body: any = {
  //     appUserSno: this.crmList[i].users[j].appUserSno,
  //     appMenuSno: this.selectAppMenuSno,
  //     isAdmin: !this.crmList[i].users[j].isAdmin
  //   };
  //   this.api.put('8082/api/update_make_as_admin', body).subscribe((result: any) => {
  //     if (result != null && !result?.data?.message) {
  //       this.toastrService.success('Updated Successfully');
  //       this.crmList[i].users[j].isAdmin = !this.crmList[i].users[j].isAdmin
  //     } else {
  //       this.toastrService.error(result?.data?.message);
  //     }
  //   }, err => {
  //     this.toastrService.error(err)
  //   }
  //   );
  // }

  public getMenu() {
    let params: any = {};
    this.api.get('8082/api/get_app_menu_role', params).subscribe(result => {
      this.isShowLoading = false;
      if (result != null && result.data) {
        this.menuList = result.data;
        if (this.menuList?.length) {
          this.selectAppMenuSno = this.menuList[0]?.appMenuSno;
          this.getUserMenu();
        }
      } else {
        this.isNoData = false;
      }
    });
  }


  public getUserMenu() {
    let params: any = {};
    if (this.selectAppMenuSno) {
      params.appMenuSno = this.selectAppMenuSno;
    }
    if (this.selectCountrySno) {
      params.countrySno = this.selectCountrySno;
    }
    if (this.selectRoleSno) {
      params.roleCdSno = this.selectRoleSno;
    }
    this.api.get('8082/api/get_menu_user', params).subscribe(result => {
      this.isShowLoading = false;
      if (result != null && result.data) {
        this.crmList = result.data;
        if (this.crmList?.length) {
          this.unAssignUser = this.crmList[0]?.users;
          this.assignUser = this.crmList[1]?.users;
        }
      } else {
        this.crmList = [];
        this.isNoData = false;
      }
      // console.log(this.crmList);
      this.filteredUnAssignUsers.next(this.unAssignUser?.slice());
      this.filteredAssignUsers.next(this.assignUser?.slice());
    });
  }

  // changeDealStatus(i: any, currentIndex: any, previousIndex: any, previousContainer, container) {
  //   let body: any = {};
  //   body.appUserSno = this.crmList[i].users[currentIndex].appUserSno;
  //   body.appMenuSno = this.selectAppMenuSno;
  //   body.isMenuAssign = true;
  //   if (this.crmList[i].menuStatus == 'Assigned User') {
  //     body.isAssign = true;
  //   } else {
  //     body.isAssign = false;
  //   }
  //   this.api.put('8082/api/assign_menu_user', body).subscribe((result: any) => {
  //     // console.log(result);
  //     if (result != null && result.data) {
  //       this.crmList[i].users[currentIndex].appMenuSno = result.data.appMenuSno;
  //     }
  //   }, error => {
  //     this.toastrService.error(error)
  //   });
  // }

  changeAssignStatus(user: any) {
    // console.log(user);
    let body: any = {};
    body.appUserSno = user.appUserSno;
    body.appMenuSno = this.selectAppMenuSno;
    body.isMenuAssign = true;
    body.isAssign = true;
    body.isAdmin = false;
    this.api.put('8082/api/assign_menu_user', body).subscribe((result: any) => {
      // console.log(result);
      if (result != null && result.data) {
        user.appMenuSno = result.data.appMenuSno;
        this.assignUser.unshift(user);
        this.filteredAssignUsers.next(this.assignUser.slice());
        let i: number = this.unAssignUser.findIndex(client => client?.appUserSno == user.appUserSno);
        if (i != -1) {
          this.unAssignUser.splice(i, 1);
        }
        this.filteredUnAssignUsers.next(this.unAssignUser.slice());
      }
    }, error => {
      this.toastrService.error(error)
    });
  }

  changeUnAssignStatus(user: any) {
    // console.log(user);
    let body: any = {};
    body.appUserSno = user.appUserSno;
    body.appMenuSno = this.selectAppMenuSno;
    body.isMenuAssign = true;
    body.isAssign = false;
    this.api.put('8082/api/assign_menu_user', body).subscribe((result: any) => {
      // console.log(result);
      if (result != null && result.data) {
        delete user.appMenuSno;
        delete user.isAdmin;
        this.unAssignUser.unshift(user);
        this.filteredUnAssignUsers.next(this.unAssignUser.slice());
        let i: number = this.assignUser.findIndex(client => client?.appUserSno == user.appUserSno);
        this.assignUser.splice(i, 1);
        this.filteredAssignUsers.next(this.assignUser.slice());
        //   user.appMenuSno = result.data.appMenuSno;
        //   this.assignUser.push(user);
        //   this.filteredAssignUsers.next(this.assignUser.slice());
        //   let i: number = this.unAssignUser.findIndex(client => client?.appUserSno == user.appUserSno);
        //   if (i != -1) {
        //     this.unAssignUser.splice(i, 1);
        //   }
        //   this.filteredUnAssignUsers.next(this.unAssignUser.slice());
      }
    }, error => {
      this.toastrService.error(error)
    });
  }

  changeAdmin(user: any) {
    let body: any = {
      appUserSno: user?.appUserSno,
      appMenuSno: this.selectAppMenuSno,
      isAdmin: !user?.isAdmin
    };
    this.api.put('8082/api/update_make_as_admin', body).subscribe((result: any) => {
      if (result != null && !result?.data?.message) {
        this.toastrService.success('Updated Successfully');
        user.isAdmin = !user?.isAdmin
      } else {
        this.toastrService.error(result?.data?.message);
      }
    }, err => {
      this.toastrService.error(err)
    }
    );
  }

  clearUnAssignedUser() {
    this.searchUnAssignVal = '';
    let search = this.searchUnAssignVal?.toString()?.toLowerCase();
    this.filteredUnAssignUsers.next(
      this.unAssignUser.filter(client => (client.name?.toLowerCase().indexOf(search) > -1) || (client.surname?.toLowerCase().indexOf(search) > -1) || (client.email?.toLowerCase().indexOf(search) > -1))
    );
  }

  clearAssignedUser() {
    this.searchAssignVal = '';
    let search = this.searchAssignVal?.toString()?.toLowerCase();
    this.filteredAssignUsers.next(
      this.assignUser.filter(client => (client.first_name?.toLowerCase().indexOf(search) > -1) || (client.last_name?.toLowerCase().indexOf(search) > -1) || (client.email?.toLowerCase().indexOf(search) > -1))
    );
  }

  getRole() {
    let param: any = { codeType: "role_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      console.log(result.data)
      if (result != null && result.data.length > 0) {
        this.roleList = result.data;
        if (this.roleList?.length) {
          this.selectRoleSno = this.roleList[0]?.codesDtlSno;
          this.getCountry();
        }
      }
    });
  }

  getCountry() {
    let params: any = {};
    this.api.get('8082/api/get_country', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length) {
          this.selectCountrySno = this.countryList[0]?.countrySno;
        }
        this.changeRole(this.selectRoleSno);
      } else {
      }
    });
  }

  changeRole(value) {
    let obj = this.roleList.find((role) => role?.codesDtlSno == value);
    this.selectedRoleValue = obj?.cdValue;
    if ((obj?.cdValue == 'Admin') || (obj?.cdValue == 'Super Admin')) {
      this.getUserMenu();
    }
    if ((obj?.cdValue == 'Employee') && !this.menuList?.length) {
      this.getMenu();
    } else if (obj?.cdValue == 'Employee') {
      this.getUserMenu();
    }
  }

  updateRole(user, roleCd) {
    console.log(user)
    let body: any = {};
    body.appUserSno = user.appUserSno;
    body.roleCd = roleCd;
    this.api.put('8082/api/update_role', body).subscribe((result: any) => {
      if (result != null && result.data) {
        if (roleCd == 'Employee') {
          this.unAssignUser.unshift(user);
          let i: number = this.assignUser.findIndex((users) => users?.appUserSno == user?.appUserSno);
          console.log(i);
          if (i != -1) {
            this.assignUser.splice(i, 1);
          }
        } else {
          this.assignUser.unshift(user);
          let i: number = this.unAssignUser.findIndex((users) => users?.appUserSno == user?.appUserSno);
          console.log(i);
          if (i != -1) {
            this.unAssignUser.splice(i, 1);
          }
        }
        this.filteredUnAssignUsers.next(this.unAssignUser?.slice());
        this.filteredAssignUsers.next(this.assignUser?.slice());
      }
    });
  }
}
