import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../providers/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { DirectivesModule } from '../theme/directives/directives.module';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import * as XLSX from 'xlsx';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReplaySubject, Subject, debounceTime, take, takeUntil } from 'rxjs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { MatInputModule } from '@angular/material/input';
import { DataService } from '../providers/data/data.service';
import { PipesModule } from '../theme/pipes/pipes.module';
import * as moment from 'moment';

@Component({
  selector: 'app-deal',
  standalone: true,
  imports: [
    CommonModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    DirectivesModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    PipesModule
  ],
  templateUrl: './deal.component.html',
  styleUrls: ['./deal.component.scss']
})
export class DealComponent {

  previousIndex: any;
  currentIndex: any;
  sequnceCrmList: any = [];
  selectedIndex: any;
  count = 200;
  duration = 5000;
  isLoad: boolean = false;

  crmList: any = [];
  authUser: any = this.tokenStorage.getUser();
  selectCountrySno: any;

  // Autocomplete User
  public userCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  // Autocomplete Client
  public clientCtrl: FormControl = new FormControl();
  public clientFilterCtrl: FormControl = new FormControl();
  public filteredClients: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  dealList: any = [];
  protected _onDestroy = new Subject<void>();
  clients: any = [];
  isShowLoading: boolean = true;

  userType: any;
  countryList: any = [];
  selectedAppMenuSno: any;
  selectedUserSno: any;
  searchKey: any = '';
  modelChanged = new Subject<string>();
  isExport: boolean = false;

  constructor(
    private router: Router,
    public api: ApiService,
    public toastrService: ToastrService,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private dataService: DataService
  ) {
    this.modelChanged.pipe(debounceTime(800)).subscribe(() => {
      this.getDeal();
    });
  }

  ngOnInit(): void {
    // this.route.params.subscribe(() => {
    this.dataService.data$.subscribe((data) => {
      let index = this.authUser?.menus?.findIndex((menu) => menu?.routerLink == '/deal');
      if (index != -1) {
        this.userType = this.authUser?.menus[index]?.userType;
        this.selectedAppMenuSno = this.authUser?.menus[index]?.appMenuSno
      }
      if (!this.userType?.isAdmin && !this.userType?.isPageAdmin && !this.userType?.isGlobalAdmin && !this.userType?.isSuperAdmin) {
        this.selectedUserSno = this.authUser?.appUserSno;
      }
      this.searchKey = data?.searchKey;
      this.selectCountrySno = data?.selectCountrySno;
      this.clientCtrl.patchValue(data?.clientCtrl);
      this.userCtrl.patchValue(data?.userCtrl);
      this.getCountry();
    });
  }

  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = JSON.stringify(this.authUser?.countries);
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length && !this.selectCountrySno) {
          this.selectCountrySno = this.countryList[0]?.countrySno;
        }
        this.getClients();
        if (this.userType?.isSuperAdmin || this.userType?.isGlobalAdmin || this.userType?.isAdmin || this.userType?.isPageAdmin) {
          this.getUsers();
        } else {
          this.getDeal();
        }
      } else {
      }
    });
  }



  // drop(event: CdkDragDrop<any[]>) {
  //   alert(event.previousIndex)
  //   alert(event.currentIndex)
  //   alert(event.container.data)
  //   alert( event.previousContainer.data)
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex,
  //     );
  //   }
  // }

  drop(event: CdkDragDrop<{}[]>, i: any) {
    // console.log(event);
    // alert(event.previousIndex)
    // alert(event.currentIndex)
    // for(let i in this.crmList){
    // moveItemInArray(this.crmList[index].clients, event.previousIndex, event.currentIndex);
    // }
    // // console.log(this.crmList[index].clients)
    if (event.previousContainer == event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    // console.log('event.previousIndex' + event.previousIndex);
    // console.log('event.currentIndex' + event.currentIndex);
    // console.log('index' + i);
    // console.log(this.crmList[i].deals[event.currentIndex]);
    // console.log(this.crmList[i].statusName);
    this.selectedIndex = i;
    this.previousIndex = event.currentIndex;
    this.currentIndex = event.currentIndex;
    this.changeDealStatus(i, event.currentIndex, event.previousIndex, event.previousContainer.data, event.container.data)
  }


  changeDealStatus(i: any, currentIndex: any, previousIndex: any, previousContainer, container) {
    let body: any = {
      stageTypeCd: this.crmList[i].stageTypeCd,
      dealSno: this.crmList[i].deals[currentIndex].dealSno
    };
    if ((this.crmList[i].statusName == 'Won Progress') || (this.crmList[i].statusName == 'Won Complete')) {
      body.project = {
        // billingMethodCd: 62,
        projectName: this.crmList[i].deals[currentIndex].dealName,
        clientSno: this.crmList[i].deals[currentIndex].clientSno,
        countrySno: this.crmList[i].deals[currentIndex]?.countrySno
        // projectStatusCd: 65,
        // categoryStatusCd: 79
      }
    }
    body.createBy = this.authUser?.appUserSno;
    body.createdDate = this.api.dateToSavingStringFormatConvertion(new Date());
    this.api.put('8091/api/update_deal_status', body).subscribe((result: any) => {
      if (result != null) {
        if (!result.message) {
          this.crmList[i].deals[previousIndex].dealModifyDetails = {
            updateTime: new Date(),
            updateBy: (this.authUser?.profile?.name ?? '') + (this.authUser?.profile?.surname ?? '')
          }
          console.log(this.crmList[i].deals[previousIndex]);
          this.updateDealStatusIndexPos(i);
        } else {
          transferArrayItem(container, previousContainer, currentIndex, previousIndex);
          this.toastrService.error(result?.message);
        }
      } else {
        this.toastrService.error(result?.error);
      }
    }, err => {
      this.toastrService.error(err);
    }
    );
  }


  updateDealStatusIndexPos(index: any) {
    this.sequnceCrmList = [];
    let stageTypeCd: any;
    for (let i in this.crmList[index].deals) {
      if (this.crmList[index].stageTypeCd != this.crmList[index].deals[i].stageTypeCd) {
        stageTypeCd = this.crmList[index].deals[i].stageTypeCd;
        this.crmList[index].deals[i].stageTypeCd = this.crmList[index].stageTypeCd;
      }
      this.sequnceCrmList.push({ dealSno: this.crmList[index].deals[i].dealSno, seq: i });
    }
    let previousIndex = this.crmList.findIndex(x => x.stageTypeCd === stageTypeCd);
    if (previousIndex >= 0 && previousIndex != index) {
      for (let j in this.crmList[previousIndex]?.deals) {
        this.sequnceCrmList.push({ dealSno: this.crmList[previousIndex].deals[j].dealSno, seq: j });
      }
      // this.crmList[index].dealCount = this.crmList[index]?.deals.length || 0;
      // this.crmList[previousIndex].dealCount = this.crmList[previousIndex]?.deals.length || 0;
      // this.crmList[index].totalDealAmount = this.getDealTotalAmt(index);
      // this.crmList[previousIndex].totalDealAmount = this.getDealTotalAmt(previousIndex);
    }

    this.updateDealSequence();
  }

  getDealTotalAmt(index: any) {
    var total = 0;
    for (let i in this.crmList[index]?.deals) {
      total = total + this.crmList[index]?.deals[i].dealAmount;
    }
    return total;
  }



  updateDealSequence() {
    let body: any = {
      sequnceDealList: this.sequnceCrmList
    };
    this.api.put('8091/api/update_deal_squence', body).subscribe((result: any) => {
      if (result != null) {
      } else {
        this.toastrService.error(result?.data?.message);
      }
    }, err => {
      this.toastrService.error(err)
    }
    );

  }


  gotoAddDealPage() {
    this.router.navigate(['/deal/adddeal']);
  }


  getDeal() {
    this.isShowLoading = true;
    let param: any = {};
    if (this.userCtrl.value?.length) {
      param.dealOwnerList = '[' + this.userCtrl.value + ']';
    }
    if (this.clientCtrl.value?.length) {
      param.clientList = '[' + this.clientCtrl.value + ']';
    }
    if (this.selectCountrySno) {
      param.countrySno = this.selectCountrySno;
    }
    if (this.selectedUserSno) {
      param.dealOwnerList = '[' + this.selectedUserSno + ']';
    }
    if (this.api.isEmptyString(this.searchKey)) {
      param.searchKey = this.api.searchKey(this.searchKey);
    }
    console.log(param)
    this.api.get('8091/api/get_deal', param).subscribe(result => {
      this.isShowLoading = false;
      console.log(result);
      if (result != null && result.data) {
        this.crmList = result.data;
        for (let i in this.crmList) {
          this.crmList[i]?.deals.sort((a, b) => {
            let aDate = new Date(a.dealModifyDetails?.updateTime ?? a?.createdDate);
            let bDate = new Date(b.dealModifyDetails?.updateTime ?? b?.createdDate);
            return bDate.getTime() - aDate.getTime();
          });
        }
        // console.log(this.crmList)
      } else {
      }
    });
  }

  edit(i: any, j: any) {
    let param: any = {};
    param.dealSno = this.crmList[i].deals[j]?.dealSno;
    this.api.get('8091/api/get_deal_details', param).subscribe(result => {
      console.log(result);
      if (result != null && result?.data?.length) {
        let navigationExtras: any = {
          state: {
            data: result?.data[0]
          }
        };
        this.setData();
        this.router.navigate(['/deal/editdeal'], navigationExtras);
      } else {
      }
    });


  }



  deleteAlert(i: any, j: any) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'lightgrey',
      confirmButtonText: 'Yes, delete it',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your record has been deleted.',
        //   'success'
        // )
        this.delete(i, j);
      }
      else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled!',
          'Your record is safe',
          'error'
        )
      }
    })
  }


  delete(i: any, j: any) {
    let body: any = {
      dealSno: this.crmList[i].deals[j].dealSno
    };
    this.api.delete('8091/api/delete_deal', body).subscribe((result: any) => {
      if (result != null) {
        this.crmList[i].deals.splice(i, 1);
        this.toastrService.success('Deleted Sucessfully');
      }
    }, err => {
      this.isLoad = false;
      this.toastrService.error(err)
    });
  }

  viewDeal(client: any) {
    let param: any = {};
    param.dealSno = client?.dealSno;
    this.api.get('8091/api/get_deal_details', param).subscribe(result => {
      console.log(result);
      if (result != null && result?.data?.length) {
        let navigationExtras: any = {
          state: {
            data: result.data[0],
            // isAdmin: this.userType?.
          }
        };
        this.setData();
        this.router.navigate(['/deal/viewdeal'], navigationExtras);
      } else {
      }
    });


  }

  getTotalWithRoundOfAmount(data) {
    data.totalAmount = 0;
    data.totalWithRoundOfAmount = 0;
    data.withoutTaxTotalAmount = 0;
    if (data?.estimateDetails?.length) {
      for (let estimate of data?.estimateDetails) {
        if (estimate?.lineItems?.length)
          for (let item of estimate?.lineItems) {
            data.totalAmount = data.totalAmount + (item?.quantity * item?.rate) * (((100 + item?.taxPercentage) / 100));
            data.withoutTaxTotalAmount = data.withoutTaxTotalAmount + (item?.quantity * item?.rate);
          }
      }
      data.roundOf = Math.round(data.totalAmount) - data.totalAmount;
      data.totalWithRoundOfAmount = data.totalAmount + data.roundOf;
    }
    return data?.withoutTaxTotalAmount || 0;
  }

  selectDocument() {
    $('#document_upload').click();
  }

  // fileChange(file) {
  //   // console.log(file);
  //   let event = file.target
  //   let rowObject: any;
  //   var fileReader = new FileReader();
  //   fileReader.onload = (event: any) => {
  //     var data = event.target.result;
  //     var workbook = XLSX.read(data, {
  //       type: "binary"
  //     });
  //     workbook.SheetNames.forEach((sheet: any) => {
  //       rowObject = XLSX.utils.sheet_to_json(
  //         workbook.Sheets[sheet]
  //       );
  //     });
  //     console.log(rowObject);
  //     this.uploadExl(rowObject);
  //   }
  //   fileReader.readAsBinaryString(event.files[0]);
  // }

  // uploadExl(data) {
  //   $.getJSON('assets/json/old-deal.json', (json) => {
  //     let estimateIdList = [];
  //     // for (let i in json) {
  //     //   for (let j in data) {
  //     //     if (data[j]?.est_no && json[i]?.estimate_number?.toString()?.split('-')[1]?.trim() == data[j]?.est_no?.toString()?.trim()) {
  //     //       data[j].estimate_id = json[i]?.estimate_id?.toString();
  //     //       data[j].estimate_number = json[i]?.estimate_number?.toString();
  //     //       data[j].deal_name = data[j]?.deal_name?.toString()?.replaceAll("'", "''");
  //     //       data[j].description = data[j]?.description?.toString()?.replaceAll("'", "''");
  //     //       // if(!this.api.isEmptyString(data[j].description) ?? true){
  //     //       //   break;
  //     //       // }
  //     //       estimateIdList.push(data[j]);
  //     //       break;
  //     //     }
  //     //   }
  //     // }

  //     // without est no
  //     for (let j in data) {
  //       data[j].deal_name = data[j]?.deal_name?.toString()?.replaceAll("'", "''");
  //       data[j].description = data[j]?.description?.toString()?.replaceAll("'", "''");
  //       estimateIdList.push(data[j]);
  //     }

  //     console.log(estimateIdList);
  //     let body: any = {};
  //     body.xlSheet = estimateIdList;
  //     body.createTime = this.api.dateToSavingStringFormatConvertion(new Date());
  //     this.api.post('8091/api/upload_xl_sheet', body).subscribe((result: any) => {
  //       if (result != null) {
  //       } else {
  //         this.toastrService.error(result?.data?.message);
  //       }
  //     }, err => {
  //       this.toastrService.error(err)
  //     });
  //   });
  // }

  public getUsers() {
    let params: any = {}
    params.countries = '{' + this.selectCountrySno + '}';
    params.appMenuSno = this.selectedAppMenuSno;
    this.api.get('8082/api/get_user', params).subscribe(result => {
      if (result != null) {
        this.dealList = result?.data?.length ? result?.data : [];
        let isAlready: boolean = false;
        for (let i in this.dealList) {
          if (this.dealList[i]?.appUserSno == this.authUser?.appUserSno) {
            isAlready = true;
          }
        }
        if (!isAlready) {
          this.dealList.unshift({
            "appUserSno": this.authUser?.appUserSno,
            "name": this.authUser.profile?.name + ' ' + this.authUser.profile?.surname
          })
        }
        // this.userCtrl.setValue(this.dealList)
        this.filteredUsers.next(this.dealList.slice());
        this.userFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterUsers();
          });
        this.getDeal();
      } else {
      }
    });
  }

  protected filterUsers() {
    if (!this.dealList) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.dealList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsers.next(
      this.dealList.filter(user => user.name?.toLowerCase().indexOf(search) > -1)
    );
  }

  getClients() {
    let params: any = {};
    params.countrySno = this.selectCountrySno;
    this.api.get('8084/api/get_search_client', params).subscribe(result => {
      // console.log(result);
      if (result != null) {
        this.clients = result?.data?.length ? result?.data : [];
        // this.clientCtrl.setValue(this.clients)
        this.filteredClients.next(this.clients?.slice());
        this.clientFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterclients();
          });
      }
    }, error => {
    });
  }

  filterclients() {
    if (!this.clients) {
      return;
    }
    let search = this.clientFilterCtrl.value;
    if (!search) {
      this.filteredClients.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredClients.next(
      this.clients.filter(client => client.companyName?.toLowerCase().indexOf(search) > -1)
    );
  }

  toggleSelectAllClient(selectAllValue: boolean) {
    this.filteredClients.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
      if (selectAllValue) {
        let selected: any = [];
        for (let i in val) {
          if (val[i]?.clientSno)
            selected.push(val[i]?.clientSno)
        }
        this.clientCtrl.patchValue(selected);
      } else {
        this.clientCtrl.patchValue([]);
      }
    });
  }

  toggleSelectAllUsers(selectAllValue: boolean) {
    this.filteredUsers.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
      console.log(val);
      if (selectAllValue) {
        let selected: any = [];
        for (let i in val) {
          if (val[i]?.appUserSno)
            selected.push(val[i]?.appUserSno)
        }
        this.userCtrl.patchValue(selected);
      } else {
        this.userCtrl.patchValue([]);
      }
    });
  }

  changeCountry() {
    this.userCtrl.patchValue([]);
    this.clientCtrl.patchValue([]);
    this.selectedUserSno = null;
    this.getUsers();
    this.getClients();
    this.getDeal();
  }

  clearSearch() {
    this.searchKey = '';
    this.getDeal();
  }

  searchEle() {
    this.modelChanged.next('');
  }

  setData() {
    let data = {
      searchKey: this.searchKey,
      selectCountrySno: this.selectCountrySno,
      userCtrl: this.userCtrl.value,
      clientCtrl: this.clientCtrl.value
    };
    this.dataService.setData(data);
  }

  extractXl() {
    let param: any = {};
    if (this.userCtrl.value?.length) {
      param.dealOwnerList = '[' + this.userCtrl.value + ']';
    }
    if (this.clientCtrl.value?.length) {
      param.clientList = '[' + this.clientCtrl.value + ']';
    }
    if (this.selectCountrySno) {
      param.countrySno = this.selectCountrySno;
    }
    if (this.selectedUserSno) {
      param.dealOwnerList = '[' + this.selectedUserSno + ']';
    }
    if (this.api.isEmptyString(this.searchKey)) {
      param.searchKey = this.api.searchKey(this.searchKey);
    }
    this.isExport = true;
    this.api.get('8091/api/get_export_deal', param).subscribe(result => {
      this.isExport = false;
      console.log(result);
      if (result != null && result?.data) {
        if (result?.data?.length) {
          let report: any = result?.data;
          let xlList: any = [];
          for (let i in report) {
            // report[i].workedTime = this.workedTime(report[i].workedTime);
            let obj: any = {
              "Deal Id": report[i]?.dealId,
              "Deal Owner": report[i]?.dealOwnerName,
              "Amount": report[i]?.withoutTaxTotalAmount,
              "Deal Name": report[i]?.dealName,
              "Closing Date": report[i]?.closingDate,
              "Account Name": report[i]?.accountName,
              "Stage": report[i]?.stage,
              "Type": report[i]?.businessType,
              "Created By": report[i]?.createBy,
              "Modify By": report[i]?.dealModifyDetails?.updateBy,
              "Create Time": report[i]?.createDate,
              "Modify Time": report[i]?.dealModifyDetails?.updateTime,
              "Description": report[i]?.description,
              "Contact Name": report[i]?.contactName,
              "Deal Type": report[i]?.dealType,
              "End Customer": report[i]?.endUser,
              "Contact Email": report[i]?.contactEmail,
              "EST NO": report[i]?.estNo,
              "Technology": report[i]?.technology,
            }
            xlList.push(obj);
          }
          let sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlList);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, sheet, 'report')
          let wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            bookSST: false,
            type: 'binary'
          });
          function s2ab(s) {
            let buf = new ArrayBuffer(s.length);
            let view = new Uint8Array(buf);
            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
          }
          let blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
          var fileName = "CRM backup - " + moment().format('DD-MM-YYYY') + ".xlsx";
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            var base64data = reader.result;
            let a: any = document.createElement('a');
            a.href = base64data;
            a.download = fileName;
            a.click();
          }
        } else {
          this.toastrService.error('No Record Found...');
        }
      }
    });
  }
}
