import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../providers/api/api.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import * as moment from 'moment';

@Component({
  selector: 'app-add-deal-task',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
  ],
  templateUrl: './add-deal-task.component.html',
  styleUrls: ['./add-deal-task.component.scss']
})
export class AddDealTaskComponent {
  authUser: any = this.tokenStorage.getUser();
  public form: FormGroup;
  userType: any;
  selectedAppMenuSno: any;
  selectCountrySno: any;
  userList: any = [];
  taskStatusList: any = [];
  countryList: any = [];
  dealList: any = [];
  public dealFilterCtrl: FormControl = new FormControl();
  public filteredDeal: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  isDisabled: boolean = true;

  constructor(
    public fb: FormBuilder,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddDealTaskComponent>,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      dealTaskSno: null,
      taskName: [null, Validators.compose([Validators.required])],
      description: null,
      startDate: [null, Validators.compose([Validators.required])],
      dueDate: [null],
      assignedAppUserSno: [null, Validators.compose([Validators.required])],
      taskStatusCd: [null, Validators.compose([Validators.required])],
      dealSno: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(() => {
      let index = this.authUser?.menus?.findIndex((menu) => menu?.routerLink == '/deal_task');
      if (index != -1) {
        this.userType = this.authUser?.menus[index]?.userType;
        this.selectedAppMenuSno = this.authUser?.menus[index]?.appMenuSno
      }
      if (this.data?.task) {
        if (this.api.isEmptyString(this.data?.task?.startDate)) {
          this.data.task.startDate = moment(this.data?.task?.startDate).format('yyyy-MM-DD');
        }
        if (this.api.isEmptyString(this.data?.task?.dueDate)) {
          this.data.task.dueDate = moment(this.data?.task?.dueDate).format('yyyy-MM-DD');
        }
        console.log(this.data?.task);
        this.selectCountrySno = this.data?.task?.countrySno;
        delete this.data?.task?.countrySno;
        this.form.setValue(this.data?.task);
      }
      this.getTaskStatusEnum();
      this.getCountry();
    });
  }

  public getTaskStatusEnum() {
    let param: any = { codeType: 'project_task_cd' };
    this.api.get('8082/api/get_enum_names', param).subscribe(result => {
      // console.log(result);
      if (result != null && result.data) {
        this.taskStatusList = result.data;
        if (this.taskStatusList?.length && !this.data?.task) {
          this.form.patchValue({
            taskStatusCd: this.taskStatusList[0]?.codesDtlSno
          })
        }
      } else {

      }
    });
  }

  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = JSON.stringify(this.authUser?.countries);
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length && !this.data?.task) {
          this.selectCountrySno = this.countryList[0]?.countrySno;
        }
        if (this.userType?.isSuperAdmin || this.userType?.isGlobalAdmin || this.userType?.isAdmin || this.userType?.isPageAdmin) {
          this.getUsers();
        } else {
          this.form.patchValue({
            "assignedAppUserSno": this.authUser.appUserSno
          });
          this.getDealId();
        }
      } else {
      }
    });
  }

  public getUsers() {
    let params: any = {}
    params.countries = '{' + this.selectCountrySno + '}';
    params.appMenuSno = this.selectedAppMenuSno;
    this.api.get('8082/api/get_user', params).subscribe(result => {
      if (result != null) {
        this.userList = result?.data?.length ? result?.data : [];
      }
      this.getDealId();
    });
  }

  addTask() {
    let body: any = {};
    body = this.form.value;
    console.log(body)
    this.api.post('8091/api/create_deal_task', body).subscribe((result: any) => {
      if (result?.data) {
        body.dealTaskSno = result?.data?.dealTaskSno
        this.dialogRef.close(body);
      }
    });
  }

  updateTask() {
    let form: any = this.form.value;
    let updateArgumentObj: any = {
      task_name: form.taskName,
      description: form.description,
      start_date: form.startDate,
      due_date: form.dueDate,
      assigned_app_user_sno: form.assignedAppUserSno,
      task_status_cd: form.taskStatusCd,
    }
    let body: any = {};
    body.updateArgumentObj = updateArgumentObj;
    body.dealTaskSno = form?.dealTaskSno;
    console.log(body)
    this.api.put('8091/api/update_deal_task', body).subscribe((result: any) => {
      if (result?.data && result?.data?.dealTaskSno) {
        this.dialogRef.close(form);
      }
    });
  }

  getDealId() {
    let param: any = {};
    param.countrySno = this.selectCountrySno;
    this.api.get('8091/api/get_deal_id', param).subscribe(result => {
      console.log(result);
      if (result != null && result.data) {
        this.dealList = result?.data;
        this.filteredDeal.next(this.dealList.slice());
        this.dealFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDeal();
          });
      } else {
      }
    });
  }

  filterDeal() {
    if (!this.dealList) {
      return;
    }
    // get the search keyword
    let search = this.dealFilterCtrl.value;
    if (!search) {
      this.filteredDeal.next(this.dealList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDeal.next(
      this.dealList.filter(deal => deal.dealId?.toLowerCase().indexOf(search) > -1)
    );
  }

  changeCountry() {
    this.filteredDeal.next([]);
    this.userList = [];
    if (this.userType?.isSuperAdmin || this.userType?.isGlobalAdmin || this.userType?.isAdmin || this.userType?.isPageAdmin) {
      this.getUsers();
    } else {
      this.getDealId();
    }
  }

}
