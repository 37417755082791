<div class="row my-4">
    <div class="col-12 d-flex flex-row-reverse">
        <mat-form-field appearance="outline" class="ml-2">
            <mat-label>Select Project</mat-label>
            <mat-select placeholder="Account" [multiple]="false" [formControl]="projectCtrl"
                (ngModelChange)="getSelectedProject();getSelectedProjectPlan();">
                <mat-option>
                    <ngx-mat-select-search [formControl]="projectFilterCtrl" placeholderLabel="Find Account.."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="false"
                        [toggleAllCheckboxChecked]="false">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let project of filteredProject | async;" [value]="project?.projectSno">
                    {{project?.projectName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="container ml-0 mb-3">
    <h2>Project Details</h2>
    <div class="row">
        <div class="col-md-12 col-lg-6 card">
            <div class="row">
                <div class="col-4 p-2 border-bottom border-light text-center bg-primary text-white">
                    Project name
                </div>
                <div class="col-8 border-bottom border-dark">
                    <p *ngIf="!api.isEmptyString(selectedProject?.projectName)" class="my-2 fw-bold">-----</p>
                    <p *ngIf="api.isEmptyString(selectedProject?.projectName)" class="my-2">
                        {{selectedProject?.projectName}}</p>
                </div>
                <div class="col-4 p-2 border-bottom border-light text-center bg-primary text-white">
                    Project manager
                </div>
                <div class="col-8 border-bottom border-dark">
                    <p *ngIf="!selectedProject?.projectAdmin?.length ||
                    !api.isEmptyString(selectedProject?.projectName)" class="my-2 fw-bold">-----</p>
                    <p *ngIf="selectedProject?.projectAdmin?.length && 
                    api.isEmptyString(selectedProject?.projectAdmin[0]?.name)" class="my-2">
                        {{selectedProject?.projectAdmin[0]?.name}}</p>
                </div>
                <div class="col-4 p-2 border-bottom border-light text-center bg-primary text-white">
                    Project start date
                </div>
                <div class="col-8 border-bottom border-dark">
                    <p *ngIf="!api.isEmptyString(selectedProject?.startDate)" class="my-2 fw-bold">-----</p>
                    <p *ngIf="api.isEmptyString(selectedProject?.startDate)" class="my-2">{{selectedProject?.startDate |
                        date :'dd-MM-y'}}</p>
                </div>
                <div class="col-4 p-2 border-bottom border-light text-center bg-primary text-white">
                    Project finish date
                </div>
                <div class="col-8 border-bottom border-dark">
                    <p *ngIf="!api.isEmptyString(selectedProject?.endDate)" class="my-2 fw-bold">-----</p>
                    <p *ngIf="api.isEmptyString(selectedProject?.endDate)" class="my-2">{{selectedProject?.endDate |
                        date :'dd-MM-y'}}</p>
                </div>
                <div class="col-4 p-2 border-bottom border-light text-center bg-primary text-white">
                    Duration
                </div>
                <div class="col-8 border-bottom border-dark">
                    <p *ngIf="selectedProject ? false : true" class="my-2 fw-bold">-----</p>
                    <p *ngIf="selectedProject ?  calculateDiff() : false" class="my-2">{{selectedProject?.duration}}
                        {{selectedProject?.duration > 1 ? 'days' : 'day'}}
                    </p>
                </div>
                <div class="col-4 p-2 border-bottom border-light text-center bg-primary text-white">
                    % complete
                </div>
                <div class="col-8 border-bottom border-dark">
                    <p *ngIf="!selectedProject?.totalPercentage" class="my-2 fw-bold">-----</p>
                    <p *ngIf="selectedProject?.totalPercentage" class="my-2">{{selectedProject?.totalPercentage}} %</p>
                </div>
            </div>
        </div>
    </div>
</div>

<h2>Project Plan</h2>
<div class="table-responsive bg-white box-shadow">
    <!-- <table class="table">
        <thead>
            <tr class="bg-primary text-white">
                <th scope="col">Task Name</th>
                <th scope="col">Responsibility</th>
                <th scope="col">Start</th>
                <th scope="col">Finish</th>
                <th scope="col"> % complete</th>
                <th scope="col">Milestone</th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>
                <th scope="col">Comments</th>
                <th scope="col">Checklist Items</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody class="table-hover" *ngFor="let item of itemList;let i = index;">
            <tr>
                <td>
                    <textarea rows="5" style="min-width: 350px;" class="form-control" placeholder="Enter Task"></textarea>
                </td>
                <td>
                    <input type="text" style="min-width: 250px;" class="form-control" placeholder="Responsibility" />
                </td>
                <td>
                    <input type="date" style="min-width: 200px;" class="form-control" />
                </td>
                <td>
                    <input type="date" style="min-width: 200px;" class="form-control" />
                </td>
                <td>
                    <input type="number" style="min-width: 150px;" class="form-control" min="1" max="100" placeholder="Complete Percentage" />
                </td>
                <td>
                    <mat-form-field appearance="outline">
                        <mat-select [multiple]="false" placeholder="Select Milestone">
                            <mat-option>Yes</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline">
                        <mat-select [multiple]="false" placeholder="Select Priority">
                            <mat-option>Medium</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline">
                        <mat-select [multiple]="false" placeholder="Select Status">
                            <mat-option>Completed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <textarea rows="5" style="min-width: 350px;" class="form-control" placeholder="Comments"></textarea>
                </td>
                <td>
                    <textarea rows="5" style="min-width: 350px;" class="form-control"
                        placeholder="Checklist Items"></textarea>
                </td>
                <td>
                    <div class="btn-group" role="group">
                        <button class="btn btn-info btn-sm" (click)="removePlan(i)"><i class="fa fa-trash"></i></button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table> -->

    <div [formGroup]="form">
        <table class="table" formArrayName="projectPlan">
            <thead>
                <tr class="bg-primary text-white">
                    <th scope="col" class="text-truncate">#</th>
                    <th scope="col" class="text-truncate">Task Name</th>
                    <th scope="col" class="text-truncate">Responsibility</th>
                    <th scope="col" class="text-truncate">Start</th>
                    <th scope="col" class="text-truncate">Finish</th>
                    <!-- <th scope="col" class="text-truncate"> % complete</th> -->
                    <th scope="col" class="text-truncate">Milestone</th>
                    <th scope="col" class="text-truncate">Priority</th>
                    <th scope="col" class="text-truncate">Status</th>
                    <th scope="col" class="text-truncate">Comments</th>
                    <th scope="col" class="text-truncate">Checklist Items</th>
                    <th scope="col" class="text-truncate">Action</th>
                </tr>
            </thead>
            <tbody class="table-hover" *ngFor="let plan of projectPlan().controls;let i = index;" [formGroupName]="i">
                <tr>
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        <input type="text" formControlName="taskName" tyle="min-width: 250px;" class="form-control"
                            placeholder="Task Name" />
                    </td>
                    <td>

                    </td>
                    <td>
                        <input type="date" formControlName="startDate" style="min-width: 200px;" class="form-control" />
                    </td>
                    <td>
                        <input type="date" formControlName="endDate" style="min-width: 200px;" class="form-control" />
                    </td>
                    <!-- <td>
                        <span class="ml-4">0%</span>
                    </td> -->
                    <td>
                        <mat-form-field>
                            <mat-select placeholder="Select Milestone" formControlName="milestoneStatusCd">
                                <mat-option *ngFor="let milestone of milestoneStatusList;let k = index;"
                                    [value]="milestone.codesDtlSno">{{milestone?.cdValue}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <button class="btn border-0" *ngIf="projectPlan().value?.length > 1" (click)="removePlan(i)"><i
                                class="fa fa-trash text-danger"></i></button>
                    </td>
                </tr>
                <button mat-raised-button color="primary" class="p-4 my-3 ml-3 w-100" (click)="insertTask(i)"
                    *ngIf="selectedProject">
                    Add Task
                </button>
                <tr formArrayName="taskList" *ngFor="let task of projectTask(i).controls;let j = index;">
                    <td>
                        {{i+1}}.{{j+1}}
                    </td>
                    <td [formGroupName]="j">
                        <textarea rows="5" style="min-width: 350px;" class="form-control" formControlName="taskName"
                            placeholder="Enter Task"></textarea>
                    </td>
                    <td [formGroupName]="j">
                        <mat-form-field>
                            <mat-select placeholder="Select Users" formControlName="assignedAppUserSno">
                                <mat-option *ngFor="let user of userList;let k = index;"
                                    [value]="user.appUserSno">{{user?.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td [formGroupName]="j">
                        <input type="date" formControlName="startDate" style="min-width: 200px;" class="form-control" />
                    </td>
                    <td [formGroupName]="j">
                        <input type="date" formControlName="dueDate" style="min-width: 200px;" class="form-control" />
                    </td>
                    <!-- <td [formGroupName]="j">
                        <span class="ml-4">0%</span>
                    </td> -->
                    <td [formGroupName]="j">
                        <mat-form-field>
                            <mat-select placeholder="Select Milestone" formControlName="milestoneStatusCd">
                                <mat-option *ngFor="let milestone of milestoneStatusList;let k = index;"
                                    [value]="milestone.codesDtlSno">{{milestone?.cdValue}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td [formGroupName]="j">
                        <mat-form-field>
                            <mat-select placeholder="Select Priority" formControlName="priorityStatusCd">
                                <mat-option *ngFor="let priority of priorityStatusList;let k = index;"
                                    [value]="priority.codesDtlSno">{{priority?.cdValue}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td [formGroupName]="j">
                        <mat-form-field>
                            <mat-select placeholder="Select Status" formControlName="taskStatusCd">
                                <mat-option *ngFor="let task of taskStatusList;let k = index;"
                                    [value]="task.codesDtlSno">{{task?.cdValue}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td [formGroupName]="j">
                        <textarea rows="5" style="min-width: 350px;" class="form-control" formControlName="description"
                            placeholder="Enter Comments"></textarea>
                    </td>
                    <td [formGroupName]="j">
                        <textarea rows="5" style="min-width: 350px;" class="form-control"
                            formControlName="checkListItems" placeholder="Enter Checklist Items"></textarea>
                    </td>
                    <td [formGroupName]="j">
                        <button class="btn border-0" *ngIf="projectTask(i).value?.length > 1"
                            (click)="removeTask(i,j)"><i class="fa fa-trash text-danger"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- *ngIf="selectedProject" -->
<div class="row" *ngIf="selectedProject">
    <div class="col-6 ">
        <button mat-raised-button color="primary" class="p-4 my-3" (click)="insertProjectPlan()">
            <mat-icon>add</mat-icon>&nbsp;Add Plan
        </button>
    </div>
    <div class="col-6 d-flex justify-content-end">
        <button mat-raised-button color="primary" class="p-4 my-3" style="margin-right: 35px;" (click)="exportExcel()">
            Export
        </button>
        <button mat-raised-button color="primary" class="p-4 my-3" style="margin-right: 70px;" (click)="updateTask()">
            Update
        </button>
    </div>
</div>