import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ToastrService } from 'ngx-toastr';
import { PhotoService } from 'src/app/photoservice/photoservice.service';
import { ApiService } from 'src/app/providers/api/api.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { TokenStorageService } from 'src/app/pages/login/token-storage.service';

@Component({
  selector: 'app-add-bills',
  templateUrl: './add-bills.component.html',
  imports: [CommonModule, MatIconModule, FormsModule, ReactiveFormsModule],
  styleUrls: ['./add-bills.component.scss'],
  standalone: true
})
export class AddBillsComponent {
  billsForm: any = [];
  deletedDetailList: any = [];
  file: any;
  billList: any = [];
  paymentList: any = [];
  taxList: any = [];
  accountList: any;
  clients: any;
  vendorList: any = [];
  discountList: any;
  authUser: any = this.tokenStorage.getUser();
  stateList: any = [];
  data: any;

  constructor(
    public fb: FormBuilder,
    public photoService: PhotoService,
    private api: ApiService,
    public toastService: ToastrService,
    private location: Location,
    private tokenStorage: TokenStorageService
  ) {
    this.billsForm = new FormGroup({
      'billSno': new FormControl(null),
      'vendorSno': new FormControl(null, Validators.required),
      "sourceOfSupplyCd": new FormControl(null, Validators.required),
      "destinationOfSupplyCd": new FormControl(null, Validators.required),
      'billNo': new FormControl(null, Validators.required),
      'orderNo': new FormControl(null),
      'billDate': new FormControl(null, Validators.required),
      'dueDate': new FormControl(null),
      'paymentTermsCd': new FormControl(null),
      'isReverseCharge': new FormControl(null),
      'notes': new FormControl(null),
      'media': new FormControl(null),
      'bills': new FormArray([]),
    });

  };

  ngOnInit() {
    this.getEnumPaymentTerms();
    this.getEnumDiscountType();
    this.getEnumTax();
    this.getAccount();
    this.getClients();
    this.getVendor();
    this.getState();
    let param: any = window.history.state;
    if (param?.data) {
    //   console.log(param?.data);
      this.data = param?.data;
      let interval = setInterval(() => {
      //   console.log(this.clients?.length && this.paymentList?.length && this.discountList?.length &&
          // this.accountList?.length && this.taxList?.length && this.vendorList?.length);

        if (this.clients?.length && this.paymentList?.length && this.discountList?.length &&
          this.accountList?.length && this.taxList?.length && this.vendorList?.length) {
          clearInterval(interval);
          this.setFormValue(param?.data);
        }
      }, 1000);
    } else {
      this.addDetail();
    }
  };

  setFormValue(data) {
    for (let i in data.bills) {
      this.addDetail();
    }
    let obj = {
      'billSno': data?.billSno,
      'vendorSno': data?.vendorSno,
      "sourceOfSupplyCd": data?.sourceOfSupplyCd,
      "destinationOfSupplyCd": data?.destinationOfSupplyCd,
      'billNo': data?.billNo,
      'orderNo': data?.orderNo,
      'billDate': data?.billDate,
      'dueDate': data?.dueDate,
      'paymentTermsCd': data?.paymentTermsCd,
      'isReverseCharge': data?.isReverseCharge,
      'notes': data?.notes,
      'media': data?.media,
      'bills': data?.bills,
    };
    this.billsForm.setValue(obj);
  }

  get getDetailList() {
    return this.billsForm.controls["bills"] as FormArray;
  }

  getState() {
    let param: any = {};
    this.api.get("8089/api/get_state", param).subscribe((result) => {
      if (result != null && result?.data?.length) {
        this.stateList = result.data;
      }
    });
  }


  removeVisit(i: number) {
    this.deletedDetailList.push(this.getDetailList.value[i].billDetailSno)
    this.getDetailList.removeAt(i);
  }

  // addDetail() {
  //   alert(JSON.stringify(this.getDetailList))
  //   this.getDetailList?.push(this.fb.group({
  //     itemDetails: new FormControl(null),
  //     account: new FormControl(null),
  //     quantity: new FormControl(null),
  //     rate: new FormControl(null),
  //     tax: new FormControl(null),
  //     customerDetails: new FormControl(null),
  //     amount: new FormControl(null),
  //     close: new FormControl(null),
  //   }));
  // }

  addDetail() {
    this.getDetailList?.push(this.fb.group({
      'billDetailSno': new FormControl(null),
      'accountSno': new FormControl(null),
      'clientSno': new FormControl(null),
      'itemDetail': new FormControl(null),
      'quantity': new FormControl(0),
      'rate': new FormControl(0),
      'taxCd': new FormControl(null),
      'amount': new FormControl(null),
    }));
  }

  fileChange(input) {
    const reader = new FileReader();
    if (input.files.length) {
      this.file = input.files[0].name;
    //   console.log(input.files[0])
    }
    this.changeFile(input, ['png', 'jpeg', 'jpg', 'webp', 'pdf', '.doc', '.docx', '.xml'], 'conSentForm');
  }

  removeFile(): void {
    this.file = '';
  }

  changeFile(e: any, fileFormat: any, type: any) {
    this.photoService.onFileChange(e, fileFormat, type, (result: any) => {
      if (result != null && result != undefined) {
        result[0].azureId = result[0].fileType;
        this.billsForm.patchValue({
          media: result
        });
      }
    });
  }

  save() {
    let body: any = this.billsForm.value;
    // body.settings.project_status_cd=this.form.value.settings.project_status_cd;
    // body.settings.registrationDate = this.api.dateToSavingStringFormatConvertion(new Date());
    // body.form.value.budget.costBudget=this.form.value.budget.budget 
    body.appUserSno = this.authUser?.appUserSno;
    body.createdOn = this.api.dateToSavingStringFormatConvertion(new Date());
    if (this.authUser.roleCdValue == 'Admin') {
      let vendorIndex = this.vendorList.findIndex((vendor: any) => vendor?.vendorSno == body?.vendorSno);
      let sourceIndex = this.stateList.findIndex((state) => state?.stateSno == body?.sourceOfSupplyCd);
      let destinationIndex = this.stateList.findIndex((state) => state?.stateSno == body?.destinationOfSupplyCd);

      body.zohoDetails = {
        "vendor_id": this.vendorList[vendorIndex]?.vendorId,
        "bill_number": body?.billNo,
        "reference_number": body?.orderNo,
        "date": moment(body?.billDate ? body?.billDate : new Date()).format('YYYY-MM-DD'),
        "due_date": moment(body?.dueDate ? body?.dueDate : new Date()).format('YYYY-MM-DD'),
        "payment_terms": 0,
        "line_items": [
          // {
          //   "name": "test",
          //   "account_id": "2630223000007292339",
          //   "description": "",
          //   "rate": 0,
          //   "quantity": 1,
          //   "discount": 0,
          // }
        ],
        "next_action": "submit",
        "entity_type": "bill",
        "source_of_supply": this.stateList[sourceIndex]?.stateShortCode,
        "destination_of_supply": this.stateList[destinationIndex]?.stateShortCode,
      };
      for (let items of body?.bills) {
        if (!body?.zohoDetails?.line_items?.length) {
          body.zohoDetails.line_items = [];
        }
        let accountIndex = this.accountList.findIndex((account: any) => parseInt(account?.accountSno) == parseInt(items?.accountSno));
        body.zohoDetails.line_items.push({
          "name": items?.itemDetail,
          "account_id": this.accountList[accountIndex]?.zohoAccountId,
          "description": '',
          "rate": items?.rate,
          "quantity": items?.quantity,
        })
      }
      body.isAdmin = true;
    };
  //   console.log(body)
    this.api.post('8090/api/insert_all_bills', body).subscribe(result => {
      if (result != null && result.data) {
        this.toastService.success('Saved Successfully');
        this.location.back();
      } else {

      }
    });
  }

  update() {
    let body: any = this.billsForm.value;
    body.deletedDetailList = this.deletedDetailList;
    // body.settings.project_status_cd=this.form.value.settings.project_status_cd;
    // body.settings.registrationDate = this.api.dateToSavingStringFormatConvertion(new Date());
    // body.form.value.budget.costBudget=this.form.value.budget.budget 
    if (this.authUser.roleCdValue == 'Admin') {
      body.zohoDetails = {
        "vendor_id": "2630223000000184184",
        "bill_number": body?.billNo,
        "reference_number": body?.orderNo,
        "date": moment(body?.billDate ? body?.billDate : new Date()).format('YYYY-MM-DD'),
        "due_date": moment(body?.dueDate ? body?.dueDate : new Date()).format('YYYY-MM-DD'),
        "payment_terms": 0,
        "line_items": [
          {
            "name": "test",
            "account_id": "2630223000007292339",
            "description": "",
            "rate": 0,
            "quantity": 1,
            "discount": 0,
          }
        ],
        "next_action": "submit",
        "entity_type": "bill",
        "source_of_supply": "TN",
        "destination_of_supply": "TN",
      };
      body.isAdmin = true;
      body.zohoBillId = this.data?.zohoBillId;
    };
  //   console.log(body)
    this.api.put('8090/api/update_all_bills', body).subscribe((result: any) => {
      if (result != null && result.data) {
        this.toastService.success('Updated Successfully');
        this.location.back();
      } else {

      }
    });
  }

  getEnumPaymentTerms() {
    let param: any = { codeType: "payment_terms_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      if (result != null && result.data.length > 0) {
        this.paymentList = result.data;
        this.billsForm.patchValue({
          paymentTermsCd: result.data[0].codesDtlSno,
        })
      }
    });
  }

  getEnumDiscountType() {
    let param: any = { codeType: "discount_type_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      if (result != null && result.data.length > 0) {
        this.discountList = result.data;
        this.billsForm.patchValue({
          discountTypeCd: result.data[0].codesDtlSno,
        })
      }
    });
  }

  getEnumTax() {
    let param: any = { codeType: "tax_cd" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      if (result != null && result.data.length > 0) {
        this.taxList = result.data;
        this.billsForm.patchValue({
          taxCd: result.data[0].codesDtlSno,
        })
      }
    });
  }

  getVendor() {
    let param: any = {};
    this.api.get("8089/api/get_vendor", param).subscribe(result => {
      // alert(JSON.stringify(result))
    //   console.log(result)
      if (result != null) {
        if (result.data != null && result.data.length > 0) {
          this.vendorList = result.data;
        } else {
        }
      } else {
        this.toastService.error('Something went wrong');
      }
    }, err => {
      this.toastService.error(err)
    }
    );
  }

  getAccount() {
    let param: any = {};
    // alert(this.accountList)
    this.api.get("8089/api/get_account", param).subscribe(result => {
      // alert(JSON.stringify(result))
    //   console.log(result)
      if (result != null) {
        if (result.data != null && result.data.length > 0) {
          this.accountList = result.data;
        } else {
        }
      } else {
        this.toastService.error('Something went wrong');
      }
    }, err => {
      this.toastService.error(err)
    }
    );
  }

  getClients() {
    // this.isShowLoading = true;
    this.api.get('8084/api/get_client').subscribe(result => {
      // this.isShowLoading = false;
      if (result && result.data) {
        this.clients = result.data;
      }
    }, error => {

    });
  }

  removeMedia() {
    $('#expense_media').val('').clone(true);
    this.billsForm.patchValue({
      media: null
    });
  }
}
