import { DatePipe, getCurrencySymbol } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  url: any = '';
  port: any = '9000';
  isLive: boolean = true;

  constructor(public httpClient: HttpClient, private datePipe: DatePipe) {
    if (this.isLive) {
      this.url = "https://internals.tocumulus.com:";
    } else {
      this.url = "http://localhost:";
    }
  }

  get(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    if (this.isLive) {
      endpoint = this.split_at_index(endpoint, 4)
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    return this.httpClient.get(this.url + endpoint, reqOpts).pipe(
      // retry(1), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    reqOpts.params = new HttpParams();

    if (this.isLive) {
      endpoint = this.split_at_index(endpoint, 4)
    }

    return this.httpClient
      .post(this.url + endpoint, JSON.stringify(body), reqOpts)
      .pipe(
        // retry(1), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    // .map((res: Response) =>res.json())
    // .catch((error: any) => Observable.throw(error.json().error || 'Server error'));

  }

  put(endpoint: string, body: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    reqOpts.params = new HttpParams();

    if (this.isLive) {
      endpoint = this.split_at_index(endpoint, 4)
    }

    return this.httpClient.put(this.url + endpoint, body, reqOpts).pipe(
      // retry(1), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  delete(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    if (this.isLive) {
      endpoint = this.split_at_index(endpoint, 4)
    }

    // reqOpts.params = reqOpts.params.set("jwt", localStorage.getItem("jwttoken"));
    return this.httpClient.delete(this.url + endpoint, reqOpts)
      .pipe(
        catchError((err) => this.handleError(err))
      )
  }

  patch(endpoint: string, body: any, reqOpts?: any) {

    if (this.isLive) {
      endpoint = this.split_at_index(endpoint, 4)
    }

    return this.httpClient.patch(this.url + '/' + endpoint, body, reqOpts);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.log("Server was down");
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  dateToSavingStringFormatConvertion(currentDate: Date) {
    let datewithouttimezone: Date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
    return this.datePipe.transform(datewithouttimezone, 'yyyy-MM-dd HH:mm:ss');
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  split_at_index(value: any, index: number) {
    return (this.port + value.substring(index));
  }

  isEmptyString(str: any) {
    return str?.trim()?.length > 0;
  }

  getCurrencyCode(code) {
    if (code)
      return getCurrencySymbol(code, 'narrow');
    else
      return '₹';
  }

  convertNumberToWords(data) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    var amount = data;
    amount = amount?.toString();
    var atemp = amount?.split(".");
    var number = atemp[0]?.split(",")?.join("");
    var n_length = number?.length;
    var words_string = "";
    var value: any = "";

    if (n_length <= 9) {
      var n_array: any = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string?.split("  ")?.join(" ");
    }
    return words_string;
  }

  searchKey(value) {
    return value?.toString()?.replaceAll(' ','%');
  }

  // scrapWeb(url: any, params?: any, callback?: any) {
  //   let param: any = {
  //     'api_key': 'YI1W5FOMD4UH1QBXBF9BCI6H1W2HSCS909TE17O4XC7T549WRVYNQCTP31D2GQWZY6CEPRJYYDOC5XJB',
  //     'url': url
  //   }
  //   param.extract_rules = params;
  //   axios.get('https://app.scrapingbee.com/api/v1', {
  //     params: param
  //   }).then((response: any) => {
  //     if (response?.data) {
  //       callback(response?.data);
  //     } else {
  //       alert('please try again...')
  //     }
  //   });
  // }

  // getIp(callback: any) {
  //   axios.get('http://ip-api.com/json/', {
  //   }).then((response: any) => {
  //     if (response?.data) {
  //       callback(response?.data);
  //     } else {
  //       alert('please try again...')
  //     }
  //   });
  // }
}