import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { AddtaskComponent } from '../addtask/addtask.component';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { ApiService } from '../providers/api/api.service';
import { ViewtaskComponent } from '../viewtask/viewtask.component';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
declare var swal: any;
declare var $: any;

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit {
  selectedUser: any;
  isShow: boolean = false;
  // isAdmin: boolean = false;
  authUser: any = this.tokenStorage.getUser();
  taskList: any = [];
  projectList: any = [];
  taskStatusList: any = [];
  public type: string = 'list';
  initColumns: any[] = [
    { name: "Project", key: "project" },
    { name: "Tasks", key: "tasks" },
    { name: "Assigned To", key: "assignedTo" },
    { name: "Status", key: "status" },
    { name: "Start Date", key: "startDate" },
    { name: "Due Date", key: "dueDate" },
    { name: "Duration", key: "duration" },
    { name: "Action", key: "action" }
  ];
  columnsToDisplay = this.initColumns.map(col => col.key);
  @ViewChild('tasklist') taskListTable: MatTable<any>;
  isProjectAdmin: boolean = false;
  previewIndex: any;
  isShowLoading: boolean = false;
  selectedTask: any;

  currentSection = 'summary';
  limit: number = 20;
  skip: number = 0;
  public p: any;
  taskCount: number = 0;
  infiniteScrollDisabled: boolean = false;

  // Autocomplete User
  public userCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  userList: any = [];

  // Autocomplete Category
  public categoryCtrl: FormControl = new FormControl();
  public categoryFilterCtrl: FormControl = new FormControl();
  public filteredCategory: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public taskCtrl: FormControl = new FormControl();

  userType: any;
  countryList: any = [];
  allSelected: boolean = false;
  selectedCountry: any = [];
  @ViewChild('select') select: MatSelect;
  appMenuSno: any;


  constructor(
    private tokenStorage: TokenStorageService,
    private api: ApiService,
    public dialog: MatDialog
  ) {
    let index = this.authUser?.menus?.findIndex((menu) => menu?.routerLink == '/task');
    if (index != -1) {
      this.userType = this.authUser?.menus[index]?.userType;
      this.appMenuSno = this.authUser?.menus[index].appMenuSno;
    }
  }

  ngOnInit(): void {
    if (this.userType.isSuperAdmin || this.userType.isGlobalAdmin || this.userType.isAdmin || this.userType.isPageAdmin) {
      this.getCountry();
    } else {
      this.getMyProject();
    }

    this.getTaskStatusEnum();
    // this.getMyProject();
    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });

    this.categoryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCategory();
      });
  }

  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = '{' + this.authUser?.countries + '}';
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length) {
          $(document).ready(() => {
            this.allSelected = true;
            this.toggleAllSelection();
          });
        }
      } else {
      }
      // this.getMyProject();
    });
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.getUsers();
  }

  toggleAllSelection() {
    if (this.allSelected) {
      // this.select.options.forEach((item: MatOption) => item.select());
      this.selectedCountry = this.countryList.map((obj) => obj.countrySno);
    } else {
      // this.select.options.forEach((item: MatOption) => item.deselect());
      this.selectedCountry = [];
    }
    this.getUsers();
  }



  scrollTo(section) {
    this.currentSection = section;
    document.querySelector('#' + section).scrollIntoView({ behavior: 'smooth' });
  }

  getMyProject() {
    this.projectList = [];
    let params: any = {};
    if (this.userType.isSuperAdmin || this.userType.isGlobalAdmin || this.userType.isAdmin || this.userType.isPageAdmin) {
      params.isAdmin = true;

      if (this.selectedCountry && this.selectedCountry.length > 0) {
        params.countries = JSON.stringify(this.selectedCountry);
      }

      if (this.userCtrl.value?.length)
        params.userList = '{' + this.userCtrl.value + '}';
    }
    else
      params.appUserSno = this.authUser.appUserSno;

    this.api.get('8085/api/get_my_project', params).subscribe(result => {
      // console.log(result);
      if (result != null) {
        this.projectList = result?.data?.length ? result.data : [];
        this.filteredCategory.next(this.projectList.slice());
        // this.projectList.push({ projectName: 'All', projectSno: 0 });
        // for (let i in result.data) {
        //   this.projectList.push(result.data[i]);
        // }
      }
      this.taskList = [];
      this.getTask()
    })
  }

  // getData(event?: any) {
  //   // console.log(type);
  //   this.taskList = [];
  //   this.getMyProject();
  // }

  getTask() {
    if (!this.isShowLoading) {
      // this.taskList = [];
      this.isShowLoading = true;
      let params: any = {
        appUserSno: this.authUser.appUserSno,
        skip: this.taskList?.length ? this.taskList?.length : 0,
        limit: this.limit
      };
      // this.previewIndex = event?.value;
      // console.log(this.previewIndex);
      // if (this.previewIndex && this.previewIndex != 0) {
      //   params.projectSno = this.projectList[this.previewIndex]?.projectSno;
      //   this.isProjectAdmin = this.projectList[this.previewIndex]?.isAdmin;
      // } else {
      //   this.isProjectAdmin = false;
      // }
      // if (this.isAdmin) {
      //   params.isAdmin = this.isAdmin;
      // }

      if (this.userType.isSuperAdmin || this.userType.isGlobalAdmin || this.userType.isAdmin || this.userType.isPageAdmin) {
        params.isAdmin = true;
      }

      if (this.taskCtrl.value?.length) {
        params.taskList = '{' + this.taskCtrl.value + '}';
      }

      if (this.userCtrl.value?.length) {
        params.userList = '{' + this.userCtrl.value + '}';
      }

      if (this.categoryCtrl.value?.length) {
        params.categoryList = '{' + this.categoryCtrl.value + '}';
      }
      console.log(params);
      this.api.get('8085/api/get_my_task', params).subscribe(result => {
        this.infiniteScrollDisabled = false;
        this.isShowLoading = false;
        console.log(result);
        if (result != null) {
          if (result?.data) {
            for (let task of result?.data) {
              this.taskList.push(task);
              this.selectedProjectColor(task);
            }
          } else {

            // this.taskList = [];
            // this.infiniteScrollDisabled = true;
          }
        } else {
        }
      });
    }
  }

  addTask() {
    // if (!this.project?.taskList?.length) {
    //   this.project.taskList = [];
    // }
    let dialogRef = this.dialog.open(AddtaskComponent, {
      width: '600px',
      data: {
        pageName: "task"
        // project: this.project
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result)
      if (result) {
        // this.getTask({
        //   target: {
        //     value: this.previewIndex
        //   }
        // })
      }
    });
  }

  calculateDiff(startDate, dueDate) {
    startDate = new Date(startDate);
    dueDate = new Date(dueDate);
    if (dueDate >= startDate) {
      let duration: any = Math.floor((Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
      if (duration) {
        return duration;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  }

  public getTaskStatusEnum() {
    let param: any = { codeType: 'project_task_cd' };
    this.api.get('8082/api/get_enum_names', param).subscribe(result => {
      // console.log(result);
      if (result != null && result.data) {
        this.taskStatusList = result.data;
        // if (this.authUser.roleCdValue == 'Admin') {
        //   this.isAdmin = true;
        //   this.getTask();
        // } else {
        // this.getTask();
        // }
      } else {

      }
    });
  }

  updateTask(task: any, input: number) {
    let index: number = this.taskStatusList.findIndex(e => e.codesDtlSno == task.taskStatusCd);
    // console.log(index);
    if (index != null && index != undefined && index != -1) {
      // console.log(this.taskStatusList[index])
      task.colorStatus = this.taskStatusList[index].filter1;
    }
    // console.log(task)
    let body: any = {};
    body.updateArgumentObj = {
      task_status_cd: input
    };
    body.projectTaskSno = task?.projectTaskSno;
    body.projectSno = task.projectSno;
    this.api.put('8085/api/update_task', body).subscribe((result: any) => {
      // console.log(result);
      if (result?.data && result?.data?.projectTaskSno) {
      }
    });
  }

  deleteTask(element: any) {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      dangerMode: true,
      button: {
        text: "Delete",
        closeModal: true,
      }
    }).then(willDelete => {
      if (willDelete) {
        // console.log(element);
        if (element?.projectTaskSno) {
          let param: any = {};
          param.projectTaskSno = element.projectTaskSno;
          this.api.delete('8085/api/delete_my_project_task', param).subscribe((result: any) => {
            // console.log(result);
            let index: number = this.taskList.findIndex((task: any) => task.projectTaskSno == element.projectTaskSno);
            this.taskList?.splice(index, 1);
            this.taskListTable.renderRows();
          });
        } else {
          let index: number = this.taskList.findIndex((task: any) => task.projectTaskSno == element.projectTaskSno);
          this.taskList?.splice(index, 1);
          this.taskListTable.renderRows();
        }
      }
    });
  }

  editTask(task: any) {
    // console.log(task);
    let dialogRef = this.dialog.open(AddtaskComponent, {
      width: '600px',
      data: {
        pageName: 'task',
        task: {
          projectTaskSno: task.projectTaskSno,
          taskName: task.taskName,
          description: task.description,
          startDate: task.startDate,
          dueDate: task.dueDate,
          assignedAppUserSno: task.assignedAppUserSno,
          taskStatusCd: task.taskStatusCd,
          projectSubTask: []
        },
        projectSno: task.projectSno
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result)
      if (result) {
        // this.getTask({
        //   value: this.previewIndex
        // })
      }
    });
  }

  viewTask(task: any) {
    // console.log(task);
    let dialogRef = this.dialog.open(ViewtaskComponent, {
      width: '70%',
      height: '80%',
      data: {
        // project: this.project,
        task: task
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result)
    });
  }

  selectedProjectColor(projects) {
    let index: number = 0;
    for (let i in this.taskStatusList) {
      if (this.taskStatusList[i].codesDtlSno === projects.taskStatusCd) {
        index = parseInt(i);
        break;
      }
    }
    projects.colorStatus = this.taskStatusList[index]?.filter1;
  }

  viewTaskDetail(taskObj: any) {
    this.selectedTask = taskObj;
    this.isShow = true;
  }

  // getTaskCount() {
  //   this.isShowLoading = true;
  //   let param: any = {};
  //   if (this.authUser?.roleCdValue != 'Admin') {
  //     param.appUserSno = this.authUser?.appUserSno;
  //   }
  //   this.api.get("8085/api/get_task_count", param).subscribe((result) => {
  //     // console.log(result?.data)
  //     if (result != null && result.data?.length) {
  //       this.taskCount = result?.data[0]?.taskCount;
  //     }
  //     if (this.taskCount) {
  //       this.getTask(1);
  //     } else {
  //       this.isShowLoading = false;
  //     }
  //   });
  // }

  // onScroll() {
  //   console.log("onScroll");
  //   this.getTask();
  // }

  toggleSelectAllUsers(selectAllValue: boolean) {
    this.filteredUsers.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
      if (selectAllValue) {
        let selected: any = [];
        for (let i in val) {
          if (val[i]?.appUserSno)
            selected.push(val[i]?.appUserSno)
        }
        this.userCtrl.patchValue(selected);
      } else {
        this.userCtrl.patchValue([]);
      }
      this.getMyProject();
    });
  }

  public getUsers() {
    let params: any = { appMenuSno: this.appMenuSno };
    if (this.selectedCountry && this.selectedCountry.length > 0) {
      params.countries = '{' + this.selectedCountry + '}';
    }
    this.api.get('8082/api/get_user', params).subscribe(result => {
      if (result != null) {
        this.userList = result?.data?.length ? result.data : [];
        this.filteredUsers.next(this.userList.slice());
      } else {
      }
      this.getMyProject();
    });
  }

  protected filterUsers() {
    if (!this.userList) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.userList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsers.next(
      this.userList.filter(user => user.name?.toLowerCase().indexOf(search) > -1 || user.surname?.toLowerCase().indexOf(search) > -1)
    );
  }

  filterCategory() {
    if (!this.projectList) {
      return;
    }
    // get the search keyword
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategory.next(this.projectList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCategory.next(
      this.projectList.filter(project => project.projectName?.toLowerCase().indexOf(search) > -1)
    );
  }

  toggleSelectAllCategory(selectAllValue: boolean) {
    this.filteredCategory.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
      if (selectAllValue) {
        let selected: any = [];
        for (let i in val) {
          if (val[i]?.projectSno)
            selected.push(val[i]?.projectSno)
        }
        this.categoryCtrl.patchValue(selected);
      } else {
        this.categoryCtrl.patchValue([]);
      }
    });
  }
}
