<div class="d-flex justify-content-center align-items-center w-100 h-100 login-container">
    <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3">
        <div class="card border-0 shadow">
            <div class="card-header d-flex justify-content-center align-items-center border-0 box-shadow"
                style="background-color: #FFFFFF;">
                <img src="assets/img/logo/logo.png" width="130px" />
            </div>
            <div class="card-body text-center pb-1">
                <h2 class="text-primary font-weight-bold m-0">TOCUMULUS INTERNALS</h2>
                <p class="m-0 mt-2">-- Sign in With --</p>
                <form [formGroup]="form" class="text-left mt-3">
                    <div class="form-group text-center">
                        <button class="btn text-light btn-lg" style="font-size: 14px;" type="submit" (click)="connectAzure()">
                            <i class="fa fa-windows" aria-hidden="true"></i>&nbsp;&nbsp;Microsoft
                        </button>
                    </div>
                    <div class="p-2 text-center text-danger" *ngIf="isLoginFailed">
                        <p>Login Failed</p>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>