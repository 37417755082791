import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ReportTimesheetPipe', pure: false })
export class ReportTimesheetPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(user => {
        if (user?.CompanyName || user?.EmployeeName || user?.ProjectName) {
          return user.CompanyName.search(searchText) !== -1 || user.EmployeeName.search(searchText) !== -1 || user.ProjectName.search(searchText) !== -1;
        }
      });
    }
  }
}