import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ApiService } from '../providers/api/api.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  styleUrls: ['./account.component.scss'],
  standalone: true
})
export class AccountComponent implements OnInit {

  accountForm: any = [];
  isLoad: boolean = false;
  selectedIndex: any;
  accountList: any = [];

  constructor(private api: ApiService, public toastrService: ToastrService) {
    this.accountForm = new FormGroup({
      accountSno: new FormControl(null),
      accountName: new FormControl(null, Validators.required),
      activeFlag: new FormControl('true', Validators.required),
    });
  }

  ngOnInit(): void {
    this.getAccount();
  }

  save() {
    let body: any = {};
    body = this.accountForm.value;
    // console.log(body);
    this.isLoad = true;
    this.api.post('8089/api/insert_account', body).subscribe(result => {
      this.isLoad = false;
      if (result != null && result.data) {
        body.accountSno = result.data.accountSno;
        this.accountList.push(body);
        this.toastrService.success('Save Successfully');
        this.accountForm.reset();
        // this.location.back();
      } else {

      }
    });
  }


  edit(i: any) {
    this.selectedIndex = i;
    this.accountForm.setValue(this.accountList[i]);
  }

  update() {
    let body: any = {};
    body = this.accountForm.value;
    this.isLoad = true;
    this.api.put('8089/api/update_account', body).subscribe((result: any) => {
      this.isLoad = false;
      if (result != null && !result?.data?.message) {
        this.clear();
        this.accountList[this.selectedIndex].accountName = body.accountName;
        this.accountList[this.selectedIndex].activeFlag = body.activeFlag;
        this.toastrService.success('Updated Successfully');
      } else {
        this.toastrService.error(result?.data?.message);
      }
    }, err => {
      this.isLoad = false;
      this.toastrService.error(err)
    }
    );
  }

  getAccount() {
    let param: any = {};
    this.api.get("8089/api/get_account", param).subscribe(result => {
    // console.log(result)
      if (result != null) {
        if (result.data != null && result.data.length > 0) {
          this.accountList = result.data;
        } else {
        }
      } else {
        this.toastrService.error('Something went wrong');
      }
    }, err => {
      this.toastrService.error(err)
    }
    );
  }



  clear() {
    this.accountForm.reset();
  }

  delete(i: any) {
    let body: any = {
      accountSno: this.accountList[i].accountSno
    };
    this.api.delete('8089/api/delete_account', body).subscribe((result: any) => {
      if (result != null) {
        this.accountList.splice(i, 1);
        this.toastrService.success('Deleted Sucessfully');
      }
    }, err => {
      this.isLoad = false;
      this.toastrService.error(err)
    });
  }

  deleteAlert(i: any) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'lightgrey',
      confirmButtonText: 'Yes, delete it',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your record has been deleted.',
        //   'success'
        // )
        this.delete(i);
      }
      else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled!',
          'Your record is safe',
          'error'
        )
      }
    })
  }

}
