import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../providers/api/api.service';
import { TokenStorageService } from '../pages/login/token-storage.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReplaySubject, Subject, debounceTime, take, takeUntil } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from '../theme/directives/directives.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AddtaskComponent } from '../addtask/addtask.component';
import { MatDialog } from '@angular/material/dialog';
import { AddDealTaskComponent } from '../add-deal-task/add-deal-task.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PipesModule } from '../theme/pipes/pipes.module';
import { MatTable } from '@angular/material/table';
declare var swal: any;

@Component({
  selector: 'app-deal-task',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DirectivesModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    InfiniteScrollModule,
    PipesModule
  ],
  templateUrl: './deal-task.component.html',
  styleUrls: ['./deal-task.component.scss']
})
export class DealTaskComponent {


  authUser: any = this.tokenStorage.getUser();
  // Autocomplete User
  public userCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  // Autocomplete Client
  public clientCtrl: FormControl = new FormControl();
  public clientFilterCtrl: FormControl = new FormControl();
  public filteredClients: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();

  userType: any;
  countryList: any = [];
  selectedAppMenuSno: any;
  selectedUserSno: any;
  selectCountrySno: any;
  clients: any = [];
  users: any = [];

  searchKey = new FormControl('');
  isShowLoading: boolean = false;
  taskList: any = [];
  limit: number = 20;
  skip: number = 0;
  infiniteScrollDisabled: boolean = false;
  taskStatusList: any = [];
  isShow: boolean = false;
  selectedTask: any;
  public type: string = 'list';
  @ViewChild('tasklist') taskListTable: MatTable<any>;

  constructor(
    public api: ApiService,
    public toastrService: ToastrService,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    public dialog: MatDialog
  ) {
    this.searchKey.valueChanges.pipe(debounceTime(800)).subscribe(() => {
      this.change();
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(() => {
      let index = this.authUser?.menus?.findIndex((menu) => menu?.routerLink == '/deal_task');
      if (index != -1) {
        this.userType = this.authUser?.menus[index]?.userType;
        this.selectedAppMenuSno = this.authUser?.menus[index]?.appMenuSno
      }
      if (!this.userType?.isAdmin && !this.userType?.isPageAdmin && !this.userType?.isGlobalAdmin && !this.userType?.isSuperAdmin) {
        this.selectedUserSno = this.authUser?.appUserSno;
      }
      console.log(this.userType);
      this.getTaskStatusEnum();
    });
  }

  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = JSON.stringify(this.authUser?.countries);
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length) {
          this.selectCountrySno = this.countryList[0]?.countrySno;
        }
        this.taskList = [];
        this.getTask();
        this.getClients();
        if (this.userType?.isSuperAdmin || this.userType?.isGlobalAdmin || this.userType?.isAdmin || this.userType?.isPageAdmin) {
          this.getUsers();
        }
      } else {
      }
    });
  }

  getClients() {
    let params: any = {};
    params.countrySno = this.selectCountrySno;
    this.api.get('8084/api/get_search_client', params).subscribe(result => {
      // console.log(result);
      if (result != null) {
        this.clients = result?.data?.length ? result?.data : [];
        // this.clientCtrl.setValue(this.clients)
        this.filteredClients.next(this.clients?.slice());
        this.clientFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterclients();
          });
      }
    }, error => {
    });
  }

  filterclients() {
    if (!this.clients) {
      return;
    }
    let search = this.clientFilterCtrl.value;
    if (!search) {
      this.filteredClients.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredClients.next(
      this.clients.filter(client => client.companyName?.toLowerCase().indexOf(search) > -1)
    );
  }

  toggleSelectAllClient(selectAllValue: boolean) {
    this.filteredClients.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
      if (selectAllValue) {
        let selected: any = [];
        for (let i in val) {
          if (val[i]?.clientSno)
            selected.push(val[i]?.clientSno)
        }
        this.clientCtrl.patchValue(selected);
      } else {
        this.clientCtrl.patchValue([]);
      }
    });
  }

  public getUsers() {
    let params: any = {}
    params.countries = '{' + this.selectCountrySno + '}';
    params.appMenuSno = this.selectedAppMenuSno;
    this.api.get('8082/api/get_user', params).subscribe(result => {
      if (result != null) {
        this.users = result?.data?.length ? result?.data : [];
        this.filteredUsers.next(this.users.slice());
        this.userFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterUsers();
          });
      } else {
      }
    });
  }

  protected filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsers.next(
      this.users.filter(user => user.name?.toLowerCase().indexOf(search) > -1)
    );
  }
  toggleSelectAllUsers(selectAllValue: boolean) {
    this.filteredUsers.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
      console.log(val);
      if (selectAllValue) {
        let selected: any = [];
        for (let i in val) {
          if (val[i]?.appUserSno)
            selected.push(val[i]?.appUserSno)
        }
        this.userCtrl.patchValue(selected);
      } else {
        this.userCtrl.patchValue([]);
      }
    });
  }

  changeCountry() {
    this.userCtrl.patchValue([]);
    this.clientCtrl.patchValue([]);
    this.selectedUserSno = null;
    this.getUsers();
    this.getClients();
    this.change();
  }

  clearSearch() {
    this.searchKey.patchValue('');
  }

  addTask() {
    let dialogRef = this.dialog.open(AddDealTaskComponent, {
      width: '600px',
      data: {
        pageName: "task"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.change();
      }
    });
  }


  public getTaskStatusEnum() {
    let param: any = { codeType: 'project_task_cd' };
    this.api.get('8082/api/get_enum_names', param).subscribe(result => {
      this.getCountry();
      if (result != null && result.data) {
        this.taskStatusList = result.data;
      } else {
      }
    });
  }

  getTask() {
    if (!this.isShowLoading) {
      // this.taskList = [];
      this.isShowLoading = true;
      let params: any = {
        skip: this.taskList?.length ? this.taskList?.length : 0,
        limit: this.limit
      };

      if (this.userType.isSuperAdmin || this.userType.isGlobalAdmin || this.userType.isAdmin || this.userType.isPageAdmin) {
        params.isAdmin = true;
      }

      if (this.clientCtrl.value?.length) {
        params.clientList = '{' + this.clientCtrl.value + '}';
      }

      if (this.userCtrl.value?.length) {
        params.userList = '{' + this.userCtrl.value + '}';
      }

      if (!params?.isAdmin) {
        params.appUserSno = this.authUser.appUserSno;
      }
      params.countrySno = this.selectCountrySno;
      params.searchKey = this.api.searchKey(this.searchKey.value);
      this.api.get('8091/api/get_deal_task', params).subscribe(result => {
        this.infiniteScrollDisabled = false;
        console.log(result);
        if (result != null) {
          if (result?.data) {
            for (let task of result?.data) {
              this.taskList.push(task);
              this.selectedProjectColor(task);
            }
          }
        }
        this.isShowLoading = false;
      });
    }
  }

  selectedProjectColor(projects) {
    let index: number = 0;
    for (let i in this.taskStatusList) {
      if (this.taskStatusList[i].codesDtlSno === projects.taskStatusCd) {
        index = parseInt(i);
        break;
      }
    }
    projects.colorStatus = this.taskStatusList[index]?.filter1;
  }

  calculateDiff(startDate, dueDate) {
    startDate = new Date(startDate);
    dueDate = new Date(dueDate);
    if (dueDate >= startDate) {
      let duration: any = Math.floor((Date.UTC(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
      if (duration) {
        return duration;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  }

  updateTask(task: any, input: number) {
    let index: number = this.taskStatusList.findIndex(e => e.codesDtlSno == task.taskStatusCd);
    if (index != null && index != undefined && index != -1) {
      task.colorStatus = this.taskStatusList[index].filter1;
    }
    let body: any = {};
    body.updateArgumentObj = {
      task_status_cd: input
    };
    body.dealTaskSno = task?.dealTaskSno;
    console.log(body);
    this.api.put('8091/api/update_deal_task', body).subscribe((result: any) => {
      if (result?.data && result?.data?.dealTaskSno) {
      }
    });
  }

  editTask(task: any) {
    let dialogRef = this.dialog.open(AddDealTaskComponent, {
      width: '600px',
      data: {
        isUpdate: true,
        task: {
          countrySno: task.countrySno,
          dealSno: task.dealSno,
          dealTaskSno: task.dealTaskSno,
          taskName: task.taskName,
          description: task.description,
          startDate: task.startDate,
          dueDate: task.dueDate,
          assignedAppUserSno: task.assignedAppUserSno,
          taskStatusCd: task.taskStatusCd
        },
        dealSno: task.dealSno
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        task.taskName = result.taskName;
        task.description = result.description;
        task.startDate = result.startDate;
        task.dueDate = result.dueDate;
        task.assignedAppUserSno = result.assignedAppUserSno;
        task.taskStatusCd = result.taskStatusCd;
        this.selectedProjectColor(task);
      }
    });
  }

  change() {
    this.taskList = [];
    this.getTask();
  }

  viewTaskDetail(taskObj: any) {
    this.selectedTask = taskObj;
    this.isShow = true;
  }

  deleteTask(element: any) {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      dangerMode: true,
      button: {
        text: "Delete",
        closeModal: true,
      }
    }).then(willDelete => {
      if (willDelete) {
        this.selectedTask = null;
        this.isShow = false;
        if (element?.dealTaskSno) {
          let param: any = {};
          param.dealTaskSno = element.dealTaskSno;
          this.api.delete('8091/api/delete_deal_task', param).subscribe((result: any) => {
            let index: number = this.taskList.findIndex((task: any) => task.dealTaskSno == element.dealTaskSno);
            this.taskList?.splice(index, 1);
            // this.taskListTable.renderRows();
          });
        } else {
          let index: number = this.taskList.findIndex((task: any) => task.dealTaskSno == element.dealTaskSno);
          this.taskList?.splice(index, 1);
          // this.taskListTable.renderRows();
        }
      }
    });
  }
}