import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TokenStorageService } from 'src/app/pages/login/token-storage.service';
import { ApiService } from 'src/app/providers/api/api.service';
import { USER_KEY } from 'src/util/constants';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  profileObj: any;
  appUserSno = JSON.parse(window.sessionStorage.getItem(USER_KEY)).appUserSno

  constructor(private api: ApiService,
    private tokenStorage: TokenStorageService
    ) { }

  ngOnInit() {
    this.getProfile();
  }
  getProfile() {
    let param = { appUserSno: this.appUserSno }
    this.api.get('8082/api/get_user_profile', param).subscribe(result => {
      if (result != null && result) {
        this.profileObj = result;
        let user = this.tokenStorage.getUser();
        user.profile = this.profileObj;
        user.countries = this.profileObj?.countries;
        delete user?.profile?.countries;
        this.tokenStorage.saveUser(user);
        // console.log(this.profileObj)
      }
    })
  }
}
