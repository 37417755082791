
<div class="row my-2 justify-content-end p-2">
    <div class="col-md-2 col-sm-12"
        *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin">

        <mat-form-field appearance="outline" class="ml-2"
            *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin">
            <mat-label>Select Region</mat-label>
            <mat-select #select multiple [(ngModel)]="selectedCountry" (ngModelChange)="getUsers()">
                <div class="select-all">
                    <mat-checkbox [(ngModel)]="allSelected" (click)="toggleAllSelection()">Select
                        All</mat-checkbox>
                </div>
                <mat-option *ngFor="let country of countryList;let i = index;" (click)="optionClick()"
                    [value]="country.countrySno">
                    {{country?.countryShortCode}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-md-2 col-sm-12"
        *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin">
        <mat-form-field appearance="outline" class="search-form-field">
            <mat-label>Select User</mat-label>
            <mat-select [formControl]="userCtrl" [multiple]="true" (selectionChange)="getMyProject()">
                <mat-option>
                    <ngx-mat-select-search [formControl]="userFilterCtrl" placeholderLabel="Find user..."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="false"
                        [toggleAllCheckboxChecked]="false" (toggleAll)="toggleSelectAllUsers($event)">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let user of filteredUsers | async "
                    style="border-bottom:1px solid #ced4da !important;" [value]="user?.appUserSno">
                    {{ user?.surname}} {{ user?.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-2 col-sm-12">
        <mat-form-field appearance="outline" class="search-form-field">
            <mat-label>Select Project</mat-label>
            <mat-select [formControl]="categoryCtrl" (selectionChange)="getTask()" [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="categoryFilterCtrl" placeholderLabel="Find project..."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="false"
                        [toggleAllCheckboxChecked]="false" (toggleAll)="toggleSelectAllCategory($event)">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let project of filteredCategory | async;" [value]="project?.projectSno">
                    {{project?.projectName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-2 col-sm-12">
        <mat-form-field appearance="outline" class="search-form-field">
            <mat-label>Select Task Status</mat-label>
            <mat-select [multiple]="true" [formControl]="taskCtrl" (selectionChange)="getTask()">
                <mat-option class="option_bg" *ngFor="let project of taskStatusList; let i = index;"
                    [value]="project?.codesDtlSno">
                    {{project?.cdValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-2 col-sm-12">
        <button mat-raised-button color="primary" class="p-4 ml-3" (click)="addTask()">
            <mat-icon>add</mat-icon>&nbsp;Add Task
        </button>
    </div>
</div>
<div *ngIf="!taskList?.length && !isShowLoading" class="text-center">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div *ngIf="isShowLoading" class="text-center">
    <img src="assets/gif/loading.gif" class="position-absolute top-50 start-50" width="110px" />
</div>

<div class="row p-2">
    <div [class]="isShow ? 'col-12 col-sm-12 col-md-6 col-lg-4  overflow-auto vh-100' : 'col-12' ">
        <div *ngIf="type=='list' && taskList?.length" class="row">
            <div class="col-12">
                <div class="table-responsive bg-white box-shadow search-table-outter">
                    <table class="table">
                        <thead>
                            <tr class="bg-primary text-white">
                                <th class="text-center">#</th>
                                <th>Project</th>
                                <th>Tasks</th>
                                <th *ngIf="!isShow">Assigned To</th>
                                <th *ngIf="!isShow">Start Date</th>
                                <th *ngIf="!isShow">Due Date</th>
                                <th *ngIf="!isShow">Duration</th>
                                <th *ngIf="!isShow">Status</th>
                                <th *ngIf="!isShow">Action</th>
                            </tr>
                        </thead>
                        <tbody class="table-hover" infiniteScroll [infiniteScrollDistance]="2"
                            [infiniteScrollThrottle]="50" (scrolled)="getTask()"
                            [infiniteScrollDisabled]="infiniteScrollDisabled">
                            <tr *ngFor="let task of taskList ;let i =index;" class="pointer"
                                [class]="task?.projectTaskSno == selectedTask?.projectTaskSno && isShow  ? 'bg-light' : ''">
                                <td (click)="viewTaskDetail(task)" class="text-center">{{i+1}}</td>
                                <td class="align-middle" (click)="viewTaskDetail(task)"
                                    [class]="task?.projectTaskSno == selectedTask?.projectTaskSno && isShow  ? 'text-dark fw-bold' : 'text-primary'">
                                    {{task?.projectName}}
                                </td>
                                <td class="align-middle " (click)="viewTaskDetail(task)"
                                    [class]="task?.projectTaskSno == selectedTask?.projectTaskSno && isShow  ? 'text-dark fw-bold' : 'text-primary'">
                                    {{task?.taskName}}
                                </td>
                                <td class="align-middle text-truncate" *ngIf="!isShow" (click)="viewTaskDetail(task)">
                                    {{ task?.name ? (task?.name ?? '-----') : '-----'}}
                                </td>
                                <td class="align-middle text-truncate" *ngIf="!isShow" (click)="viewTaskDetail(task)">
                                    {{ task?.startDate ? (task?.startDate | date : 'dd-MM-y' ?? '-----') : '-----'}}
                                </td>
                                <td class="align-middle text-truncate" *ngIf="!isShow" (click)="viewTaskDetail(task)">
                                    {{ task?.dueDate ? (task?.dueDate | date : 'dd-MM-y' ?? '-----') : '-----'}}
                                </td>
                                <td class="align-middle text-truncate" *ngIf="!isShow" (click)="viewTaskDetail(task)">
                                    {{calculateDiff(task?.startDate,task?.dueDate)}} day
                                </td>
                                <td class="align-middle text-truncate" *ngIf="!isShow">
                                    <select [(ngModel)]="task.taskStatusCd" *ngIf="task?.taskStatusCd"
                                        class="form-control rounded-pill text-center text-white"
                                        [disabled]="userType?.isEmployee && !userType?.isPageAdmin"
                                        (change)="updateTask(task,task?.taskStatusCd)"
                                        [style.background]="task.colorStatus">
                                        <option class="option_bg" *ngFor="let project of taskStatusList; let i = index;"
                                            [value]="project?.codesDtlSno">
                                            {{project?.cdValue}}
                                        </option>
                                    </select>
                                    <span *ngIf="!task?.taskStatusCd">-----</span>
                                </td>
                                <td class="align-middle text-truncate" *ngIf="!isShow">
                                    <div class="btn-group" role="group">
                                        <button class="btn btn-info btn-sm" (click)="editTask(task)"
                                            *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin || task?.isAdmin">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="w-100 text-center mt-5 mb-5" *ngIf="isShowLoading && taskList?.length">
            <div class="spinner-border text-primary m-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
    </div>
    <div [class]="isShow ? 'col-8 col-sm-12 col-md-6 col-lg-8' : 'd-none'">
        <div class="container">
            <div class="row bg-white p-4">
                <div class="col-12 text-end">
                    <button class="btn" (click)="editTask(selectedTask)"
                        *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin || selectedTask?.isAdmin">
                        <!-- <i class="fa fa-pencil"></i> -->
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button class="btn" (click)="deleteTask(selectedTask)"
                        *ngIf="userType?.isSuperAdmin || userType?.isGlobalAdmin || userType?.isAdmin || userType?.isPageAdmin  || selectedTask?.isAdmin">
                        <!-- <i class="fa fa-trash"></i> -->
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button class="btn" (click)="isShow=false">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="col-6">
                    <h3 class="fw-bold mb-2">Task Details</h3>
                    <div class="mb-2">
                        <div class="row">
                            <div class="col-6 text-right">
                                <label class="text-gray">project Name</label>
                            </div>
                            <div class="col-6 fw-bold text-capitalize">
                                {{selectedTask?.projectName}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 text-right">
                                <label class="text-gray">Task Name</label>
                            </div>
                            <div class="col-6 fw-bold text-capitalize">
                                {{selectedTask?.taskName}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 text-right">
                                <label class="text-gray">Assigned To</label>
                            </div>
                            <div class="col-6 fw-bold text-capitalize">
                                {{selectedTask?.name}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6 text-right">
                                <label class="text-gray">Status </label>
                            </div>
                            <div class="col-6 fw-bold text-capitalize">
                                <select [(ngModel)]="selectedTask.taskStatusCd" *ngIf="selectedTask?.taskStatusCd"
                                    class="form-control rounded-pill text-center w-75 text-white"
                                    [disabled]="userType?.isEmployee && !userType?.isPageAdmin"
                                    (change)="updateTask(selectedTask,selectedTask?.taskStatusCd)"
                                    [style.background]="selectedTask.colorStatus">
                                    <option class="option_bg" *ngFor="let project of taskStatusList; let i = index;"
                                        [value]="project?.codesDtlSno">
                                        {{project?.cdValue}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6 text-right">
                                <label class="text-gray">Start Date </label>
                            </div>
                            <div class="col-6 fw-bold text-capitalize">
                                {{ selectedTask?.startDate ? (selectedTask?.startDate | date :
                                'dd-MM-y' ?? '-----') : '-----'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 text-right">
                                <label class="text-gray">Due Date </label>
                            </div>
                            <div class="col-6 fw-bold text-capitalize">
                                {{ selectedTask?.dueDate ? (selectedTask?.dueDate | date :
                                'dd-MM-y'
                                ?? '-----') : '-----'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 text-right">
                                <label class="text-gray">Duration </label>
                            </div>
                            <div class="col-6 fw-bold text-capitalize">
                                {{calculateDiff(selectedTask?.startDate,selectedTask?.dueDate)}} day
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>