import { Inject, Injectable } from "@angular/core";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, PopupRequest } from "@azure/msal-browser";
import * as moment from "moment";
import { filter } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class azureService {

  token:any;
  
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ){
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      // console.log(result);
      this.token = result;
      localStorage.setItem('oauth_token', JSON.stringify(this.token?.payload))
      const payload = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(payload.account);
    });
  }

  connectAzure(callback: any) {
    let oauth_token: any = localStorage.getItem('oauth_token')
    let isafter: any;

    if (oauth_token) {
      oauth_token = JSON.parse(oauth_token);
      let currentTime: any = moment(new Date());
      isafter = moment(oauth_token.expiresOn).isAfter(currentTime);
      // alert(moment(oauth_token.expiresOn))
    }

    if(isafter){
      callback(oauth_token);
    }else{
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            // console.log(response.account)
            callback(this.token?.payload)
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            // console.log(response.account)
            localStorage.setItem('oauth_token', JSON.stringify(this.token?.payload))
            callback(this.token?.payload)
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    }
  }

  logOut(){
    this.authService.logout().subscribe((res:any)=>{
      
    });
  }

}
