<!-- <div class="row mt-4 ">
    <div class="col-2">
        <h3 class="font-weight-bold">Time sheet Approval</h3>
    </div>
    <div class="col-2 text-right">
        <div class="form-group mb-0 pb-1">
            <div class="input-group">
                <span class="input-group-prepend">
                    <span class="input-group-text bg-transparent border"><i class="fa fa-search ml-2 mr-1"></i></span>
                </span>
                <input class="form-control border" type="text" [(ngModel)]="searchText"
                    placeholder="Search employee...">
            </div>
        </div>
    </div>
    <div class="col-2 text-right">
        <select class="form-control fa-select  mb-4" [(ngModel)]="selectProject" (change)="getProjectTimesheet()">
            <option [value]="project.projectSno" *ngFor="let project of projectList">
                {{project.projectName}}
            </option>
        </select>
    </div>
    <div class="col-6 text-right">
        <div class="btn-group mb-4 border " role="group" aria-label="Basic example">
            <input type="week" class="form-control text-center" [(ngModel)]="selectWeek" (ngModelChange)="changeWeek()"
                id="input-14">
        </div>
        <div class="btn-group mb-4 border " role="group" aria-label="Basic example">
            <button type="button" class="btn btn-light border-left" (click)="previousWeek()"><i
                    class="fa fa-chevron-left mr-2"></i></button>
            <button type="button" class="btn btn-light border-left" (click)="nextWeek()"><i
                    class="fa fa-chevron-right mr-2"></i></button>
            <button type="button" class="btn btn-light border-left">{{displayDate}}</button>
        </div>
    </div>
</div> -->

<div class="row mt-4 ">

    <div class="col-12 text-right d-flex">
        <!-- <div class="form-group mb-0 pb-1">
            <div class="input-group">
                <span class="input-group-prepend">
                    <span class="input-group-text bg-transparent border"><i class="fa fa-search ml-2 mr-1"></i></span>
                </span>
                <input class="form-control border" type="text" [(ngModel)]="searchText"
                    placeholder="Search employee...">
            </div>
        </div>
        <select class="form-control fa-select mb-4" [(ngModel)]="selectProject" (change)="getProjectTimesheet()">
            <option [value]="project.projectSno" *ngFor="let project of projectList">
                {{project.projectName}}
            </option>
        </select> -->
        <div class="w-25 ms-auto">
            <div class="input-group">
                <div class="input-group-prepend mx-auto" style="width:50px">
                    <span class="input-group-text  text-center" style="width:50px"><i
                            class="fa fa-search mx-auto"></i></span>
                </div>
                <input [(ngModel)]="searchText" type="text" style="height:42px" class="w-25"
                    placeholder="Search employee..." class="form-control border-1 ">
            </div>
        </div>
        <mat-form-field appearance="outline" class="mx-2">
            <mat-label>Select Region</mat-label>
            <mat-select #select multiple [(ngModel)]="selectedCountry">
                <div class="select-all">
                    <mat-checkbox [(ngModel)]="allSelected" (click)="toggleAllSelection()">Select
                        All</mat-checkbox>
                </div>
                <mat-option *ngFor="let country of countryList;let i = index;" (click)="optionClick()"
                    [value]="country.countrySno">
                    {{country?.countryShortCode}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="ml-2">
            <mat-label>Select Project</mat-label>
            <mat-select [(ngModel)]="selectProject" [formControl]="projectCtrl" placeholder="Project" [multiple]="false"
            (ngModelChange)="getProjectTimesheet()" #singleSelect>
                <mat-option>
                    <ngx-mat-select-search [formControl]="projectFilterCtrl" placeholderLabel="Find project..."
                        noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                        toggleAllCheckboxTooltipMessage="Select / Unselect All"
                        [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                        (toggleAll)="toggleSelectAll($event)">
                        <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let project of filteredProjects | async "
                    style="border-bottom:1px solid #ced4da !important;" [value]="project.projectSno">
                    {{ project.projectName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="btn-group mb-4 border-0 ml-2" role="group" aria-label="Basic example">
            <input type="week" class="form-control text-center" [(ngModel)]="selectWeek" (ngModelChange)="changeWeek()"
                id="input-14">
        </div>
        <div class="btn-group mb-4 border mx-2" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-light ">{{displayDate}}</button>
            <button type="button" class="btn btn-info border-left" (click)="previousWeek()"><i
                    class="fa fa-chevron-left mr-2"></i></button>
            <button type="button" class="btn btn-info border-left" (click)="nextWeek()"><i
                    class="fa fa-chevron-right mr-2"></i></button>
        </div>
    </div>
</div>


<div class="row mb-5 p-3">
    <div class="col-12">
        <div widget class="card border-0 box-shadow m-0 ">
            <div class="card-header bg-primary text-light transparent border-0 text-muted p-0 m-0 ">

                <div class="row p-2 m-0">
                    <div class="col-3 text-light">
                        Employees
                    </div>
                    <div class="col-xl-1 col-md-1 col-sm-12  text-center text-light" *ngFor="let date of dateList">
                        {{date.displayDate}}
                    </div>
                    <div class="col-xl-1 col-md-1 col-sm-12  text-center text-light">
                        Total
                    </div>
                    <div class="col-xl-1 col-md-1 col-sm-12  text-center text-light">
                        Actions
                    </div>
                </div>
                <div class="row bg-light p-2 m-0 border-bottom timesheet_approval_hover "
                    *ngFor="let timesheet of timesheetList | TimesheetEmployeeSearchPipe : searchText;let i = index">
                    <div class="col-3 m-auto">
                        {{timesheet.name}}
                    </div>
                    <div class="col-xl-1 col-md-1 col-sm-12  text-center m-auto"
                        *ngFor="let time of timesheet?.timesheet?.workingTime;let j =index;">

                        <div class="mt-1 mb-1"
                            [class]="time.workedStatus == 'approved' ? 'text-success' : time.workedStatus == 'rejected' ? 'text-danger' : 'text-dark'">
                            {{time.workedTime}}
                        </div>

                        <div class="btn-group mb-4 border  " role="group" aria-label="Basic example"
                            *ngIf="time.workedStatus == 'created' ||  time.workedStatus == 'edited'">
                            <button type="button" class="btn btn-light border-left"
                                (click)="selectedIndexI = i;selectedIndexJ = j;updateTimeSheet('approved')"><i
                                    class="fa fa-check  mr-2 text-success"></i></button>
                            <button type="button" class="btn btn-light border-left" href="javascript:void(0);"
                                data-toggle="modal" (click)="selectedIndexI = i;selectedIndexJ = j"
                                [attr.data-target]='"#error-modal-"+ i +"-"+j'><i
                                    class="fa fa-close mr-2 text-danger"></i></button>
                        </div>

                        <div class="modal fade" id="error-modal-{{i}}-{{j}}" role="dialog"
                            aria-labelledby="modal-error-label">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header state modal-danger">
                                        <h4 class="modal-title" id="modal-error-label"><i
                                                class="fa fa-warning"></i>Reject Reason</h4>
                                        <button type="button" class="close text-white" data-dismiss="modal"
                                            aria-label="Close"><span aria-hidden="true">×</span></button>
                                    </div>
                                    <div class="modal-body">
                                        <textarea placeholder="max 200 characters" maxlength="200" rows="4" cols="50"
                                            [(ngModel)]="time.comments" autofocus></textarea>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal"
                                            (click)="updateTimeSheet('rejected')">Reject</button>
                                        <button type="button" class="btn btn-default"
                                            data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xl-1 col-md-1 col-sm-12  text-center m-auto">
                        {{timesheet?.timesheet?.total}}
                    </div>
                    <div class="col-xl-1 col-md-1 col-sm-12  text-center m-auto">
                        <div class="btn-group  border " role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-light border-left"
                                [disabled]="!timesheet?.timesheet?.isDisabled"
                                (click)="selectedIndexI = i;selectedIndexJ = null;updateTimeSheet('approved')"><i
                                    class="fa fa-check  mr-2 text-success"></i></button>
                            <button type="button" class="btn btn-light border-left"
                                [disabled]="!timesheet?.timesheet?.isDisabled" href="javascript:void(0);"
                                data-toggle="modal" (click)="selectedIndexI = i;selectedIndexJ = null"
                                data-target="#all-modal"><i class="fa fa-close mr-2 text-danger"></i></button>
                        </div>


                        <div class="modal fade" id="all-modal" role="dialog" aria-labelledby="modal-error-label">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header state modal-danger">
                                        <h4 class="modal-title" id="modal-error-label"><i
                                                class="fa fa-warning"></i>Reject Reason</h4>
                                        <button type="button" class="close text-white" data-dismiss="modal"
                                            aria-label="Close"><span aria-hidden="true">×</span></button>
                                    </div>
                                    <div class="modal-body">
                                        <textarea placeholder="max 200 characters" maxlength="200" rows="4" cols="50"
                                            [(ngModel)]="comments" autofocus></textarea>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal"
                                            (click)="updateTimeSheet('rejected')">Reject</button>
                                        <button type="button" class="btn btn-default"
                                            data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>