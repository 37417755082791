<form class="ml-2" [formGroup]="expenseForm">
    <div class="row">
        <div class="col-6">
            <!-- <div class="row">
                <div class="col-12">
                    <h5>Record Expense</h5>
                </div>
            </div> -->
            <!-- <div class="form-group">
                <label class="text-gray">Date *</label>
                <input class="form-control" formControlName="date" type="date"
                    [class.is-invalid]="expenseForm.get('date').invalid && expenseForm.get('date').touched">
                <div *ngIf="expenseForm?.controls['date']?.touched && expenseForm?.controls['date']?.errors?.required"
                    class="text-danger">
                    Consumer Name is required.
                </div>
            </div> -->
            <div class="form-group">
                <label class="text-gray">Organization Region <span class="text-danger">*</span></label>
                <mat-form-field appearance="outline" class="search-form-field">
                    <mat-select formControlName="countrySno" placeholder="Select region"
                        (selectionChange)="getEnumPaidThrough();getAccount();">
                        <mat-option *ngFor="let country of countryList;" [value]="country?.countrySno">
                            {{country?.countryShortCode}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <label class="text-gray">Date <span class="text-danger">*</span></label>
                <div class="form-group">
                    <input class="form-control bg-white" type="date" formControlName="date">
                </div>
            </div>
            <div class="form-group">
                <label class="text-gray">Expense Account <span class="text-danger">*</span></label>
                <mat-form-field appearance="outline" class="search-form-field">
                    <mat-select formControlName="accountSno" placeholder="Select expense account">
                        <mat-option *ngFor="let account of accountList;" [value]="account?.accountSno">
                            {{account.accountName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="form-group">
                <label class="me-5">Expense Type *</label>
                <div class="form-check form-check-inline">
                    <label class="form-check-label" *ngFor="let type of expenseTypeList;" style="margin-right: 21px;">
                        <input class="form-check-input" formControlName="expenseTypeCd" type="radio"
                            [value]="type?.codesDtlSno"
                            (click)=" type?.cdValue=='Goods' ?  expenseValue='Goods': expenseValue='Services'">{{type?.cdValue}}
                    </label>
                </div>
            </div> -->
            <!-- <div class="form-group row" *ngIf="expenseValue=='Goods' && expenseValue!='Services'">
                <div class="col-md-2">
                    <p class="text-right">HSN code</p>
                </div>
                <div class="col-md-10">
                    <div class="input-group">
                        <input formControlName="hsnCode" class="form-control" type="number">
                    </div>
                </div>
                <div *ngIf="expenseForm?.controls['hsnCode']?.touched && expenseForm?.controls['hsnCode']?.errors?.required"
                    class="text-danger">
                    Consumer Name is required.
                </div>
            </div>
            <div class="form-group row" *ngIf="expenseValue=='Services' && expenseValue!='Goods'">
                <div class="col-md-2">
                    <p class="text-right">SAC</p>
                </div>
                <div class="col-md-10">
                    <div class="input-group">
                        <input formControlName="sacCode" class="form-control" type="number">
                    </div>
                </div>
                <div *ngIf="expenseForm?.controls['sacCode']?.touched && expenseForm?.controls['sacCode']?.errors?.required"
                    class="text-danger">
                    Consumer Name is required.
                </div>
            </div> -->
            <div class="form-group">
                <label class="text-gray">Amount <span class="text-danger">*</span></label>
                <div class="input-group">
                    <!-- <span class="input-group-text" id="basic-addon1">
                        <select class="bg-none bg-transparent border-0" formControlName="countrySno">
                            <option *ngFor="let country of countryList;" [value]="country?.countrySno">
                                {{country?.countryShortCode}}</option>
                        </select>
                    </span> -->
                    <input type="number" class="form-control w-100" placeholder="Enter amount" aria-label="Username"
                        aria-describedby="basic-addon1" formControlName="amount"
                        [class.is-invalid]="expenseForm.get('amount').invalid && expenseForm.get('amount').touched">
                    <small class="text-danger"
                        [class.d-none]="expenseForm.get('amount').valid || expenseForm.get('amount').untouched">Amount
                        is required
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label class="text-gray">Paid Through <span class="text-danger">*</span></label>
                <mat-form-field appearance="outline" class="search-form-field">
                    <mat-select formControlName="paidThroughCd" placeholder="Select paid through">
                        <mat-option [value]="paid.codesDtlSno" *ngFor="let paid of paidList;">
                            {{paid.cdValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="form-group">
                <label class="text-gray">Vendor</label>
                <select class="form-control fa-select" formControlName="vendorSno">
                    <option *ngFor="let vendor of vendorList;let i index" [value]="vendor?.vendorSno">
                        {{vendor.vendorName}}</option>
                </select>
            </div> -->
            <!-- <div class="form-group">
                <label class="text-gray">GST Treatment *</label>
                <select class="form-control fa-select" formControlName="gstTreatmentCd">
                    <option [value]="gst.codesDtlSno" *ngFor="let gst of gstList;">
                        {{gst.cdValue}}</option>
                </select>
            </div> -->
            <!-- <div class="form-group">
                <label class="text-gray">Source Of Supply *</label>
                <select class="form-control fa-select" formControlName="sourceOfSupplyCd">
                    <option *ngFor="let source of stateList;" [value]="source?.stateSno">
                        {{source.stateName}}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="text-gray">Destination Of Supply *
                </label>
                <select class="form-control fa-select" formControlName="destinationOfSupplyCd">
                    <option *ngFor="let destination of stateList;" [value]="destination?.stateSno">
                        {{destination.stateName}}</option>
                </select>
            </div> -->
            <!-- <div class="form-group">
                <label class="text-gray">Reverse Charge</label>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                        formControlName="reverseCharge">
                    <label class="form-check-label" for="flexCheckDefault">
                        This transaction is applicable for reverse charge
                    </label>
                </div>
            </div> -->
            <!-- <div class="form-group">
                <label class="text-gray">Tax
                </label>
                <select class="form-control fa-select" formControlName="taxCd">
                    <option *ngFor="let tax of taxList;" [value]="tax.codesDtlSno">
                        {{tax.cdValue}}</option>
                </select>
            </div> -->
            <div class="form-group">
                <label class="text-gray">Invoice# <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                    <input formControlName="invoice" class="form-control w-100" type="text"
                        placeholder="Enter invoice number"
                        [class.is-invalid]="expenseForm.get('invoice').invalid && expenseForm.get('invoice').touched">
                    <small class="text-danger"
                        [class.d-none]="expenseForm.get('invoice').valid || expenseForm.get('invoice').untouched">Invoice
                        is required
                    </small>
                </div>
                <!-- <div>
                    <small class="text-danger"
                        [class.d-none]="expenseForm.get('invoice').valid || expenseForm.get('invoice').untouched">invoice
                        is required
                    </small>
                </div> -->
            </div>
            <div class="form-group">
                <label for="exampleFormControlTextarea1" class="form-label text-gray">Notes <span
                        class="text-danger">*</span></label>
                <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="notes"
                    placeholder="Max 500 characters"
                    [class.is-invalid]="expenseForm.get('notes').invalid && expenseForm.get('notes').touched"></textarea>
                <small class="text-danger"
                    [class.d-none]="expenseForm.get('notes').valid || expenseForm.get('notes').untouched">Notes
                    is required
                </small>
            </div>
            <!-- <div class="form-group">
                <label class="text-gray">Customer Name
                </label>
                <select class="form-control fa-select" formControlName="customerSno">
                    <option *ngFor="let client of clientList;" [value]="client.clientSno">
                        {{client.companyName}}</option>
                </select>
            </div> -->

        </div>
        <div class="col-5 align-self-start mt-5">
            <div class="card card-border pointer py-3">
                <div class="form-group col-lg-6 col-md-6 mx-auto">
                    <h4 class="form-control-label text-center fw-bold text-secondary">Upload your receipts <span
                            class="text-danger">*</span></h4>
                    <div class="file-upload d-flex" *ngIf="!expenseForm.value.media?.length">
                        <input type="file" id="expense_media" (change)="fileChange(input)" #input
                            class="file-upload-btn" />
                        <input type="text" class="form-control" placeholder="Choose a file...">
                        <span class="input-group-append">
                            <button class="btn btn-secondary" type="button">
                                <i class="fa fa-upload"></i></button>
                        </span>
                    </div>
                    <div *ngIf="expenseForm.value.media?.length" class="text-center" (click)="removeMedia()">
                        {{expenseForm.value.media[0]?.fileName}}
                        <i class="fa fa-close"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ms-0 m-3 p-0">
            <div class="col-12 p-0">
                <button type="button" class="btn btn-success  mb-1 mr-3" *ngIf="!expenseForm.value.expenseSno"
                    (click)="saveExpense()" [disabled]="expenseForm.invalid || isLoading"><span
                        class="spinner-border spinner-border-sm" *ngIf="isLoading"></span>
                    &nbsp;&nbsp;Save</button>
                <button type="button" class="btn btn-success  mb-1 mr-3" *ngIf="expenseForm.value.expenseSno"
                    (click)="updateExpense()" [disabled]="expenseForm.invalid || isLoading"><span
                        class="spinner-border spinner-border-sm" *ngIf="isLoading"></span>
                    &nbsp;&nbsp;Update</button>
                <button type="button" class="btn btn-outline-secondary  mb-1 mr-3" (click)="back()">Cancel</button>
            </div>
        </div>
    </div>
</form>