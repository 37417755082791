import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfilePicturePipe } from './profilePicture/profilePicture.pipe';
import { ChatPersonSearchPipe } from './search/chat-person-search.pipe';
import { UserSearchPipe } from './search/user-search.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { MailSearchPipe } from './search/mail-search.pipe';
import { ProjectSearchPipe } from './search/project-search.pipe';
import { ContractSearchPipe } from './search/contract-search.pipe';
import { TimesheetEmployeeSearchPipe } from './search/timesheet-employee-search.pipe';
import { ReportTimesheetPipe } from './search/report-timesheet.pipe';
import { DateAgoPipe } from './dateAgo/date-ago.pipe';
import { LengthPipe } from './length/length.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        ProjectSearchPipe,
        ContractSearchPipe,
        TimesheetEmployeeSearchPipe,
        ReportTimesheetPipe,
        DateAgoPipe,
        LengthPipe,
    ],
    exports: [
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        ProjectSearchPipe,
        ContractSearchPipe,
        TimesheetEmployeeSearchPipe,
        ReportTimesheetPipe,
        DateAgoPipe,
        LengthPipe,
    ]
})
export class PipesModule { }
