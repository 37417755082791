<div id="messagesDropDown" class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="messages" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" (click)="getNotification()">
        <i class="bi bi-bell"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 p-0 mt-3 box-shadow" aria-labelledby="messages">
        <div class="clearfix">
            <ul id="messagesTabs" class="nav nav-tabs nav-justified border-0">
                <li class="nav-item">
                    <a class="nav-link active transition" data-toggle="tab" href="#comments"><i class="fa fa-comment-o"
                            aria-hidden="true"></i></a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link transition" data-toggle="tab" href="#files"><i class="fa fa-file"
                            aria-hidden="true"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link transition" data-toggle="tab" href="#meetings"><i class="fa fa-calendar-check-o"
                            aria-hidden="true"></i></a>
                </li> -->
            </ul>
        </div>
        <div class="tab-content" perfectScrollbar>
            <div class="tab-pane active" id="comments">
                <div class="list">
                    <a *ngFor="let message of messages;let i =index;" href="javascript:void(0);" class="transition"
                        (click)="viewNotification(message.routerLink,modalContent,i)">
                        <img [src]="message.image" onerror="this.src='assets/img/logo/logo2.png';">
                        <div class="list-content">
                            <h3>{{message.title}} <span class="pull-right"><i class="fa fa-clock-o"></i>
                                    {{message.createdOn | date:'shortTime' }}</span></h3>
                            <p>{{message.message}}</p>
                        </div>
                    </a>

                    <div *ngIf="!messages?.length" class="center">
                        <img class="w-100" src="assets/img/norecord.png" style="float: none;" />
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="files">
                <div class="list">
                    <a *ngFor="let file of files" href class="transition">
                        <h3>{{file.text}} <span class="pull-right">{{file.size}}</span></h3>
                        <div class="progress progress-sm">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-{{file.class}}"
                                role="progressbar" [style.width]="file.value" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="tab-pane" id="meetings">
                <div class="list">
                    <a *ngFor="let meeting of meetings" href="javascript:void(0);" class="transition">
                        <div class="meeting-day bg-{{meeting.color}} pull-left">
                            <span>{{meeting.day}}</span>
                            <span>{{meeting.month}}</span>
                        </div>
                        <div class="list-content">
                            <h2>{{meeting.title}}</h2>
                            <p>{{meeting.text}}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>




<ng-template #modalContent>
    <div class="modal-body bg-gray">
        <h6 class="bg-gray text-center" style="padding-bottom: 3px; color:white;    font-size: 14px;
        font-weight: bold;">{{action}} {{actionMode=='v'}}</h6>
        <div class="row">
            <div class="col-12 vertical-tabs">
                <div *ngIf="actionMode=='v'" class="col-12 vertical-tabs bg-white">
                    <table class="table table-hover">
                        <tr>
                            <td width="50%" class="text-right">
                                <label>Project Name</label>
                            </td>
                            <td width="50%" class="text-left color-blue"><label> {{contract.projectName}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" class="text-right">
                                <label>Start Date</label>
                            </td>
                            <td width="50%" class="text-left color-blue"><label> {{contract.startDate | date}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" class="text-right">
                                <label>End Date</label>
                            </td>
                            <td width="50%" class="text-left color-blue"><label> {{contract.endDate | date}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" class="text-right">
                                <label>Active Flag</label>
                            </td>
                            <td width="50%" class="text-left color-blue"><label> {{contract.activeFlag}}</label></td>
                        </tr>

                    </table>
                    <div class="col-12 bg-white text-center py-1">
                        <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>