import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from '../providers/api/api.service';
import { TokenStorageService } from '../pages/login/token-storage.service';

@Component({
  selector: 'app-addtask',
  templateUrl: './addtask.component.html',
  styleUrls: ['./addtask.component.scss']
})
export class AddtaskComponent implements OnInit {

  public form: FormGroup;
  taskStatusList: any = [];
  project: any;
  projectList: any = [];
  authUser: any = this.tokenStorage.getUser();
  userList: any = [];
  isDisabled: boolean = false;
  userType: any;

  constructor(
    public fb: FormBuilder,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddtaskComponent>,
    private tokenStorage: TokenStorageService
  ) {
    this.form = this.fb.group({
      projectTaskSno: null,
      taskName: [null, Validators.compose([Validators.required])],
      description: null,
      startDate: [null],
      dueDate: [null],
      assignedAppUserSno: [null],
      taskStatusCd: [null],
      projectSubTask: new FormArray([])
    });
    let index = this.authUser?.menus?.findIndex((menu) => menu?.routerLink == '/task');
    if (index != -1) {
      this.userType = this.authUser?.menus[index]?.userType;
    }
  }

  ngOnInit(): void {
    this.project = this.data.project;
    this.userList = this.project?.userList;
    this.getTaskStatusEnum();
    if (this.data?.task) {
      this.data.task.projectSubTask = [];
      if (this.api.isEmptyString(this.data?.task?.startDate)) {
        this.data.task.startDate = moment(this.data?.task?.startDate).format('yyyy-MM-DD');
      }
      if (this.api.isEmptyString(this.data?.task?.dueDate)) {
        this.data.task.dueDate = moment(this.data?.task?.dueDate).format('yyyy-MM-DD');
      }
      this.form.setValue(this.data?.task);
    }
    if (this.data?.pageName == 'task') {
      this.getMyProject();
      this.form.addControl('projectSno', new FormControl(this.data?.projectSno, Validators.compose([Validators.required])));
      this.form.updateValueAndValidity();
    }
  }

  getMyProject() {
    this.projectList = [];
    let params: any = {};
    if (this.userType.isSuperAdmin || this.userType.isGlobalAdmin || this.userType.isAdmin || this.userType.isPageAdmin) {
      params.appUserSno = this.authUser.appUserSno;
      params.isAdmin = true;
    } else {

    }
    this.api.get('8085/api/get_my_project', params).subscribe(result => {
      console.log(result);
      if (result != null && result.data) {
        this.projectList = result.data;
        if (this.projectList?.length && !this.form.value.projectSno) {
          this.form.patchValue({
            projectSno: this.projectList[0].projectSno
          })
        }
        if (!this.userList?.length && this.form.value.projectSno) {
          this.getProjectUser();
        }
      }
    })
  }


  getProjectUser() {
    let param: any = { projectSno: this.form.value.projectSno };
    this.api.get('8085/api/get_project_user', param).subscribe(result => {
      // console.log(result);
      if (result != null && result?.data) {
        this.userList = [];
        for (let i in result.data) {
          this.userList.push({
            appUserSno: result.data[i].appUserSno,
            name: result.data[i].name
          });
        }
      } else {

      }
    });
  }

  public getTaskStatusEnum() {
    let param: any = { codeType: 'project_task_cd' };
    this.api.get('8082/api/get_enum_names', param).subscribe(result => {
      // console.log(result);
      if (result != null && result.data) {
        this.taskStatusList = result.data;
        if (this.taskStatusList?.length) {
          this.form.patchValue({
            taskStatusCd: this.taskStatusList[0]?.codesDtlSno
          })
        }
      } else {

      }
    });
  }

  addTask() {
    let body: any = {};
    body = this.form.value;
    if (!body?.projectSno) {
      body.projectSno = this.project.projectSno;
    }
    // console.log(body)
    this.api.put('8085/api/update_task', body).subscribe((result: any) => {
      // console.log(result);
      if (result?.data && result?.data?.projectTaskSno) {
        body.projectTaskSno = result?.data?.projectTaskSno
        this.dialogRef.close(body);
      }
    });
  }

  updateTask() {
    let form: any = this.form.value;
    let updateArgumentObj: any = {
      task_name: form.taskName,
      description: form.description,
      start_date: form.startDate,
      due_date: form.dueDate,
      assigned_app_user_sno: form.assignedAppUserSno,
      task_status_cd: form.taskStatusCd,
    }
    let body: any = {};
    body.updateArgumentObj = updateArgumentObj;
    body.projectTaskSno = form?.projectTaskSno;
    this.api.put('8085/api/update_task', body).subscribe((result: any) => {
      // console.log(result);
      if (result?.data && result?.data?.projectTaskSno) {
        this.dialogRef.close(form);
      }
    });
  }
}
