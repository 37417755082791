import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/pages/login/token-storage.service';
import { ApiService } from 'src/app/providers/api/api.service';
import { MessagesService } from './messages.service';
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessagesService]
})
export class MessagesComponent implements OnInit {
  public messages: any;
  public files: Array<Object>;
  public meetings: Array<Object>;
  authUser: any = this.tokenStorage.getUser();
  contract: any;
  public actionMode: string = "";
  public modalRef: NgbModalRef;
  public action: string = "";

  constructor(
    private messagesService: MessagesService,
    private api: ApiService,
    private tokenStorage: TokenStorageService,
    public modalService: NgbModal
  ) {
    // this.messages = messagesService.getMessages();
    this.files = this.messagesService.getFiles();
    this.meetings = this.messagesService.getMeetings();
  }

  ngOnInit() {
    jQuery('#messagesTabs').on('click', '.nav-item a', function () {
      setTimeout(() => jQuery(this).closest('.dropdown').addClass('show'));
    });
  }

  viewNotification(type, modalContent, i) {
    // console.log(type)
    if (type == '/contract') {
      this.getContractDetails(modalContent, i)
    }
  }

  getContractDetails(modalContent, i) {
    let params: any = {};
    this.api.get('8087/api/get_contract_by_id/' + this.messages[i].actionId, params).subscribe(result => {
      // console.log(result);
      if (result != null && result.data) {
        if (result.data?.length) {
          this.openModal(modalContent, result.data[0], 'v')
        }
      } else {
      }
    });
  }

  public openModal(modalContent, contract, mode) {
    this.actionMode = mode;
    if (contract && mode == 'v') {
      this.contract = contract;
      this.action = "View Contract Details..";
    }
    this.modalRef = this.modalService.open(modalContent, { container: '.app' });
    this.modalRef.result.then((result) => {
    });
  }

  public closeModal() {
    this.modalRef.close();
  }


  getNotification() {
    let body: any = {};
    body.appUserSno = this.authUser?.appUserSno;

    if (this.messages?.length) {
      body.lastGetTime = this.messages[0]?.createdOn
    }

    // console.log(body)
    this.api.get('8082/api/get_notification', body).subscribe(result => {
      // console.log(result);
      if (result != null && result.data) {
        if (this.messages?.length) {
          for (let i in result.data) {
            this.messages.push(result.data[i])
          }
        } else {
          this.messages = result.data
        }
      }
    });
  }

}
