<div mat-dialog-title>
    <div class="row">
        <div class="col-10 text-start">
            <h2 class="m-auto">View Task</h2>
        </div>
        <div class="col-2 text-end">
            <button class="btn" mat-dialog-close>
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
</div>
<div mat-dialog-content>
    <div class="mt-3">
        <div class="form-group">
            <label for="exampleInputEmail1">Task</label>
            <div>{{data?.task?.taskName}}</div>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Assigned To</label>
            <select [(ngModel)]="data.task.assignedAppUserSno"
                *ngIf="data?.task?.assignedAppUserSno && !data?.task?.name"
                class="form-control bg-white border-0 p-0 m-0" disabled>
                <option *ngFor="let user of userList;let i =index;" [value]="user?.appUserSno">
                    {{user?.name}}</option>
            </select>
            <div *ngIf="data?.task?.name">{{data?.task?.name}}</div>
            <div *ngIf="!data.task.assignedAppUserSno">
                -----
            </div>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Status</label>
            <select [(ngModel)]="data.task.taskStatusCd" *ngIf="data?.task?.taskStatusCd"
                class="form-control bg-white border-0 p-0 m-0" disabled>
                <option *ngFor="let project of taskStatusList; let i = index;" [value]="project?.codesDtlSno">
                    {{project?.cdValue}}
                </option>
            </select>
            <div *ngIf="!data?.task?.taskStatusCd">
                -----
            </div>
        </div>
        <div class="row">
            <div class="form-group col-6 col-sm-12 col-md-6">
                <label for="exampleInputEmail1">Start Date</label>
                <div>{{ data?.task?.startDate ? (data?.task?.startDate | date : 'dd-MM-y' ?? '-----') : '-----'}}</div>
            </div>
            <div class="form-group col-6 col-sm-12 col-md-6">
                <label for="exampleInputEmail1">Due Date</label>
                <div>{{data?.task?.dueDate ? (data?.task?.dueDate | date : 'dd-MM-y' ?? '-----') : '-----'}}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Description</label>
            <div>{{data?.task?.description ?? '-----'}}</div>
        </div>
    </div>
</div>