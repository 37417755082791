<div class="row mt-4 ">
    <div class="col-4">
    </div>
    <div class="col-8 text-right">
        <mat-form-field appearance="outline" class="mx-3">
            <mat-label>Select Region</mat-label>
            <mat-select #select multiple [(ngModel)]="selectedCountry">
                <div class="select-all">
                    <mat-checkbox [(ngModel)]="allSelected" (click)="toggleAllSelection()">Select
                        All</mat-checkbox>
                </div>
                <mat-option *ngFor="let country of countryList;let i = index;" (click)="optionClick()"
                    [value]="country.countrySno">
                    {{country?.countryShortCode}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="btn-group mb-4 border " role="group" aria-label="Basic example">
            <input type="week" class="form-control text-center" [(ngModel)]="selectWeek" (ngModelChange)="changeWeek()"
                id="input-14">
        </div>
        <div class="btn-group mb-4 border mx-2" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-light ">{{displayDate}}</button>
            <button type="button" class="btn btn-info border-left" (click)="previousWeek()"><i
                    class="fa fa-chevron-left mr-2"></i></button>
            <button type="button" class="btn btn-info border-left" (click)="nextWeek()"><i
                    class="fa fa-chevron-right mr-2"></i></button>
        </div>
        <div class="btn-group mb-4 border mx-2" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-light" (click)="refresh()">
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>

<div class="p-2">
    <div class="row mb-5">
        <div class="col-12">
            <div widget class="card border-0 box-shadow m-0 ">
                <div class="card-header  bg-primary text-white border-0 text-muted p-0 m-0 ">

                    <div class="row  p-2 m-0 ">
                        <div class="col-4 text-light">
                            Projects
                        </div>
                        <div class="col-xl-1 col-md-1 col-sm-12  text-center text-light" style="font-size: 12px;"
                            *ngFor="let date of dateList">
                            {{date.displayDate}}
                        </div>
                        <div class="col-xl-1 col-md-1 col-sm-12  text-center text-light">
                            Total
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0 widget-body">
                    <div class="row timesheet_hover py-3" *ngFor="let project of timesheetList;let j = index">
                        <div class="col-4 m-auto">
                            <span *ngIf="project.projectName"><label>{{project.projectName}}</label></span>
                            <!-- <span>{{project.description}}</span> -->
                            <!-- <select *ngIf="!project.projectName" class="form-control fa-select w-50"
                            [(ngModel)]="project.projectSno">
                            <option value="0" disabled selected>-Select Project-</option>
                            <option [value]="pro.projectSno" *ngFor="let pro of projectList">{{pro.projectName}}
                            </option>
                        </select> -->
                            <mat-form-field appearance="outline" *ngIf="!project.projectName">
                                <mat-label>Select Project</mat-label>
                                <mat-select [(ngModel)]="project.projectSno" [formControl]="projectCtrl"
                                    placeholder="Project" [multiple]="false" #singleSelect
                                    (ngModelChange)="chooseProject(project.projectSno,j)">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="projectFilterCtrl"
                                            placeholderLabel="Find project..." noEntriesFoundLabel="'no matching found'"
                                            [showToggleAllCheckbox]="true"
                                            toggleAllCheckboxTooltipMessage="Select / Unselect All"
                                            [toggleAllCheckboxIndeterminate]="isIndeterminate"
                                            [toggleAllCheckboxChecked]="isChecked"
                                            (toggleAll)="toggleSelectAll($event)">
                                            <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option *ngFor="let project of filteredProjects | async "
                                        style="border-bottom:1px solid #ced4da !important;"
                                        [value]="project.projectSno">
                                        {{ project.projectName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-xl-1 col-md-1 col-sm-12 timesheet_select_option m-auto"
                            *ngFor="let time of project.timesheet.workingTime;let i =index;">
                            <!-- <ngb-timepicker [(ngModel)]="timeMeridian" [spinners]="spinners"></ngb-timepicker> -->
                            <!-- <input type="time" min="00:00" step="900" name="{{time+i}}" max="12:00"
                            class="form-control text-center" [(ngModel)]="time.workedTime"
                            (change)="keyInTime(time.timesheetSno,project.projectSno,time.workedTime,i,j)"
                            [readonly]="time.isReadOnly || project.projectSno == 0"> -->

                            <select class="form-control mr-1"
                                [class]="time.workedStatus == 'approved' ? 'text-success' : time.workedStatus == 'rejected' ? 'text-danger' : (time.workedStatus == 'created' || time.workedStatus == 'edited' )?'text-warning':'text-dark'"
                                [(ngModel)]="time.selectedHours"
                                [disabled]="time.isReadOnly || project.projectSno == 0 || (time.workedStatus ==  'approved' && !project.isAdmin) "
                                (ngModelChange)="selectBoxChange(time.timesheetSno,project.projectSno,j,i)">
                                <option [value]="hour" *ngFor="let hour of hours;">
                                    {{hour}}</option>
                            </select>
                            <div>
                                <span>:</span>
                            </div>
                            <select class="form-control ml-1"
                                [class]="time.workedStatus == 'approved' ? 'text-success' : time.workedStatus == 'rejected' ? 'text-danger' : (time.workedStatus == 'created' || time.workedStatus == 'edited' )?'text-warning':'text-dark'"
                                [(ngModel)]="time.selectedMinutes"
                                (ngModelChange)="selectBoxChange(time.timesheetSno,project.projectSno,j,i)"
                                [disabled]="time.isReadOnly || project.projectSno == 0 || (time.workedStatus ==  'approved' && !project.isAdmin)">
                                <option [value]="min" *ngFor="let min of minutes;">
                                    {{min}}</option>
                            </select>
                            <div data-toggle="tooltip" data-placement="top"
                                *ngIf="time.workedStatus == 'rejected' && time?.comments && time?.comments != ''"
                                [title]="time.comments" data-animation="false">
                                <i class="fa fa-exclamation-circle mr-2 ml-2 text-danger"></i>
                            </div>

                        </div>
                        <div class="col-xl-1 col-md-1 col-sm-12 d-flex  text-center font-weight-bold"
                            (mouseenter)="project.isTrashShow = true" (mouseleave)="project.isTrashShow = false">
                            <p class="m-auto">{{project.timesheet.total}} </p>
                            <i class="fa fa-trash text-danger ml-2  m-auto"
                                *ngIf="project.isTrashShow && !project.projectName"
                                (click)="timesheetList.splice(j,1)"></i>
                        </div>

                    </div>
                </div>

                <div class="row bg-light p-2 m-0">
                    <div class="col-4">
                        Total
                    </div>
                    <div class="col-xl-1 col-md-1 col-sm-12  text-center font-weight-bold"
                        *ngFor="let total of totalList">
                        {{total.total}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span *ngFor="let project of timesheetList;let i=index;">
        <span *ngFor="let time of project.timesheet.workingTime;let j =index;">
            <div class="row mt-2 " *ngIf="time.workedStatus == 'warning'">
                <div class="col-12">
                    <p class="text-warning">Max limit (24 hrs) reached for this date
                        ({{time.workedDate | date }})</p>
                </div>
            </div>
        </span>
    </span>
    <div class="row mt-5 ">
        <div class="col-6">
            <button type="button" class="btn btn-primary  mb-1 mr-3" (click)="addNewRow()"><i
                    class="fa fa-plus mr-2"></i>Add new row</button>
            <!-- <button type="button" class="btn btn-outline-secondary  mb-1 mr-3"><i class="fa fa-copy mr-2"></i>Copy last
            week<i class="fa fa-caret-down ml-2"></i></button> -->
            <!-- <button type="button" class="btn btn-outline-secondary  mb-1 mr-3"><i class="fa fa-floppy-o mr-2"></i>Save as
            template</button> -->
            <!-- <button type="button" class="btn btn-outline-secondary  mb-1 mr-3"><i class="fa fa-plus mr-2"></i>Add New Row</button> -->
            <!-- <ss-multiselect-dropdown  [options]="secondControlOptions" [texts]="secondControlTexts" [settings]="secondControlSettings" [(ngModel)]="secondControlModel"></ss-multiselect-dropdown> -->
        </div>

        <div class="col-6 text-end">
            <button type="button" class="btn   mb-1 mr-3 text-warning">
                <i class="fa fa-square mr-2 text-warning"></i>Pending</button>
            <button type="button" class="btn mb-1 mr-3 text-success "><i class="fa fa-square mr-2 text-success"></i>
                Approved</button>
            <button type="button" class="btn mb-1 mr-3 text-danger"><i
                    class="fa fa-square mr-2 text-danger"></i>Rejected</button>
            <!-- <button type="button" class="btn btn-outline-secondary  mb-1 mr-3"><i class="fa fa-plus mr-2"></i>Add New Row</button> -->
            <!-- <ss-multiselect-dropdown  [options]="secondControlOptions" [texts]="secondControlTexts" [settings]="secondControlSettings" [(ngModel)]="secondControlModel"></ss-multiselect-dropdown> -->
        </div>
    </div>
</div>