<!-- <div class="row mb-4">
    <div class="col-12 ">
        <button mat-raised-button color="primary" class="p-4 float-right" (click)="goToAddExpense()">
            <mat-icon>add</mat-icon>&nbsp;New
        </button>
    </div>
</div> -->

<div class="row p-3" *ngIf="tableList?.length">
    <div [class]="isShow ? 'col-4' : 'col-12'">
        <div class="table-responsive bg-white box-shadow">
            <table class="table">
                <thead>
                    <tr class="bg-primary text-white">
                        <th class="text-center">#</th>
                        <th *ngIf="!isShow ">Employee Name</th>
                        <th *ngIf="!isShow">DATE</th>
                        <th>EXPENSE ACCOUNT</th>
                        <th *ngIf="!isShow">REFERENCE#</th>
                        <!-- <th *ngIf="!isShow">VENDOR NAME</th> -->
                        <th *ngIf="!isShow">PAID THROUGH</th>
                        <!-- <th *ngIf="!isShow">CUSTOMER NAME</th> -->
                        <th *ngIf="!isShow">STATUS</th>
                        <th *ngIf="!isShow">AMOUNT</th>
                        <th *ngIf="!isShow">Actions</th>
                    </tr>
                </thead>
                <tbody class="table-hover" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                    (scrolled)="getExpenses()">
                    <tr class="pointer" *ngFor="let table of tableList;let i = index;">
                        <td (click)="viewExpanse(table)" class="text-center">{{i+1}}</td>
                        <td *ngIf="!isShow " (click)="viewExpanse(table)">
                            {{table?.ExpenseCreatedBy?.name }}</td>
                        <td *ngIf="!isShow" (click)="viewExpanse(table)">{{table.date | date : 'dd-MM-YYYY'}}</td>
                        <td [class]="table?.expenseSno == selectedExpanse?.expenseSno && isShow  ? 'text-dark fw-bold' : 'text-primary'"
                            (click)="viewExpanse(table)">{{table.expenseAccountName}}</td>
                        <td *ngIf="!isShow" (click)="viewExpanse(table)">{{table.invoice}}</td>
                        <!-- <td *ngIf="!isShow" (click)="viewExpanse(table)">{{table.vendorName}}</td> -->
                        <td *ngIf="!isShow" (click)="viewExpanse(table)"> {{table.paidThroughName}}</td>
                        <!-- <td *ngIf="!isShow" (click)="viewExpanse(table)">{{table.clientName}}</td> -->

                        <td *ngIf="!isShow ">
                            <select [style.background]="table?.statusCdValue == 'Approved' ? 'green' : 'red'"
                                class="form-control rounded-pill text-white text-center m-auto"
                                [(ngModel)]="table.statusCd" (change)="changeExpensesStatus(table)">
                                <option class="option_bg" *ngFor="let status of statusList;"
                                    [value]="status?.codesDtlSno">
                                    {{status?.cdValue}}
                                </option>
                            </select>
                        </td>
                        <td *ngIf="!isShow" (click)="viewExpanse(table)">{{table.amount | number :
                            '1.2-2'}}</td>
                        <td *ngIf="!isShow">
                            <div class="btn-group" role="group"
                                *ngIf="authUser?.roleCdValue == 'Admin' ? true : table?.statusCdValue != 'Approved' ? true : false ">
                                <button class="btn btn-info btn-sm" (click)="edit(i)"><i
                                        class="fa fa-pencil"></i></button>
                                <button class="btn btn-info btn-sm" (click)="delete(i)"><i
                                        class="fa fa-trash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
    <div class="col-8 bg-white" *ngIf="isShow">
        <div class="container mt-3">
            <div class="row mb-3">
                <div class="col-12 text-end">
                    <button class="btn" (click)="editExpenses()">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button class="btn" (click)="isShow=false">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-5">
                    <div class="text-muted font-weight-light font-small" *ngIf="authUser?.roleCdValue == 'Admin'">
                        Employee Name
                    </div>
                    <p class="font-small" *ngIf="authUser?.roleCdValue == 'Admin'">
                        {{selectedExpanse?.ExpenseCreatedBy?.name}}</p>
                    <div class="text-muted font-weight-normal">
                        Expense Amount
                    </div>
                    <span class="text-danger fw-bold h5"><span>&#8377;</span>{{selectedExpanse?.amount | number :
                        '1.2-2'}} </span>
                    <span style="font-size: 10px;">
                        on <span class="text-muted">{{selectedExpanse?.date | date : 'dd-MM-YYYY'}}</span>
                    </span>
                    <p class="text-uppercase font-small text-non-billable">
                        Non-Billable
                    </p>
                    <div class="text-muted font-weight-light font-small">
                        Paid Through
                    </div>
                    <p class="font-small">{{selectedExpanse?.paidThroughName}}</p>
                    <div class="text-muted font-weight-ligh font-smallt">
                        Ref #
                    </div>
                    <p class="font-small">{{selectedExpanse?.invoice}}</p>
                    <div class="text-muted font-weight-light font-small">
                        GST Treatment
                    </div>
                    <p class="font-small">Out Of Scope</p>
                    <div class="text-muted font-weight-light font-small">
                        Status
                    </div>
                    <select [style.background]="selectedExpanse?.statusCdValue == 'Approved' ? 'green' : 'red'"
                        class="form-control rounded-pill text-white text-center my-2 w-50" [(ngModel)]="selectedExpanse.statusCd"
                        (change)="changeExpensesStatus(selectedExpanse)">
                        <option class="option_bg" *ngFor="let status of statusList;" [value]="status?.codesDtlSno">
                            {{status?.cdValue}}
                        </option>
                    </select>
                </div>
                <div class="col-7 text-center" *ngIf="selectedExpanse?.media?.length">
                    <img width="100px" class="pointer"
                        src="https://toppng.com/uploads/preview/pdf-icon-11549528510ilxx4eex38.png"
                        (click)="viewDocument()" />
                    <!-- <div class="row mt-5 pointer" (click)="viewDocument()">
                        <div class="col-12 text-center">
                            <div class="btn-group" role="group">
                                <button class="btn btn-info btn-sm">
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!tableList?.length && !isShowLoading" class="text-center">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div *ngIf="isShowLoading" class="text-center">
    <img src="assets/gif/loading.gif" class="position-absolute top-50 start-50" width="110px" />
</div>

<!-- <div class="row" *ngIf="tableList?.length">
    <div class="col-12">
        <div class="bg-white text-center">
            <pagination-controls id="expense" (pageChange)="getExpenses($event);p=$event" responsive="true">
            </pagination-controls>
        </div>
    </div>
</div> -->

<app-confirm-dialog></app-confirm-dialog>