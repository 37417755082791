import { CommonModule, JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ApiService } from '../providers/api/api.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  styleUrls: ['./vendor.component.scss'],
  standalone: true
})
export class VendorComponent implements OnInit {

  vendorForm: any = [];
  isLoad: boolean = false;
  selectedIndex: any;
  vendorList: any = [];
  // vendorList: any = [
  //   { "Name": "Karthi", "status": "Active" },
  //   { "Name": "Mani", "status": "Active" },
  //   { "Name": "Allu", "status": "Active" },

  // ];

  isUpdate: boolean = false;
  searchVendor = new Subject<string>();
  searchText: any;
  isShowLoading: boolean = false;
  public p: any;
  
  constructor(private api: ApiService, public toastrService: ToastrService) {
    this.vendorForm = new FormGroup({
      vendorSno: new FormControl(null),
      vendorName: new FormControl(null, Validators.required),
      activeFlag: new FormControl('true', Validators.required),
    });
  }

  ngOnInit(): void {
    this.searchVendor.pipe(debounceTime(400), distinctUntilChanged()).subscribe(value => {
      this.getVendor();
    });
    this.getVendor();
  }

  save() {
    let body: any = {};
    body = this.vendorForm.value;
    // console.log(body);
    this.isLoad = true;
    this.api.post('8089/api/insert_vendor', body).subscribe(result => {
      this.isLoad = false;
      if (result != null && result.data) {
        body.vendorSno = result.data.vendorSno;
        this.vendorList.push(body);
        this.toastrService.success('Save Successfully');
        this.vendorForm.reset();
        // this.location.back();
      } else {

      }
    });
  }


  edit(i: any) {
    this.selectedIndex = i;
    this.vendorForm.setValue(this.vendorList[i]);
    // this.vendorForm.patchValue({
    //   vendorSno: this.vendorList[i].vendorSno,
    //   vendorName: this.vendorList[i].vendorName,
    //   activeFlag: this.vendorList[i].activeFlag,
    // });

  }

  update() {
    let body: any = {};
    body = this.vendorForm.value;
    this.isLoad = true;
    this.api.put('8089/api/update_vendor', body).subscribe((result: any) => {
      this.isLoad = false;
      if (result != null && !result?.data?.message) {
        this.clear();
        this.vendorList[this.selectedIndex].vendorName = body.vendorName;
        this.vendorList[this.selectedIndex].activeFlag = body.activeFlag;
        this.toastrService.success('Updated Successfully');
      } else {
        this.toastrService.error(result?.data?.message);
      }
    }, err => {
      this.isLoad = false;
      this.toastrService.error(err)
    }
    );
  }

  getVendor() {
    this.isShowLoading = true;
    let param: any = {};
    if (this.api.isEmptyString(this.searchText)) {
      param.searchKey = this.searchText
    }
    this.api.get("8089/api/get_vendor", param).subscribe(result => {
      this.isShowLoading = false;
      // console.log(result)
      if (result != null) {
        if (result.data != null && result.data.length > 0) {
          this.vendorList = result.data;
        } else {
        }
      } else {
        this.toastrService.error('Something went wrong');
      }
    }, err => {
      this.toastrService.error(err)
    });
  }

  clear() {
    this.vendorForm.reset();
  }

  delete(i: any) {
    let body: any = {
      vendorSno: this.vendorList[i].vendorSno
    };
    this.api.delete('8089/api/delete_vendor', body).subscribe((result: any) => {
      if (result != null) {
        this.vendorList.splice(i, 1);
        this.toastrService.success('Deleted Sucessfully');
      }
    }, err => {
      this.isLoad = false;
      this.toastrService.error(err)
    });
  }

  deleteAlert(i: any) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'lightgrey',
      confirmButtonText: 'Yes, delete it',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Deleted!',
        //   'Your record has been deleted.',
        //   'success'
        // )
        this.delete(i);
      }
      else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Cancelled!',
          'Your record is safe',
          'error'
        )
      }
    })
  }

  updateVendor() {
    this.isUpdate = true;
    let body: any = {};
    this.api.put('8089/api/update_admin_add_vendor', body).subscribe((result: any) => {
      this.isUpdate = false;
      // console.log(result);
      if (result != null) {
        this.getVendor()
      }
    }, err => {
      this.isLoad = false;
      this.toastrService.error(err)
    });
  }
}