import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { MessagingService } from './providers/fire/messaging.service';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { ApiService } from './providers/api/api.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { TokenStorageService } from './pages/login/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {
  sidebar: SidebarComponent | any;
  public settings: Settings;
  authUser: any;

  constructor(public appSettings: AppSettings,
    private messageService: MessagingService, public translate: TranslateService, private tokenStorage: TokenStorageService,
    private api: ApiService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
    translate.addLangs(['en', 'de', 'fr', 'ru', 'tr']);
    translate.setDefaultLang('en');
    translate.use('en');
    this.messageService.requestPermission();
    this.messageService.receivedMsg();
  }

  ngOnInit() {
    this.authUser = this.tokenStorage.getUser();
    if (this.authUser?.menus?.length) {
      // this.router.events
      //   .pipe(filter(event => event instanceof NavigationEnd))
      //   .subscribe((event: NavigationEnd) => {
      //     const componentName = this.getComponentName(event.urlAfterRedirects);
      //     console.log(componentName);
      // if (this.authUser && componentName?.trim()?.length == 0) {
      this.router.navigateByUrl(this.authUser.menus[0].routerLink)
      // }
      // });
    }
    if (!this.api.isEmpty(this.authUser)) {
      this.getToken();
      this.getMenu();
    }
  }

  getComponentName(url: string): string {
    // Extract the component name from the URL
    const segments = url.split('/');
    const componentName = segments[segments.length - 1];

    return componentName;
  }


  getMenu() {
    let param: any = {};
    // param.roleCd = this.authUser.roleCd;
    param.appUserSno = this.authUser.appUserSno;
    this.api.get('8082/api/get_menu_role', param).subscribe(result => {
      console.log(result)
      if (result != null) {
        this.tokenStorage.updateUserMenus(result.data);
        this.sidebar = result.data;
      } else {

      }
    });
  }

  getToken() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        // console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });
  }



  /* These following methods used for theme preview, you can remove this methods */

  // ngOnInit() { 
  //     var demo = this.getParameterByName('demo');
  //     this.setLayout(demo);
  // }

  // private getParameterByName(name) {
  //     var url = window.location.href;
  //     name = name.replace(/[\[\]]/g, "\\$&");
  //     var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
  //         results = regex.exec(url);
  //     if (!results) return null;
  //     if (!results[2]) return '';
  //     return decodeURIComponent(results[2].replace(/\+/g, " "));
  // }

  // private setLayout(demo){
  //      switch (demo) {
  //         case "vertical-default":
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'default';
  //             break;
  //         case "vertical-compact":
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'compact';
  //             break;
  //         case "vertical-mini":
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'mini';
  //             break;
  //         case "horizontal-default":
  //             this.settings.theme.menu = 'horizontal';
  //             this.settings.theme.menuType = 'default';
  //             break;
  //         case "horizontal-compact":
  //             this.settings.theme.menu = 'horizontal';
  //             this.settings.theme.menuType = 'compact';
  //             break;
  //         case "horizontal-mini":
  //             this.settings.theme.menu = 'horizontal';
  //             this.settings.theme.menuType = 'mini';
  //             break;
  //         default:
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'default';
  //     }
  //     this.router.navigate(['/']);
  // }

}
