<section>
    <form [formGroup]="accountForm">
        <div class="row mt-3">
            <div class="col-lg-4 col-md-4 col-sm-12 card bg-light mx-2 p-3 mt-3 h-100">
                <div class="form-floating mb-3 mt-3">
                    <input type="text" formControlName="accountName" class="form-control" id="floatingInput"
                        placeholder="name@example.com">
                    <label for="floatingInput">Account Name *</label>
                </div>
                <div class="form-floating">
                    <select class="form-select" formControlName="activeFlag" id="floatingSelect"
                        aria-label="Floating label select example">
                        <option value="true">Active</option>
                        <option value="false">InActive</option>
                    </select>
                    <label for="floatingSelect">Status *</label>
                </div>
                <div class="mt-4">
                    <button type="submit" class="btn btn-success" *ngIf="accountForm.value?.accountSno == null"
                        (click)="save()" [disabled]="accountForm.invalid || isLoad">
                        <span class="spinner-border spinner-border-sm" *ngIf="isLoad"></span>
                        &nbsp;&nbsp;Save </button>

                    <button type="submit" class="btn btn-success" [disabled]="accountForm.invalid || isLoad"
                        *ngIf="accountForm.value?.accountSno != null" (click)="update()">
                        <span class="spinner-border spinner-border-sm" *ngIf="isLoad"></span>
                        &nbsp;&nbsp;Update
                    </button>
                    <button class="btn btn-outline-secondary mx-3" (click)="clear()"
                        [disabled]="isLoad">Cancel</button>`
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 card bg-light mx-2 mt-3 p-3">
                <table class="mt-3">
                    <thead class="mt-3">
                        <tr>
                            <th class="p-2">Sno</th>
                            <th class="p-2">Account Name</th>
                            <th class="p-2">Staus</th>
                            <th class="p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let account of accountList;let i=index" class="table-hover">
                        <tr>
                            <td class="p-2">{{i+1}}</td>
                            <td class="p-2">{{account.accountName}}</td>
                            <td>
                                <span class="text-success" *ngIf="account?.activeFlag == 'true' ">Active</span>
                                <span class="text-danger" *ngIf="account?.activeFlag == 'false' ">InActive</span>
                            </td>
                            <td class="p-2">
                                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                    <div class="btn-group mr-2 btn-group-sm" style="margin-right: 5px;" role="group"
                                        aria-label="First group">
                                        <button type="button" class="btn btn-light" (click)="edit(i)">
                                            <span class="menu-icon tf-icons iconify text-primary">
                                                <i class="fa fa-edit"></i>
                                            </span>
                                        </button>
                                    </div>

                                    <div class="btn-group btn-group-sm" role="group" aria-label="Third group">
                                        <button type="button" class="btn btn-light" (click)="deleteAlert(i)">
                                            <span class="menu-icon tf-icons iconify text-danger">
                                                <i class="fa fa-trash"></i>
                                            </span>
                                            <span class="spinner-border spinner-border-sm" *ngIf="isLoad"></span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</section>