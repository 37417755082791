import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/providers/api/api.service';
import { TokenStorageService } from '../login/token-storage.service';
import { MatOption } from '@angular/material/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-timesheet-approval',
  templateUrl: './timesheet-approval.component.html',
  styleUrls: ['./timesheet-approval.component.scss']
})
export class TimesheetApprovalComponent implements OnInit {

  fromDate: any;
  toDate: any;
  displayDate: any;

  appUser: any;
  dateList: any = [];
  monthList: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  WeekList: any = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  selectWeek: any;
  currentWeek: any;
  currentYear: any;
  authUser: any;
  // projectList: any = [];
  searchText: any;
  selectProject: any;
  timesheetList: any = [];
  selectedIndexI: any;
  selectedIndexJ: any;
  comments: any = '';
  projectName: any;

  public projectList: any = [];

  /** control for the selected car */
  public projectCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public projectFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of cars filtered by search keyword */
  public filteredProjects: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect', { static: true })
  singleSelect!: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  /** flags to set the toggle all checkbox state */
  isIndeterminate = false;
  isChecked = false;

  countryList:any = [];
  allSelected:boolean = false;
  selectedCountry:any = [];
  @ViewChild('select') select: MatSelect;


  constructor(private api: ApiService, private tokenService: TokenStorageService) {
    this.authUser = this.tokenService.getUser();
    this.displayDate = moment().format('MMMM DD') + '-' + moment().add(6, 'd').format('MMMM DD');
  }

  ngOnInit(): void {
    var currentDate: any = moment();
    this.currentWeek = (currentDate.week() - 1);
    if (this.currentWeek < 10) {
      this.currentWeek = '0' + this.currentWeek;
    }
    this.currentYear = currentDate.get('year');
    this.selectWeek = this.currentYear + '-W' + this.currentWeek;
    this.fromDate = currentDate.clone().startOf('week').add(1, 'd');
    this.toDate = currentDate.clone().endOf('week').add(1, 'd');
    this.dateList = [];
    for (let i = 0; i <= 6; i++) {
      let date: any = moment(this.fromDate).add(i, 'days');
      this.dateList.push({ displayDate: date.format("ddd , MMM D "), fullDate: date.format('YYYY-MM-DD') });
    };
    this.displayDate = this.fromDate.format('MMM DD') + ' - ' + this.toDate.format('MMM DD');
    // this.getAdminProjects();
    this.getCountry();
  }

  
  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = JSON.stringify(this.authUser?.countries);
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length) {
          $(document).ready(() => {
            this.allSelected = true;
            this.toggleAllSelection();
          });
        }
      } else {
      }
    });
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.getAdminProjects();
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.selectedCountry = this.countryList.map((obj)=> obj.countrySno);
    } else {
      this.selectedCountry = [];
    }
    this.getAdminProjects();
  }

  getAdminProjects() {
    this.projectList = [];
    let params:any = { appUserSno: this.authUser.appUserSno };

    
    if(this.selectedCountry && this.selectedCountry.length > 0){
      params.countries = JSON.stringify(this.selectedCountry );
    }

    this.api.get('8085/api/get_admin_project', params).subscribe(result => {
      if (result != null && result.data) {
        this.projectList.push({ projectName: 'All', projectSno: 0 });
        for (let i in result.data) {
          this.projectList.push(result.data[i]);
        }
        if (this.projectList.length > 0) {
          this.selectProject = this.projectList[0].projectSno;
          this.getProjectTimesheet();
          this.projectCtrl.setValue(this.projectList)

          this.filteredProjects.next(this.projectList.slice())
  
          this.projectFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterProjects();
            });
        }
      } else {

      }
    });
  }

  protected filterProjects() {
    if (!this.projectList) {
      return;
    }
    // get the search keyword
    let search = this.projectFilterCtrl.value;
    if (!search) {
      this.filteredProjects.next(this.projectList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the cars
    this.filteredProjects.next(
      this.projectList.filter((project) => project.projectName.toLowerCase().indexOf(search) > -1)
    );
  }

  
  toggleSelectAll(selectAllValue: boolean) {
    this.filteredProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          this.projectCtrl.patchValue(val);
        } else {
          this.projectCtrl.patchValue([]);
        }
      });
  }

  ngAfterViewInit() {
    // this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  getProjectTimesheet() {
    for (let i in this.projectList) {
      if (this.projectList[i].projectSno == this.selectProject) {
        this.projectName = this.projectList[i].projectName;
        break;
      }
    }
    let body: any = { fromDate: this.fromDate.format('YYYY-MM-DD'), toDate: this.toDate.format('YYYY-MM-DD') };
    if (parseInt(this.selectProject)) {
      body.projectSno = this.selectProject;
    } else {
      body.appUserSno = this.authUser.appUserSno;
    }
    this.api.get('8086/api/get_project_timesheet', body).subscribe(result => {
      // console.log(result)
      if (result != null && result.data) {
        this.timesheetList = result.data;
        this.timeConvertion()
      } else {
        this.timesheetList = [];
      }

    });
  }

  timeConvertion() {
    for (let i in this.timesheetList) {
      for (let j in this.timesheetList[i]?.timesheet?.workingTime) {
        this.timesheetList[i].timesheet.workingTime[j].workedTime = this.getHoursAndMinutes(this.timesheetList[i].timesheet?.workingTime[j].workedTime);
        if (this.timesheetList[i].timesheet.workingTime[j].workedStatus == 'created' || this.timesheetList[i].timesheet.workingTime[j].workedStatus == 'edited') {
          this.timesheetList[i].timesheet.isDisabled = true;
        }
      }
      if (this.timesheetList[i]?.timesheet?.total) {
        this.timesheetList[i].timesheet.total = this.getHoursAndMinutes(this.timesheetList[i]?.timesheet?.total)
      } else {
        this.timesheetList[i].timesheet.total = '00:00';
      }
    }
  }


  getHoursAndMinutes(totalTimeInMin: any) {
    let hours = Math.floor(totalTimeInMin / 60);
    let minutes = totalTimeInMin % 60;
    let display = (hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()) + ':' + (minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString());
    // console.log(display)
    return display;
  }


  nextWeek() {

    let nextWeek = this.selectWeek.split('W');
    let incrementWeek: any = parseInt(nextWeek[1]) + 1;
    if (incrementWeek > 52) {
      let year = nextWeek[0].split('-');
      this.selectWeek = (parseInt(year) + 1) + '-W01';
    } else {
      if (incrementWeek < 10) {
        incrementWeek = '0' + incrementWeek;
      }
      this.selectWeek = nextWeek[0] + 'W' + incrementWeek;
    }
    this.changeWeek();
  }

  previousWeek() {
    let previousWeek = this.selectWeek.split('W');
    let decrementWeek: any = parseInt(previousWeek[1]) - 1;
    if (decrementWeek == 0) {
      let year = previousWeek[0].split('-');
      this.selectWeek = (parseInt(year) - 1) + '-W52';
    } else {
      if (decrementWeek < 10) {
        decrementWeek = '0' + decrementWeek;
      }
      this.selectWeek = previousWeek[0] + 'W' + decrementWeek;
    }

    this.changeWeek();
  }

  changeWeek() {
    let selectedWeek = this.selectWeek.split('-');
    let selectWeekYear = selectedWeek[0];
    let selectweek = selectedWeek[1].split('W')[1];
    let selectedWeekStartDate = this.getDateOfISOWeek(parseInt(selectweek), parseInt(selectWeekYear));
    this.fromDate = moment(selectedWeekStartDate);
    this.toDate = this.fromDate.clone().endOf('week').add(1, 'd');
    this.dateList = [];
    for (let i = 0; i <= 6; i++) {
      let date: any = moment(this.fromDate).add(i, 'days');
      this.dateList.push({ displayDate: date.format("ddd , MMM D "), fullDate: date.format('YYYY-MM-DD') });
    };
    this.displayDate = this.fromDate.format('MMM DD') + ' - ' + this.toDate.format('MMM DD');
    this.getProjectTimesheet();
  }


  // getDateOfWeek(w, y) {
  //   var d: any = (1 + (parseInt(w) - 1) * 7); // 1st of January + 7 days for each week
  //   d = d + 2;
  //   if (d < 10) {
  //     d = '0' + d;
  //   }
  //   return new Date(y, 0, d);
  // }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;

    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    return ISOweekStart;
  }


  updateTimeSheet(type) {
    let body: any = {};
    let time: any;
    // console.log(this.timesheetList[this.selectedIndexI])
    if (this.selectedIndexJ != null && this.selectedIndexJ != undefined) {
      time = this.timesheetList[this.selectedIndexI].timesheet?.workingTime[this.selectedIndexJ];
      body.timesheetSno = time?.timesheetSno;
      body.approverSno = this.authUser.appUserSno;
      body.workedTime = moment(time?.workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes');
      body.timesheetStatus = type;
      if (type == 'rejected') {
        body.comments = time?.comments;
      }
    } else {
      let timeList: any = [];
      for (let j in this.timesheetList[this.selectedIndexI].timesheet?.workingTime) {
        let timesheet = this.timesheetList[this.selectedIndexI].timesheet?.workingTime[j];
        // time.timesheetSno = timeSheet?.timesheetSno;
        // time.approverSno = this.authUser.appUserSno;
        // time.workedTime = moment(timeSheet?.workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes');
        // time.timesheetStatus = type;
        timeList.push({
          timesheetSno: timesheet?.timesheetSno,
          approverSno: this.authUser.appUserSno,
          workedTime: moment(timesheet?.workedTime, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
          timesheetStatus: type,
          comments: this.comments
        });
      }
      body.timesheetList = timeList;
    }
    // console.log(body)
    this.api.put('8086/api/update_timesheet', body).subscribe((result: any) => {
      // console.log(result)
      this.comments = '';
      if (result != null && result.data) {
        this.getProjectTimesheet();
      }
    });
  }

}
