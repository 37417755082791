import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Router } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { TimesheetComponent } from './pages/timesheet/timesheet.component';
import { TimesheetApprovalComponent } from './pages/timesheet-approval/timesheet-approval.component';
import { ReportTimesheetComponent } from './pages/report-timesheet/report-timesheet.component';
import { TaskComponent } from './task/task.component';
import { BillsComponent } from './bills/bills.component';
import { AddBillsComponent } from './bills/add-bills/add-bills.component';
import { AddExpenseComponent } from './add-expense/add-expense.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { VendorComponent } from './vendor/vendor.component';
import { AccountComponent } from './account/account.component';
import { DealComponent } from './deal/deal.component';
import { MenuPermissionComponent } from './menu-permission/menu-permission.component';
import { ProjectPlanComponent } from './project-plan/project-plan.component';
import { ExpenseApprovalComponent } from './expense-approval/expense-approval.component';
import { TokenStorageService } from './pages/login/token-storage.service';
import { FormsComponent } from './forms/forms.component';
import { AddformsComponent } from './addforms/addforms.component';
import { ViewformsComponent } from './viewforms/viewforms.component';
import { AddDealTaskComponent } from './add-deal-task/add-deal-task.component';
import { DealTaskComponent } from './deal-task/deal-task.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
      { path: 'membership', loadChildren: () => import('./pages/membership/membership.module').then(m => m.MembershipModule), data: { breadcrumb: 'Membership' } },
      { path: 'ui', loadChildren: () => import('./pages/ui/ui.module').then(m => m.UiModule), data: { breadcrumb: 'UI' } },
      { path: 'form-elements', loadChildren: () => import('./pages/form-elements/form-elements.module').then(m => m.FormElementsModule), data: { breadcrumb: 'Form Elements' } },
      { path: 'tables', loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule), data: { breadcrumb: 'Tables' } },
      { path: 'tools', loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsModule), data: { breadcrumb: 'Tools' } },
      { path: 'calendar', loadChildren: () => import('./pages/calendar/app-calendar.module').then(m => m.AppCalendarModule), data: { breadcrumb: 'Calendar' } },
      { path: 'mailbox', loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailboxModule), data: { breadcrumb: 'Mailbox' } },
      { path: 'maps', loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule), data: { breadcrumb: 'Maps' } },
      { path: 'charts', loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsModule), data: { breadcrumb: 'Charts' } },
      { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' } },
      { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } },
      { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      { path: 'timesheet', component: TimesheetComponent, data: { breadcrumb: 'Timesheet' } },
      { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
      { path: 'timesheet-approval', component: TimesheetApprovalComponent, data: { breadcrumb: 'Timesheet Approval' } },
      { path: 'report-timesheet', component: ReportTimesheetComponent, data: { breadcrumb: 'Timesheet Report' } },

      //tocumulus
      { path: 'employee', loadChildren: () => import('./pages/employee/employee.module').then(m => m.EmployeeModule), data: { breadcrumb: 'Employee' } },
      { path: 'client', loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule), data: { breadcrumb: 'Client' } },
      { path: 'vault', loadChildren: () => import('./pages/vault/vault.module').then(m => m.VaultModule), data: { breadcrumb: 'Vault' } },
      { path: 'project', loadChildren: () => import('./pages/project/project.module').then(m => m.ProjectModule), data: { breadcrumb: 'Project' } },
      { path: 'company', loadChildren: () => import('./pages/company/company.module').then(m => m.ProjectModule), data: { breadcrumb: 'Company' } },
      // { path: 'vaultcategory', loadChildren: () => import('./pages/vaultcategory/vaultcategory.module').then(m => m.VaultCategoryModule), data: { breadcrumb: 'Vault category' } }
      { path: 'contract', loadChildren: () => import('./pages/contract/contract.module').then(m => m.ContractModule), data: { breadcrumb: 'Contract' } },
      { path: 'task', component: TaskComponent, data: { breadcrumb: 'Task' } },
      { path: 'bills', component: BillsComponent, data: { breadcrumb: 'Bills' } },
      { path: 'add-bills', component: AddBillsComponent, data: { breadcrumb: 'Add Bills' } },
      { path: 'edit-bills', component: AddBillsComponent, data: { breadcrumb: 'Edit Bills' } },
      { path: 'add-expense', component: AddExpenseComponent, data: { breadcrumb: 'Add Expense' } },
      { path: 'edit-expense', component: AddExpenseComponent, data: { breadcrumb: 'Edit Expense' } },
      { path: 'expenses', component: ExpensesComponent, data: { breadcrumb: 'Expenses' } },
      { path: 'vendor', component: VendorComponent, data: { breadcrumb: 'Vendor' } },
      { path: 'account', component: AccountComponent, data: { breadcrumb: 'Account' } },

      { path: 'deal', component: DealComponent, data: { breadcrumb: 'Deal' } },
      { path: 'lead', loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule), data: { breadcrumb: 'Lead' } },
      { path: 'deal', loadChildren: () => import('./deal/deal.module').then(m => m.DealModule), data: { breadcrumb: 'Deal' } },
      { path: 'menu-permission', component: MenuPermissionComponent, data: { breadcrumb: 'Menu Permission' } },
      { path: 'project-plan', component: ProjectPlanComponent, data: { breadcrumb: 'Project Plan' } },
      { path: 'expense-approval', component: ExpenseApprovalComponent, data: { breadcrumb: 'Expense Approval' } },
      { path: 'forms', component: FormsComponent, data: { breadcrumb: 'Forms' } },
      { path: 'addforms', component: AddformsComponent, data: { breadcrumb: 'Add Forms' } },
      { path: 'viewforms', component: ViewformsComponent, data: { breadcrumb: 'View Forms' } },
      { path: 'deal_task', component: DealTaskComponent, data: { breadcrumb: 'Deal Task' } },
    ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

  constructor(private router: Router, private tokenStorage: TokenStorageService) {
    let authUser: any = this.tokenStorage.getUser();
    if (authUser?.appUserSno) {
      if (authUser?.menus?.length) {
        this.router.navigateByUrl(authUser.menus[0].routerLink);
      }
    } else {
      this.router.navigateByUrl('/login')
    }
  }
}