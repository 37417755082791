import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../providers/api/api.service';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { AutosizeModule } from 'ngx-autosize';
import { ToastrService } from 'ngx-toastr';
import * as PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-viewforms',
  standalone: true,
  imports: [CommonModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatIconModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    AutosizeModule
  ],
  templateUrl: './viewforms.component.html',
  styleUrls: ['./viewforms.component.scss'],
})
export class ViewformsComponent {

  dealList: any = [];
  public dealCtrl: FormControl = new FormControl();
  public dealFilterCtrl: FormControl = new FormControl();
  public filteredDeal: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  dealOwnerList: any = [];
  public dealOwnerCtrl: FormControl = new FormControl();
  public dealOwnerFilterCtrl: FormControl = new FormControl();
  public filteredDealOwner: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public clientCtrl: FormControl = new FormControl();
  public clientFilterCtrl: FormControl = new FormControl();
  public filteredClients: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public contactCtrl: FormControl = new FormControl();
  public contactFilterCtrl: FormControl = new FormControl();
  public filteredContact: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public companyCtrl: FormControl = new FormControl();
  public signatoryCtrl: FormControl = new FormControl();


  protected _onDestroy = new Subject<void>();

  proposalIssuedDate: any = moment().format('YYYY-MM-DD');
  proposalValidDate: any;
  overView: any = '';
  projectActivities: any = '';
  projectScope: any = '';
  prerequisites: any = '';
  organization: any;
  dealCompany: any;

  clients: any = [];
  contactList: any = [];
  companyList: any = [];
  signatoryList: any = [];

  constructor(
    private http: HttpClient,
    public api: ApiService,
    public toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getDealId();
    this.getDealOwner();
    this.getClients();
    this.getCompany();
    this.getSignatory();
    this.contactFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterContact();
      });
  }

  getDealId() {
    let param: any = {};
    this.api.get('8091/api/get_deal_id', param).subscribe(result => {
      console.log(result);
      if (result != null && result.data) {
        this.dealList = result?.data;
        this.filteredDeal.next(this.dealList.slice());
        this.dealFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDeal();
          });
      } else {
      }
    });
  }

  filterDeal() {
    if (!this.dealList) {
      return;
    }
    // get the search keyword
    let search = this.dealFilterCtrl.value;
    if (!search) {
      this.filteredDeal.next(this.dealList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDeal.next(
      this.dealList.filter(deal => deal.dealId?.toLowerCase().indexOf(search) > -1)
    );
  }

  getDealOwner() {
    let param: any = {};
    this.api.get('8091/api/get_deal_owner', param).subscribe(result => {
      console.log(result);
      if (result != null && result.data) {
        this.dealOwnerList = result?.data;
        this.filteredDealOwner.next(this.dealOwnerList.slice());
        this.dealOwnerFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDealOwner();
          });
      } else {
      }
    });
  }

  filterDealOwner() {
    if (!this.dealOwnerList) {
      return;
    }
    // get the search keyword
    let search = this.dealOwnerFilterCtrl.value;
    if (!search) {
      this.filteredDealOwner.next(this.dealOwnerList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDealOwner.next(
      this.dealOwnerList.filter(deal => deal.dealOwnerName?.toLowerCase().indexOf(search) > -1)
    );
  }

  getClients() {
    let params: any = {};
    this.api.get('8084/api/get_client', params).subscribe(result => {
      // console.log(result);
      if (result && result?.data) {
        this.clients = result.data;
        this.clientCtrl.setValue(this.clients)
        this.filteredClients.next(this.clients.slice());
        this.clientFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterclients();
          });
      }
    }, error => {

    });
  }

  filterclients() {
    if (!this.clients) {
      return;
    }

    let search = this.clientFilterCtrl.value;
    if (!search) {
      this.filteredClients.next(this.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredClients.next(
      this.clients.filter(client => client.companyName?.toLowerCase().indexOf(search) > -1)
    );
  }

  onChangeClient(ev: any) {
    let data: any = this.clients?.filter(client => client?.clientSno == ev.value);
    this.contactList = data?.length ? data[0]?.clientDetails?.contact_persons : [];
    this.filteredContact.next(this.contactList.slice());
  }

  filterContact() {
    if (!this.contactList) {
      return;
    }
    // get the search keyword
    let search = this.contactFilterCtrl.value;
    if (!search) {
      this.filteredContact.next(this.contactList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredContact.next(
      this.contactList.filter(client => (client.first_name?.toLowerCase().indexOf(search) > -1) || (client.last_name?.toLowerCase().indexOf(search) > -1))
    );
  }

  getCompany() {
    let param: any = { codeType: "region_of_company" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      console.log(result.data);
      if (result != null && result.data.length > 0) {
        this.companyList = result.data;
      }
    });
  }

  getSignatory() {
    let param: any = { codeType: "company_Signatory" };
    this.api.get("8082/api/get_enum_names", param).subscribe((result) => {
      console.log(result.data);
      if (result != null && result.data.length > 0) {
        this.signatoryList = result.data;
      }
    });
  }

  calCulateIssuedDate() {
    this.proposalValidDate = moment(this.proposalIssuedDate).add(1, 'month').format('DD-MM-YYYY');
    return this.proposalValidDate;
  }

  getDocx() {
    let selectedDeal = this.dealList.filter((deal) => deal?.dealSno == this.dealCtrl.value);
    let selectedDealOwner = this.dealOwnerList.filter((deal) => deal?.dealOwnerSno == this.dealOwnerCtrl.value);
    let selectedclient = this.clients.filter((client) => client?.clientSno == this.clientCtrl.value);
    let selectedContact = this.contactList.filter((contact) => contact?.contact_person_id == this.contactCtrl.value);
    let selectedCompany = this.companyList.filter((company) => company?.codesDtlSno == this.companyCtrl.value);
    let selectedSignatory = this.signatoryList.filter((signatory) => signatory?.codesDtlSno == this.signatoryCtrl.value);
    if (
      // selectedDeal?.length && selectedDealOwner?.length && selectedclient?.length && 
      // selectedContact?.length && selectedSignatory?.length && selectedCompany?.length
      true
    ) {
      console.log(selectedCompany[0]);
      console.log(selectedCompany[0]?.cdValue?.trim() == ("Tocumulus Technology Solutions LLP")?.trim());
      let xl: any = selectedCompany[0]?.cdValue?.trim() == ("Tocumulus Technology Solutions LLP")?.trim() ? 'assets/xl/sow_in.docx' : 'assets/xl/sow_du.docx'

      const lineBreaksToXml = (text) => {
        const lines = text?.split('\n');
        const xmlLines = lines?.map((line) => `${line}`);
        return xmlLines?.join('');
      };

      this.http.get("assets/xl/Reddington-iSource-AVD-09.03.23.docx", { responseType: 'arraybuffer' }).subscribe((response: ArrayBuffer) => {
        const zip = new PizZip(response);
        const doc = new Docxtemplater().setOptions({
          linebreaks: true,
          paragraphLoop: true, 
        }).loadZip(zip);

        let data = {
          "products": [
            { name: "vijay" },
            { name: "vijay v" },
          ],
          "$company": '<b>Tocumulus Technology Solutions LLP</b>',
          "$deal_id": selectedDeal?.length ? selectedDeal[0]?.dealId : '',
          "$prepared_by": selectedDealOwner?.length ? selectedDealOwner[0]?.dealOwnerName : '',
          "$proposal_issued_date": moment(this.proposalIssuedDate).format('DD-MM-YYYY'),
          "$proposal_valid_date": this.proposalValidDate,
          "$overview": this.overView,
          "$activities": this.projectActivities,
          "$scope": this.projectScope,
          "$prerequisites": this.prerequisites,
          "$organization": selectedCompany?.length ? selectedCompany[0]?.cdValue : '',
          "$organization_signature": selectedSignatory?.length ? selectedSignatory[0]?.cdValue : '',
          "$deal_company": selectedclient[0]?.companyName,
          "$deal_signature": selectedContact?.length ? selectedContact[0]?.first_name + (this.api?.isEmptyString(selectedContact[0]?.last_name) ? ' ' + selectedContact[0]?.last_name : '') : ''
        }
        console.log(data);
        doc.setData(data);
        doc.render();
        const updatedBuffer = doc.getZip().generate({
          type: 'blob', mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });
        saveAs(updatedBuffer, 'output.docx');
        // const blob = new Blob([updatedBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        // const downloadLink = document.createElement('a');
        // downloadLink.href = URL.createObjectURL(blob);
        // downloadLink.download = 'updated-file.docx';
        // downloadLink.click();
      });
    } else {
      this.toastrService.error('Please fill mandatory field');
    }
  }
}