<div class="adminmenu-header">
    <div mat-dialog-title>
        <h4 class="m-0">Assign Role & Module Admin</h4>
    </div>
</div>
<mat-dialog-content class="mat-typography bg-light adminmenu-body">

    <div class="card p-3 shadow">

        <div class="d-flex">
            <div class="text-center profile-img">
                <img class="rounded-circle" src="assets/img/avatars/user.png">
                <ul class="social_icons">
                    <li>
                        <i class="devicon-facebook-plain colored"></i>
                    </li>
                    <li>
                        <i class="devicon-twitter-original colored"></i>
                    </li>
                    <li>
                        <i class="devicon-google-plain colored"></i>
                    </li>
                </ul>
            </div>
            <div class="d-grid ml-3">
                <p class="text-gray text-truncate m-0">position:
                    <span>{{api.isEmptyString(adUser?.profile?.jobTitle) ? adUser?.profile?.jobTitle : '- - - - -'
                        }}</span>
                </p>
                <p class="text-gray text-truncate">email:
                    <span>{{api.isEmptyString(adUser?.profile?.email) ? adUser?.profile?.email : '- - - - -' }}</span>
                </p>
                <p class="text-gray text-truncate">phone:
                    <span>{{api.isEmptyString(adUser?.profile?.mobilePhone) ?
                        adUser?.profile?.mobilePhone : '- - - - -' }}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="p-3">
        <h3 class="mt-3">Select Role</h3>
        <div class="px-5">
            <div class="row gy-3">
                <div class="form-check col-auto " *ngFor="let role of roleList;let i = index;">
                    <input class="form-check-input" type="radio" [disabled]="role.isDisabled" [name]="role.cdValue"
                        id="role.cdValue{{i}}" [(ngModel)]="selectRoleCd" [value]="role.codesDtlSno"
                        (change)="changeRole()">
                    <label class="form-check-label" for="role.cdValue{{i}}">{{role.cdValue}}</label>
                </div>
            </div>
        </div>
        <h3 class="mt-3">Select Region</h3>
        <div class="px-5">
            <div class="row gy-3">
                <div class="form-check col-auto " *ngFor="let country of countryList;let i = index;">
                    <input class="form-check-input" name="region" type="checkbox" id="country{{i}}"
                        [value]="country.countrySno" [disabled]="(selectRoleCdValue == 'Super Admin') || selectRoleCdValue == 'Global Admin'">
                    <label class="form-check-label" for="country{{i}}">{{country.countryShortCode}}</label>
                </div>
            </div>
        </div>
        <span *ngIf="selectRoleCdValue != 'Super Admin'">
            <h3 class="mt-3">Select Modules & Module Admins</h3>
            <div class="px-5">
                <div class="row gy-3 justify-content-between">
                    <span class="col-lg-4 col-md-6 col-sm-12 d-flex"
                        *ngFor="let menu of adUser?.menuSelectOptions;let i = index;">
                        <div class="form-check">
                            <input class="form-check-input" name="usermenu" type="checkbox" id="usermenu{{i}}"
                                (change)="selectMenu(i)" [value]="menu.appMenuSno">
                            <label class="form-check-label" for="usermenu{{i}}">{{menu.name}}</label>
                        </div>
                        <div class="form-check form-switch" style="margin-left: auto;">
                            <input class="form-check-input" type="checkbox" [value]="menu.isAdmin"
                                (change)="selectAdminToogle(i)"
                                [disabled]="selectRoleCdValue == 'Global Admin' || selectRoleCdValue == 'Admin'"
                                role="switch" [(ngModel)]="menu.isAdmin" id="menuswitch{{i}}">
                        </div>
                    </span>
                </div>
            </div>
        </span>
    </div>
    <!-- <div class="table-responsive bg-white box-shadow mt-3">
        <table class="table">
            <thead>
                <tr class="bg-primary text-white">
                    <th>Modules</th>
                    <th class="text-center"> <input class="form-check-input pointer position-relative" type="checkbox" > &nbsp;Assign Modules </th>
                    <th class="text-center"><input class="form-check-input pointer  position-relative" type="checkbox" >&nbsp;Assign Module Admin</th>
                    <th class="text-center"><input class="form-check-input pointer  position-relative" type="checkbox" >&nbsp;Assign Region Admin</th>
                </tr>
            </thead>
            <tbody class="table-hover">
                <tr *ngFor="let menu of adUser?.menuSelectOptions;let i =index;" class="pointer">
                    <td>
                        {{menu.name}}
                    </td>
                    <td class="text-center">
                        <div class="form-check">
                            <input class="form-check-input pointer" type="checkbox" [(ngModel)]="menu.isMenuAssign"
                                [disabled]="menu.isAdmin">
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="form-check">
                            <input class="form-check-input pointer" type="checkbox" [(ngModel)]="menu.isAdmin"
                                (ngModelChange)="changeAdmin(i)">
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="form-check">
                            <input class="form-check-input pointer" type="checkbox" [(ngModel)]="menu.isAdmin"
                                (ngModelChange)="changeAdmin(i)">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div> -->
</mat-dialog-content>
<mat-dialog-actions align="end" class="mr-3">
    <button mat-button mat-dialog-close class="bg-light">Cancel</button>
    <button mat-button cdkFocusInitial (click)="updateMenuRole()" class="bg-primary text-light">Assign</button>
</mat-dialog-actions>