import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/providers/api/api.service';
import { TokenStorageService } from '../login/token-storage.service';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
// import { single, multi } from 'src/app/pages/charts/charts.data';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import * as $ from 'jquery';

@Component({
  selector: 'app-report-timesheet',
  templateUrl: './report-timesheet.component.html',
  styleUrls: ['./report-timesheet.component.scss']
})
export class ReportTimesheetComponent implements OnInit {

  authUser: any;
  fromDate: any = moment().subtract(6, 'd').format('YYYY-MM-DD');
  toDate: any = moment().format('YYYY-MM-DD');
  timesheetReportList: any = [];
  isNoRecord: boolean = true;
  searchKey: any = '';
  total: any = 0;
  selectedProject: any;
  selectedEmp: any = -1;
  // projectUserList: any = [];

  //bar chart
  public single: any[];
  public multi: any[];

  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public showXAxisLabel = true;
  public xAxisLabel = 'Account Name';
  public showYAxisLabel = true;
  public yAxisLabel = 'time (mins)';
  public colorScheme = {
    domain: []
  };

  //pie chart
  // '#2F3E9E', '#D22E2E', '#378D3B', '#0096A6', '#F47B00', '#606060','#F47B00', '#606060'
  // public showLegend = true;
  // public gradient = true;
  // public colorScheme = {
  //   domain: ['#2F3E9E', '#D22E2E', '#378D3B', '#0096A6', '#F47B00', '#606060']
  // }; 
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;

  @ViewChild('singleSelect', { static: true })
  singleSelect!: MatSelect;

  public projectList: any = [];
  public projectCtrl: FormControl = new FormControl();
  public projectFilterCtrl: FormControl = new FormControl();
  public filteredProjects: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public projectUserList: any = [];
  public projectUserCtrl: FormControl = new FormControl();
  public projectUserFilterCtrl: FormControl = new FormControl();
  public filteredProjectUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public clientList: any = [];
  public clientCtrl: FormControl = new FormControl();
  public clientFilterCtrl: FormControl = new FormControl();
  public filteredClients: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


  protected _onDestroy = new Subject<void>();
  isIndeterminate = false;
  isChecked = false;


  searchText: any;
  selectedClient: any;
  public data: any = [];
  isShowLoading: boolean = false;
  selectedCountrySno: any;
  countryList: any = [];

  constructor(private api: ApiService, private tokenService: TokenStorageService, public toastrService: ToastrService) {
    this.authUser = this.tokenService.getUser();
    // Object.assign(this, { single, multi });
  }

  ngOnInit(): void {
    this.getCountry();
    this.projectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProjects();
      });
    this.clientFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterClients();
      });
    this.projectUserFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProjectUsers();
      });
  }

  getCountry() {
    let params: any = {};
    if (this.authUser?.countries?.length) {
      params.countries = JSON.stringify(this.authUser?.countries);
    }
    this.api.get('8082/api/get_countries', params).subscribe(result => {
      console.log(result.data)
      if (result != null && result.data) {
        this.countryList = result.data;
        if (this.countryList?.length) {
          this.selectedCountrySno = this.countryList[0]?.countrySno;
          this.getClientInfo();
        }
      } else {
      }
    });
  }

  public onSelect(event) {
    // console.log(event);
    let clientSno = null;
    let projectSno = null;
    let appUserSno = null;
    for (let i in this.data) {
      if (this.data[i].name == event.name) {
        if (this.data[i].clientSno) {
          clientSno = this.data[i].clientSno;
          break;
        } else if (this.data[i].projectSno) {
          projectSno = this.data[i].projectSno;
          break;
        } else if (this.data[i].appUserSno) {
          appUserSno = this.data[i].appUserSno;
          break;
        }
      }
    }
    this.data = null
    this.data = [];
    if (clientSno) {
      // let projects: any = [];
      this.xAxisLabel = 'Projects';
      for (let i in this.timesheetReportList) {
        if (this.timesheetReportList[i].clientSno == clientSno) {
          // console.log(JSON.stringify(this.timesheetReportList[i]));
          if (this.data.length == 0) {
            let color: any = this.getRandomColor();
            this.colorScheme.domain.push(color);
            this.data.push({ projectSno: this.timesheetReportList[i].projectSno, name: this.timesheetReportList[i].projectName, value: parseInt(this.timesheetReportList[i].tempWorkedTime) });
          } else {
            let isAlready = false;
            for (let j in this.data) {
              if (this.data[j].projectSno == this.timesheetReportList[i].projectSno) {
                this.data[j].value = this.data[j].value + parseInt(this.timesheetReportList[i].tempWorkedTime);
                isAlready = true;
                break;
              }
            }
            if (!isAlready) {
              let color: any = this.getRandomColor();
              this.colorScheme.domain.push(color);
              this.data.push({ projectSno: this.timesheetReportList[i].projectSno, name: this.timesheetReportList[i].projectName, value: parseInt(this.timesheetReportList[i].tempWorkedTime) });
            }
          }
        }
      }

    } else if (projectSno) {
      this.xAxisLabel = 'Employees';
      for (let i in this.timesheetReportList) {
        if (this.timesheetReportList[i].projectSno == projectSno) {
          // console.log(JSON.stringify(this.timesheetReportList[i]));
          if (this.data.length == 0) {
            let color: any = this.getRandomColor();
            this.colorScheme.domain.push(color);
            this.data.push({ appUserSno: this.timesheetReportList[i].appUserSno, name: this.timesheetReportList[i].employeeName, value: parseInt(this.timesheetReportList[i].tempWorkedTime) });
          } else {
            let isAlready = false;
            for (let j in this.data) {
              if (this.data[j].appUserSno == this.timesheetReportList[i].appUserSno) {
                this.data[j].value = this.data[j].value + parseInt(this.timesheetReportList[i].tempWorkedTime);
                isAlready = true;
                break;
              }
            }
            if (!isAlready) {
              let color: any = this.getRandomColor();
              this.colorScheme.domain.push(color);
              this.data.push({ appUserSno: this.timesheetReportList[i].appUserSno, name: this.timesheetReportList[i].employeeName, value: parseInt(this.timesheetReportList[i].tempWorkedTime) });
            }
          }
        }
      }

    } else {
      this.xAxisLabel = 'Account Name';
      this.setAccountChart();
    }
  }

  search() {
    this.isShowLoading = true;
    this.isNoRecord = true;
    var a = moment(this.fromDate);
    var b = moment(this.toDate);
    let diff = b.diff(a, 'days') // 1
    if (diff >= 0) {
      let body: any = { fromDate: this.fromDate, toDate: this.toDate };
      if (this.clientCtrl.value?.length > 0)
        body.clientSno = '[' + this.clientCtrl.value + ']';
      if (this.projectCtrl.value?.length > 0)
        body.projectSno = '[' + this.projectCtrl.value + ']';
      if (this.projectUserCtrl.value?.length > 0)
        body.appUserSno = '[' + this.projectUserCtrl.value + ']';

      // console.log(body)
      this.api.get('8086/api/get_timesheet_report', body).subscribe(result => {
        // console.log(result);
        this.isShowLoading = false;
        if (result != null && result.data) {
          this.isNoRecord = false;
          this.timesheetReportList = result.data;
          this.total = 0;
          for (let i in this.timesheetReportList) {
            this.timesheetReportList[i].tempWorkedTime = this.timesheetReportList[i].workedTime;
            this.total = parseInt(this.total) + parseInt(this.timesheetReportList[i].workedTime);
            this.timesheetReportList[i].total = this.total;
            this.timesheetReportList[i].workedTime = this.getHoursAndMinutes(this.timesheetReportList[i].workedTime);
          }
          this.total = this.getHoursAndMinutes(this.total);
          this.setAccountChart();
        } else {
          this.timesheetReportList = [];
        }
      });
    } else {
      this.timesheetReportList = [];
      this.toastrService.error('startdate should earlier than end date');
    }
  }

  setAccountChart() {
    this.data = [];
    for (let i in this.timesheetReportList) {

      // this.data.push({ name: this.timesheetReportList[i].companyName, value: 40632 })
      if (this.data.length == 0) {
        let color: any = this.getRandomColor();
        this.colorScheme.domain.push(color);
        this.data.push({ clientSno: this.timesheetReportList[i].clientSno, name: this.timesheetReportList[i].companyName, value: this.timesheetReportList[i].tempWorkedTime })
      } else {
        let isAlready = false
        for (let j in this.data) {
          if (this.data[j].clientSno == this.timesheetReportList[i].clientSno) {
            this.data[j].value = this.data[j].value + this.timesheetReportList[i].tempWorkedTime;
            isAlready = true;
            break;
          }
        }
        if (!isAlready) {
          let color: any = this.getRandomColor();
          this.colorScheme.domain.push(color);
          this.data.push({ clientSno: this.timesheetReportList[i].clientSno, name: this.timesheetReportList[i].companyName, value: this.timesheetReportList[i].tempWorkedTime })
        }
      }
    }
  }

  getHoursAndMinutes(totalTimeInMin: any) {
    let hours = Math.floor(totalTimeInMin / 60);
    let minutes = totalTimeInMin % 60;
    let display = (hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()) + ':' + (minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString());
    return display;
  }

  extractXl() {
    if (this.timesheetReportList.length > 0) {
      // console.log(this.timesheetReportList)
      let report: any = JSON.parse(JSON.stringify(this.timesheetReportList));
      let xlList: any = [];
      for (let i in report) {
        // report[i].workedTime = this.workedTime(report[i].workedTime);
        let obj: any = {}
        if ($('#account').prop('checked')) {
          obj.companyName = report[i]?.companyName;
        }
        if ($('#project').prop('checked')) {
          obj.projectName = report[i]?.projectName;
        }
        if ($('#employee').prop('checked')) {
          obj.employeeName = report[i]?.employeeName;
        }
        obj.workedDate = report[i]?.workedDate;
        obj.workedTime = this.workedTime(report[i]?.workedTime);

        xlList.push(obj);
      }
      // let obj: any = {}
      // if ($('#account').prop('checked')) {
      //   obj.companyName = '';
      // }
      // if ($('#project').prop('checked')) {
      //   obj.projectName = '';
      // }
      // if ($('#employee').prop('checked')) {
      //   obj.employeeName = '';
      // }
      // obj.workedDate = 'Total'
      // obj.workedTime = 'Total'
      // xlList.push(obj);
      let sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlList);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, 'report')
      let wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
      });
      function s2ab(s) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
      let blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      var fileName = this.fromDate + ' - ' + this.toDate + "-timeheet-report.docx";
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        var base64data = reader.result;
        let a: any = document.createElement('a');
        a.href = base64data;
        a.download = fileName;
        a.click();
      }
    } else {
      this.toastrService.error('No Record Found...');
    }
  }

  workedTime(workingTime: string) {
    let work = workingTime?.split(':')[0] + '.' +
      (workingTime?.split(':')[1] == '15' ? '25' : workingTime?.split(':')[1] == '30' ? '50' : workingTime?.split(':')[1] == '45' ? '75' : '00');
    return parseFloat(work);
  }

  getProjectList() {
    this.projectList = [];

    let params: any = {};
    if (this.api.isEmptyString(this.clientCtrl.value.toString())) {
      params = { clientSno: '[' + this.clientCtrl.value.toString() + ']' };
    } else {
      this.projectCtrl.reset();
      this.filteredProjects.next([]);
      return;
    }

    // console.log(params);
    this.api.get('8085/api/get_client_project', params).subscribe(result => {
      // console.log(result)
      if (result != null && result.data) {
        this.projectList = result.data;
        this.filteredProjects.next(this.projectList.slice());
      }
    });
  }

  protected filterProjects() {
    if (!this.projectList) {
      return;
    }
    let search = this.projectFilterCtrl.value;
    if (!search) {
      this.filteredProjects.next(this.projectList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // console.log(search);
    // // console.log(this.projectList.filter((project) => project?.projects.filter((pro) => { 
    //   // console.log(pro?.projectName?.toLowerCase());
    //   // console.log(pro?.projectName?.toLowerCase().indexOf(search) > -1 )
    //  return pro?.projectName?.toLowerCase().indexOf(search) > -1 
    // })))
    // this.filteredProjects.next(
    //   this.projectList.filter((project) => project?.projects.filter((pro) => pro?.projectName?.toLowerCase().indexOf(search) > -1 ))
    // );
    // console.log(this.projectList.length)
    let searchValue: any;
    let project: any;
    project = JSON.stringify(this.projectList);
    project = JSON.parse(project);
    for (let i in this.projectList) {
      if (this.projectList[i]?.projects) {
        searchValue = this.projectList[i]?.projects?.filter((pro) => pro?.projectName?.toLowerCase().indexOf(search) > -1);
        // console.log(searchValue)
        if (searchValue?.length) {
          if (project[i]?.projects) {
            project[i].projects = searchValue;
          } else {
            project?.splice(i, 1);
          }
        } else {
          project?.splice(i, 1);
        }
      } else {
        project?.splice(i, 1);
      }
    }
    // console.log(project);
    this.filteredProjects.next(
      project
    );

  }


  toggleSelectAll(selectAllValue: boolean) {
    this.filteredProjects
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          let projectList = [];
          for (let project of val) {
            if (project?.projects?.length) {
              for (let pro of project?.projects) {
                if (pro?.projectSno) {
                  projectList.push(pro?.projectSno)
                }
              }
            }
          }
          // if(projectList.length > 0){
          //   projectList = null
          // }
          this.projectCtrl.patchValue(projectList);
        } else {
          this.projectCtrl.patchValue([]);
        }
      });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getUserList() {
    // console.log(this.projectCtrl.value);
    this.projectUserList = [];

    let params: any = {};
    if (this.api.isEmptyString(this.projectCtrl.value?.toString())) {
      params = { projectSno: '[' + this.projectCtrl.value?.toString() + ']' };
    } else {
      this.projectUserCtrl.reset();
      this.filteredProjectUsers.next([]);
      return;
    }

    // console.log(params)
    this.api.get('8085/api/get_my_project_user', params).subscribe(result => {
      // console.log(result)
      if (result != null && result.data) {
        this.projectUserList = result.data;
        this.filteredProjectUsers.next(this.projectUserList.slice());
      }
    });
  }

  protected filterProjectUsers() {
    if (!this.projectUserList) {
      return;
    }
    let search = this.projectUserFilterCtrl.value;
    if (!search) {
      this.filteredProjectUsers.next(this.projectUserList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // this.filteredProjectUsers.next(
    //   this.projectUserList.filter((user) => user?.name?.toLowerCase().indexOf(search) > -1)
    // );
    let searchValue: any;
    let projectUser: any;
    projectUser = JSON.stringify(this.projectUserList);
    projectUser = JSON.parse(projectUser);

    for (let i in this.projectUserList) {
      searchValue = this.projectUserList[i]?.users?.filter((user) => user?.name?.toLowerCase().toLowerCase().indexOf(search) > -1);
      // console.log(searchValue);
      if (searchValue?.length && projectUser[i]?.users) {
        projectUser[i].users = searchValue;
      } else {
        projectUser?.splice(i, 1);
      }
    }
    this.filteredProjectUsers.next(
      projectUser
    );
  }


  toggleProjectUserSelectAll(selectAllValue: boolean) {
    this.filteredProjectUsers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          let projectUserList = [];
          for (let projectUser of val) {
            for (let user of projectUser?.users) {
              projectUserList.push(user?.appUserSno)
            }
          }
          this.projectUserCtrl.patchValue(projectUserList);
        } else {
          this.projectUserCtrl.patchValue([]);
        }
      });
  }

  public getClientInfo() {
    let params: any = {};
    params.countrySno = this.selectedCountrySno;
    this.api.get('8084/api/get_client_info', params).subscribe(result => {
      if (result != null) {
        this.clientList = result?.data?.length ? result.data : [];
        // this.getProjectList(null);
        // this.clientCtrl.setValue(this.clientList);
        this.filteredClients.next(this.clientList.slice());
      }
    });
  }

  protected filterClients() {
    if (!this.clientList) {
      return;
    }
    // get the search keyword
    let search = this.clientFilterCtrl.value;
    if (!search) {
      this.filteredClients.next(this.clientList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the cars
    this.filteredClients.next(
      this.clientList.filter((user) => user.companyName.toLowerCase().indexOf(search) > -1)
    );
  }

  toggleClientsSelectAll(selectAllValue: boolean) {
    this.filteredClients
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          let clientList = [];
          for (let client of val) {
            clientList.push(client?.clientSno)
          }
          this.clientCtrl.patchValue(clientList);
        } else {
          this.clientCtrl.patchValue([]);
        }
        // console.log(this.selectedClient)
      });
  }

  // getRandomColor(length:any) {
  //   var chars = '0123456789ABCDEF';
  //   var hex = '#';
  //   while(length--) {
  //     hex += chars[(Math.random() * 16) | 0];
  //     // console.log(hex)
  //   }
  //   return hex;
  // }

  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

}